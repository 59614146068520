import {
  ContactData,
  contactFields,
  contactFieldsInitialData,
  FormField,
  ContactDataModel,
} from '@ncs-frontend-monorepo/forms';
import { BuildingOwnerForm } from './Form';
import { OptionProps } from 'dss-ui-library';

type BuildingOwnerFormData = {
  message: FormField;
  isAddressKnown: FormField;
  projectStatus: FormField;
  fileUploadIds: FormField;
};

type BuildingOwnerForm = Required<ContactData & BuildingOwnerFormData>;

export const buildingOwnerForm: BuildingOwnerForm = {
  ...contactFields,
  message: {
    label: '',
    name: 'message',
    placeholder:
      'Bitte beschreiben Sie Ihr Anliegen ausführlich, damit wir Ihnen möglichst schnell helfen können.',
  },
  isAddressKnown: {
    label: '',
    name: 'isAddressKnown',
    mandatory: true,
  },
  projectStatus: {
    label: 'Worum geht es genau?',
    name: 'projectStatus',
    mandatory: true,
  },
  fileUploadIds: {
    label: '',
    name: 'fileUploadIds',
  },
} as const;

type BuildingOwnerModel = {
  message: string;
  projectStatus: string;
  fileUploadIds: string[];
  isAddressKnown: 'yes' | 'no';
};

export const initialFormData: BuildingOwnerFormBody = {
  ...contactFieldsInitialData,
  message: '',
  projectStatus: '',
  isAddressKnown: 'yes',
  fileUploadIds: [],
};

export type BuildingOwnerFormBody = Required<
  ContactDataModel & BuildingOwnerModel
>;

export const projectStatus: OptionProps[] = [
  { label: 'Planung eines Neubaus', value: 'PLANNING_PHASE' },
  { label: 'Neubau (in Bauphase)', value: 'BUILDING_PHASE' },
  {
    label: 'Sanierung einer Bestandsimmobilie',
    value: 'RENOVATION_PHASE',
  },
  {
    label: 'Anschluss einer Bestandsimmobilie an das Glasfasernetz',
    value: 'CONNECTING_EXISTING',
  },
  {
    label:
      'Erweiterung einer bereits angeschlossenen Bestandsimmobilie (zusätzliche Einheiten)',
    value: 'EXPAND_EXISTING',
  },
];
