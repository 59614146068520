import React from 'react';
import { ChevronLink } from '@ncs-frontend-monorepo/content-library';
import planStyles from '../PlanElements.module.scss';

interface TemplatePageLink {
  url: string;
  e2e?: string;
  disabled?: boolean;
}

export const TemplatePageLink: React.FC<TemplatePageLink> = ({
  url,
  e2e = 'detail-page-link',
  disabled = false,
}) => (
  <ChevronLink
    appearance="t4_2"
    url={url}
    e2e={e2e}
    className={disabled ? planStyles.isDisabled : undefined}
  >
    Zur Tarifseite
  </ChevronLink>
);
