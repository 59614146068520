import {
  AssociationFormBody,
  initialFormData,
} from '../../components/Forms/Association/data';
import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';

interface AssociationFormState {
  initialState: AssociationFormBody;
  updateInitialState: (form: AssociationFormBody) => void;
}

export const useAssociationFormStore = create<AssociationFormState>()(
  persist(
    (set) => ({
      initialState: { ...initialFormData },
      updateInitialState: (formData) => set({ initialState: { ...formData } }),
    }),
    {
      name: 'associationForm',
      storage: createJSONStorage(() =>
        window['Cookiebot']?.consent?.preferences
          ? localStorage
          : sessionStorage,
      ),
    },
  ),
);
