import { OptionProp } from 'dss-ui-library';

export enum Districts {
  AltstadtNord = 'AltstadtNord',
  AltstadtSued = 'AltstadtSued',
  Bayenthal = 'Bayenthal',
  Bickendorf = 'Bickendorf',
  Bilderstoeckchen = 'Bilderstoeckchen',
  Blumenberg = 'Blumenberg',
  Bocklemuend = 'Bocklemuend',
  Braunsfeld = 'Braunsfeld',
  Brueck = 'Brueck',
  Buchforst = 'Buchforst',
  Buchheim = 'Buchheim',
  Chorweiler = 'Chorweiler',
  Dellbrueck = 'Dellbrueck',
  Deutz = 'Deutz',
  Duennwald = 'Duennwald',
  Ehrenfeld = 'Ehrenfeld',
  Eil = 'Eil',
  Elsdorf = 'Elsdorf',
  Ensen = 'Ensen',
  EschAuweiler = 'EschAuweiler',
  Finkenberg = 'Finkenberg',
  Flittard = 'Flittard',
  Fuehlingen = 'Fuehlingen',
  Godorf = 'Godorf',
  Gremberghoven = 'Gremberghoven',
  Grengel = 'Grengel',
  Hahnwald = 'Hahnwald',
  Heimersdorf = 'Heimersdorf',
  Holweide = 'Holweide',
  HumboldtGremberg = 'HumboldtGremberg',
  Hoehenberg = 'Hoehenberg',
  Hoehenhaus = 'Hoehenhaus',
  Immendorf = 'Immendorf',
  Junkersdorf = 'Junkersdorf',
  Kalk = 'Kalk',
  Klettenberg = 'Klettenberg',
  Langel = 'Langel',
  Libur = 'Libur',
  Lind = 'Lind',
  Lindenthal = 'Lindenthal',
  Lindweiler = 'Lindweiler',
  Longerich = 'Longerich',
  Loevenich = 'Loevenich',
  Marienburg = 'Marienburg',
  Mauenheim = 'Mauenheim',
  Merheim = 'Merheim',
  Merkenich = 'Merkenich',
  Meschenich = 'Meschenich',
  Muelheim = 'Muelheim',
  Muengersdorf = 'Muengersdorf',
  Neuehrenfeld = 'Neuehrenfeld',
  Neubrueck = 'Neubrueck',
  NeustadtNord = 'NeustadtNord',
  NeustadtSued = 'NeustadtSued',
  Niehl = 'Niehl',
  Nippes = 'Nippes',
  Ossendorf = 'Ossendorf',
  Ostheim = 'Ostheim',
  Pesch = 'Pesch',
  Poll = 'Poll',
  Porz = 'Porz',
  Raderberg = 'Raderberg',
  Raderthal = 'Raderthal',
  RathHeumar = 'RathHeumar',
  Riehl = 'Riehl',
  Rodenkirchen = 'Rodenkirchen',
  RoggendorfThenhoven = 'RoggendorfThenhoven',
  Rondorf = 'Rondorf',
  Seeberg = 'Seeberg',
  Stammheim = 'Stammheim',
  Suelz = 'Suelz',
  Suerth = 'Suerth',
  Urbach = 'Urbach',
  Vingst = 'Vingst',
  Vogelsang = 'Vogelsang',
  VolkhovenWeiler = 'VolkhovenWeiler',
  Wahn = 'Wahn',
  Wahnheide = 'Wahnheide',
  Weiden = 'Weiden',
  Weidenpesch = 'Weidenpesch',
  Weiss = 'Weiss',
  Westhoven = 'Westhoven',
  Widdersdorf = 'Widdersdorf',
  Worringen = 'Worringen',
  Zollstock = 'Zollstock',
  Zuendorf = 'Zuendorf',
}

export const districtOptions: OptionProp[] = [
  {
    value: Districts.AltstadtNord,
    label: 'Altstadt-Nord',
  },
  {
    value: Districts.AltstadtSued,
    label: 'Altstadt-Süd',
  },
  {
    value: Districts.Bayenthal,
    label: 'Bayenthal',
  },
  {
    value: Districts.Bickendorf,
    label: 'Bickendorf',
  },
  {
    value: Districts.Bilderstoeckchen,
    label: 'Bilderstöckchen',
  },
  {
    value: Districts.Blumenberg,
    label: 'Blumenberg',
  },
  {
    value: Districts.Bocklemuend,
    label: 'Bocklemünd',
  },
  {
    value: Districts.Braunsfeld,
    label: 'Braunsfeld',
  },
  {
    value: Districts.Brueck,
    label: 'Brück',
  },
  {
    value: Districts.Buchforst,
    label: 'Buchforst',
  },
  {
    value: Districts.Buchheim,
    label: 'Buchheim',
  },
  {
    value: Districts.Chorweiler,
    label: 'Chorweiler',
  },
  {
    value: Districts.Dellbrueck,
    label: 'Dellbrück',
  },
  {
    value: Districts.Deutz,
    label: 'Deutz',
  },
  {
    value: Districts.Duennwald,
    label: 'Dünnwald',
  },
  {
    value: Districts.Ehrenfeld,
    label: 'Ehrenfeld',
  },
  {
    value: Districts.Eil,
    label: 'Eil',
  },
  {
    value: Districts.Elsdorf,
    label: 'Elsdorf',
  },
  {
    value: Districts.Ensen,
    label: 'Ensen',
  },
  {
    value: Districts.EschAuweiler,
    label: 'Esch/Auweiler',
  },
  {
    value: Districts.Finkenberg,
    label: 'Finkenberg',
  },
  {
    value: Districts.Flittard,
    label: 'Flittard',
  },
  {
    value: Districts.Fuehlingen,
    label: 'Fühlingen',
  },
  {
    value: Districts.Godorf,
    label: 'Godorf',
  },
  {
    value: Districts.Gremberghoven,
    label: 'Gremberghoven',
  },
  {
    value: Districts.Grengel,
    label: 'Grengel',
  },
  {
    value: Districts.Hahnwald,
    label: 'Hahnwald',
  },
  {
    value: Districts.Heimersdorf,
    label: 'Heimersdorf',
  },
  {
    value: Districts.Holweide,
    label: 'Holweide',
  },
  {
    value: Districts.HumboldtGremberg,
    label: 'Humboldt/Gremberg',
  },
  {
    value: Districts.Hoehenberg,
    label: 'Höhenberg',
  },
  {
    value: Districts.Hoehenhaus,
    label: 'Höhenhaus',
  },
  {
    value: Districts.Immendorf,
    label: 'Immendorf',
  },
  {
    value: Districts.Junkersdorf,
    label: 'Junkersdorf',
  },
  {
    value: Districts.Kalk,
    label: 'Kalk',
  },
  {
    value: Districts.Klettenberg,
    label: 'Klettenberg',
  },
  {
    value: Districts.Langel,
    label: 'Langel',
  },
  {
    value: Districts.Libur,
    label: 'Libur',
  },
  {
    value: Districts.Lind,
    label: 'Lind',
  },
  {
    value: Districts.Lindenthal,
    label: 'Lindenthal',
  },
  {
    value: Districts.Lindweiler,
    label: 'Lindweiler',
  },
  {
    value: Districts.Longerich,
    label: 'Longerich',
  },
  {
    value: Districts.Loevenich,
    label: 'Lövenich',
  },
  {
    value: Districts.Marienburg,
    label: 'Marienburg',
  },
  {
    value: Districts.Mauenheim,
    label: 'Mauenheim',
  },
  {
    value: Districts.Merheim,
    label: 'Merheim',
  },
  {
    value: Districts.Merkenich,
    label: 'Merkenich',
  },
  {
    value: Districts.Meschenich,
    label: 'Meschenich',
  },
  {
    value: Districts.Muelheim,
    label: 'Mülheim',
  },
  {
    value: Districts.Muengersdorf,
    label: 'Müngersdorf',
  },
  {
    value: Districts.Neuehrenfeld,
    label: 'Neuehrenfeld',
  },
  {
    value: Districts.Neubrueck,
    label: 'Neubrück',
  },
  {
    value: Districts.NeustadtNord,
    label: 'Neustadt-Nord',
  },
  {
    value: Districts.NeustadtSued,
    label: 'Neustadt-Süd',
  },
  {
    value: Districts.Niehl,
    label: 'Niehl',
  },
  {
    value: Districts.Nippes,
    label: 'Nippes',
  },
  {
    value: Districts.Ossendorf,
    label: 'Ossendorf',
  },
  {
    value: Districts.Ostheim,
    label: 'Ostheim',
  },
  {
    value: Districts.Pesch,
    label: 'Pesch',
  },
  {
    value: Districts.Poll,
    label: 'Poll',
  },
  {
    value: Districts.Porz,
    label: 'Porz',
  },
  {
    value: Districts.Raderberg,
    label: 'Raderberg',
  },
  {
    value: Districts.Raderthal,
    label: 'Raderthal',
  },
  {
    value: Districts.RathHeumar,
    label: 'Rath/Heumar',
  },
  {
    value: Districts.Riehl,
    label: 'Riehl',
  },
  {
    value: Districts.Rodenkirchen,
    label: 'Rodenkirchen',
  },
  {
    value: Districts.RoggendorfThenhoven,
    label: 'Roggendorf/Thenhoven',
  },
  {
    value: Districts.Rondorf,
    label: 'Rondorf',
  },
  {
    value: Districts.Seeberg,
    label: 'Seeberg',
  },
  {
    value: Districts.Stammheim,
    label: 'Stammheim',
  },
  {
    value: Districts.Suelz,
    label: 'Sülz',
  },
  {
    value: Districts.Suerth,
    label: 'Sürth',
  },
  {
    value: Districts.Urbach,
    label: 'Urbach',
  },
  {
    value: Districts.Vingst,
    label: 'Vingst',
  },
  {
    value: Districts.Vogelsang,
    label: 'Vogelsang',
  },
  {
    value: Districts.VolkhovenWeiler,
    label: 'Volkhoven/Weiler',
  },
  {
    value: Districts.Wahn,
    label: 'Wahn',
  },
  {
    value: Districts.Wahnheide,
    label: 'Wahnheide',
  },
  {
    value: Districts.Weiden,
    label: 'Weiden',
  },
  {
    value: Districts.Weidenpesch,
    label: 'Weidenpesch',
  },
  {
    value: Districts.Weiss,
    label: 'Weiß',
  },
  {
    value: Districts.Westhoven,
    label: 'Westhoven',
  },
  {
    value: Districts.Widdersdorf,
    label: 'Widdersdorf',
  },
  {
    value: Districts.Worringen,
    label: 'Worringen',
  },
  {
    value: Districts.Zollstock,
    label: 'Zollstock',
  },
  {
    value: Districts.Zuendorf,
    label: 'Zündorf',
  },
];
