import { CheckboxField, FormField } from '../../../../interfaces/form';

export interface LeadContactFormModel {
  formId: string;
  salutation: FormField;
  title?: FormField;
  firstName: FormField;
  lastName: FormField;
  customerNumber?: FormField;
  street: FormField;
  houseNumber: FormField;
  zipCode: FormField;
  city: FormField;
  email: FormField;
  countryCode: FormField;
  phoneNumber: FormField;
  agreedToDataUsage: CheckboxField;
}

export const formModel: LeadContactFormModel = {
  formId: 'leadContactForm',
  salutation: {
    name: 'salutation',
    label: 'Anrede',
  },
  title: {
    name: 'title',
    label: 'Titel',
  },
  firstName: {
    name: 'firstName',
    label: 'Vorname',
  },
  lastName: {
    name: 'lastName',
    label: 'Nachname',
  },
  customerNumber: {
    name: 'customerNumber',
    label: 'Kundennummer',
    placeholder: 'Bitte trage hier deine Kundennummer ein.',
  },
  street: {
    name: 'street',
    label: 'Straße',
  },
  houseNumber: {
    name: 'houseNumber',
    label: 'Hausnummer',
  },
  zipCode: {
    name: 'zipCode',
    label: 'PLZ',
  },
  city: {
    name: 'city',
    label: 'Ort',
  },
  email: {
    name: 'email',
    label: 'E-Mail-Adresse',
  },
  countryCode: {
    name: 'countryCode',
    label: 'Ländervorwahl',
  },
  phoneNumber: {
    name: 'phoneNumber',
    label: 'Handynummer',
  },
  agreedToDataUsage: {
    name: 'agreedToDataUsage',
  },
};
