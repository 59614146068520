import React, { useEffect, useState } from 'react';
import { TariffAdvisorStepId, TariffAdvisorValues } from '../index';
import {
  getAdvisorPoints,
  getAdvisorTemplateIds,
  GetAdvisorTemplateIdsResponse,
} from '../advisorHelper';
import { IconChevron, LoadingSpinner, Spacer, Text } from 'dss-ui-library';
import styles from '../TariffAdvisor.module.scss';
import { Link } from '@ncs-frontend-monorepo/content-library';
import { ProductProvider } from '../../ProductContext';
import { ResultPlan } from './ResultPlan';
import { pushDataLayer } from '../../../utils/dataLayer';
import { useAvailabilityModal } from '../../../hooks/useAvailabilityModal';
import { OrderPromotion } from '@ncs-frontend-monorepo/availability';
import { Footnote } from '@ncs-frontend-monorepo/order';

interface AdvisorResultProps {
  values: TariffAdvisorValues;
  maxDownload?: number;
  onReset(): void;
  footnotes?: Footnote[];
  promotions?: OrderPromotion[];
}

export const AdvisorResult: React.FC<AdvisorResultProps> = ({
  values,
  maxDownload,
  onReset,
  footnotes = [],
  promotions = [],
}) => {
  const { openModal } = useAvailabilityModal();
  const [advisorPlanIds, setAdvisorPlanIds] =
    useState<GetAdvisorTemplateIdsResponse>();
  const [isLongTerm, setLongTerm] = useState(true);

  const total = getAdvisorPoints(values);

  const handleOfferToggle = () => setLongTerm(!isLongTerm);

  useEffect(() => {
    setAdvisorPlanIds(
      getAdvisorTemplateIds({
        selection: values[TariffAdvisorStepId.Others],
        total,
        maxDownload,
        promotions,
      }),
    );
  }, [maxDownload, promotions]);

  useEffect(() => {
    if (advisorPlanIds?.proper) {
      pushDataLayer({
        event: 'tariffAdvisor',
        action: 'result',
        ...(advisorPlanIds?.proper && {
          templateId: advisorPlanIds.proper.id,
        }),
        ...(advisorPlanIds?.alternative && {
          alternativeId: advisorPlanIds.alternative.id,
        }),
        ...(advisorPlanIds?.fallback && {
          fallbackId: advisorPlanIds.fallback.id,
        }),
      });
    }
  }, [
    advisorPlanIds?.proper,
    advisorPlanIds?.alternative,
    advisorPlanIds?.fallback,
  ]);

  if (!advisorPlanIds?.proper) {
    return <LoadingSpinner theme="blue" />;
  }

  return (
    <ProductProvider>
      {advisorPlanIds?.fallback && (
        <Spacer b={2} block>
          <Text appearance="t2_2">Dein verfügbarer Tarif:</Text>
          <Spacer t={2} block />
          <ResultPlan
            product={advisorPlanIds?.fallback}
            handleAvailabilityCheck={openModal}
            handleOfferToggle={handleOfferToggle}
            isLongTerm={isLongTerm}
            footnotes={footnotes}
          />
        </Spacer>
      )}
      <Text appearance="t2_2">Unsere Empfehlung für dich:</Text>
      <Spacer t={2} block />
      <ResultPlan
        product={advisorPlanIds.proper}
        handleAvailabilityCheck={openModal}
        handleOfferToggle={handleOfferToggle}
        isLongTerm={isLongTerm}
        footnotes={footnotes}
        flagLabel="Dein idealer Tarif"
      />

      {advisorPlanIds?.alternative && (
        <Spacer t={3} block>
          <Text appearance="t3">Passt nicht? Hier ist deine Alternative:</Text>
          <Spacer t={2} block />
          <ResultPlan
            product={advisorPlanIds.alternative}
            handleAvailabilityCheck={openModal}
            handleOfferToggle={handleOfferToggle}
            isLongTerm={isLongTerm}
            footnotes={footnotes}
            isAlternative
          />
        </Spacer>
      )}

      <Spacer t={3} block />
      <Link
        e2e="form-back-button"
        url={null}
        onClick={onReset}
        className={styles.backLink}
      >
        <span className={styles.backLinkContent}>
          <IconChevron scale="small" rotation="270" small /> Tarifberater neu
          starten
        </span>
      </Link>
    </ProductProvider>
  );
};
