import { Grid, IconPhone, Spacer, Text } from 'dss-ui-library';
import { ChevronLink, Link } from '@ncs-frontend-monorepo/content-library';
import { FC, MouseEvent, useEffect } from 'react';
import {
  AvailabilityStatus,
  DataLayerEvents,
} from '../../../../interfaces/tracking';
import { pushAvailability } from '../../../../utils/dataLayer/availability';
import { isNC, Media } from '@ncs-frontend-monorepo/utils';
import { useAvailabilityContext } from '../../Context';
import { AddressRow } from '../../Elements/AddressRow';
import { FormFieldValues } from '../Form';
import {
  CONTACT_URL,
  EXPANSION_URL_NA,
  EXPANSION_URL_NC,
} from '../../../../constants/urls';
import {
  ExpansionStatus,
  useAvailability,
} from '@ncs-frontend-monorepo/availability';

interface AvailabilityErrorProps {
  address: FormFieldValues;
  onChangeAddressClick?(): void;
}

const AvailabilityError: FC<AvailabilityErrorProps> = ({
  address,
  onChangeAddressClick,
}) => {
  const { availabilityType, templateId, requestedDownload, manualCheck } =
    useAvailabilityContext();
  const { availability } = useAvailability();
  const { eventResult, eventLinks } = pushAvailability();
  const expansionLink = isNC() ? EXPANSION_URL_NC : EXPANSION_URL_NA;

  const handleExpansionLink = (e: MouseEvent<HTMLElement>) => {
    e.preventDefault();
    eventLinks({
      event: DataLayerEvents.ExpansionLink,
      status: AvailabilityStatus.NotAvailable,
      zipCode: address?.zipCode,
      checkSource: availabilityType,
      maxDownload: 0,
      targetPage: expansionLink,
      ...(availability?.objectInformation && {
        expansionStatus: availability.objectInformation.status,
      }),
    });
    location.href = expansionLink;
  };

  const handleContactLink = (e: MouseEvent<HTMLElement>) => {
    e.preventDefault();
    eventLinks({
      event: DataLayerEvents.ContactLink,
      status: AvailabilityStatus.NotAvailable,
      zipCode: address.zipCode,
      checkSource: availabilityType,
      maxDownload: 0,
      targetPage: CONTACT_URL,
      ...(availability?.objectInformation && {
        expansionStatus: availability.objectInformation.status,
      }),
    });
    location.href = CONTACT_URL;
  };

  useEffect(() => {
    eventResult({
      event: DataLayerEvents.Result,
      status: AvailabilityStatus.NotAvailable,
      expansionStatus: ExpansionStatus.ERROR,
      checkSource: availabilityType,
      checkStatus: manualCheck ? 'nicht-geprueft' : 'bereits-geprueft',
      zipCode: address?.zipCode,
      maxDownload: 0,
      ...(requestedDownload && { requestedDownload }),
      ...(templateId && { templateId }),
    });
  }, []);

  return (
    <div
      id="ac-result-error"
      data-e2e={`0-${templateId}-0-${address?.zipCode}-${templateId}-notAvailable`}
    >
      <Spacer b={1} r={4} block>
        <Text appearance="t2" color="blue">
          Der Verfügbarkeitscheck war nicht erfolgreich
        </Text>
      </Spacer>

      <Text appearance="t4_2">Stimmt deine Adresse?</Text>

      <AddressRow
        templateId={templateId}
        address={address}
        onChangeAddressClick={onChangeAddressClick}
      />
      <Grid fullWidth>
        <Grid.Row>
          <Grid.Column s={6}>
            <Text appearance="t5" block>
              Ja, meine Eingabe stimmt. Weitere Hilfe findest du hier:
            </Text>
            <Spacer t={1} block />
            <Link
              appearance="t4_2"
              url={CONTACT_URL}
              e2e={`${templateId}-contact`}
              icon={<IconPhone color="blue" width={24} height={24} />}
              onClick={handleContactLink}
            >
              Kontakt aufnehmen
            </Link>
          </Grid.Column>
          <Grid.Column s={6}>
            <Media lessThan="s">
              <Spacer t={3} block />
            </Media>
            <Text appearance="t5" block>
              Mehr zur Verfügbarkeit und Glasfaser für die Region findest du
              hier:
            </Text>
            <Spacer t={1} block />
            <ChevronLink
              appearance="t4_2"
              url={expansionLink}
              e2e={`${templateId}-glass-fiber`}
              indent
              onClick={handleExpansionLink}
            >
              Anschluss- und Ausbaugebiete
            </ChevronLink>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  );
};

export { AvailabilityError };
