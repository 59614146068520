import {
  BuildingOwnerFormBody,
  initialFormData,
} from '../../components/Forms/BuildingOwner/data';
import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';

interface BuildingOwnerFormState {
  initialState: BuildingOwnerFormBody;
  updateInitialState: (form: BuildingOwnerFormBody) => void;
}

export const useBuildingOwnerFormStore = create<BuildingOwnerFormState>()(
  persist(
    (set) => ({
      initialState: { ...initialFormData, fileUploadIds: [] },
      updateInitialState: (formData) =>
        set({ initialState: { ...formData, fileUploadIds: [] } }),
    }),
    {
      name: 'buildingOwnerForm',
      storage: createJSONStorage(() =>
        window['Cookiebot']?.consent?.preferences
          ? localStorage
          : sessionStorage,
      ),
    },
  ),
);
