import React, { useEffect, useRef, useState } from 'react';
import { Grid, Spacer, Step, Stepper, StepProps, Text } from 'dss-ui-library';
import dynamic from 'next/dynamic';
import { Persons, PersonsValue } from './Persons';
import { DevicesValue } from './Devices';
import { UsagesValue } from './Usages';
import { OthersValue } from './Others';
import { useOrderContext } from '../OrderContext';
import { AdvisorResult } from './AdvisorResult';
import { getEnv, useScrollTo } from '@ncs-frontend-monorepo/utils';
import { SlideTransition } from '@ncs-frontend-monorepo/content-library';
import { ButtonNavigation } from './Elements/ButtonNavigation';
import { AvailabilityCheck, CheckValue } from './AvailabilityCheck';
import styles from './TariffAdvisor.module.scss';
import { useAvailability } from '@ncs-frontend-monorepo/availability';
import { Footnote } from '@ncs-frontend-monorepo/order';

const Devices = dynamic(() =>
  import('./Devices').then(({ Devices }) => Devices),
);

const Usages = dynamic(() => import('./Usages').then(({ Usages }) => Usages));

const Others = dynamic(() => import('./Others').then(({ Others }) => Others));

type TariffAdvisorSteps = Pick<StepProps, 'title'> & {
  id: TariffAdvisorStepId;
  isRequired: boolean;
};

export enum TariffAdvisorStepId {
  Persons = 'persons',
  Devices = 'devices',
  Usages = 'usages',
  Others = 'others',
  Check = 'check',
}

const steps: TariffAdvisorSteps[] = [
  {
    id: TariffAdvisorStepId.Persons,
    title: 'Wie viele?',
    isRequired: true,
  },
  {
    id: TariffAdvisorStepId.Devices,
    title: 'Womit?',
    isRequired: true,
  },
  {
    id: TariffAdvisorStepId.Usages,
    title: 'Wozu?',
    isRequired: true,
  },
  {
    id: TariffAdvisorStepId.Others,
    title: 'Was noch?',
    isRequired: false,
  },
  {
    id: TariffAdvisorStepId.Check,
    title: 'Wo?',
    isRequired: true,
  },
];

export interface TariffAdvisor {
  headline?: string;
  footnotes?: Footnote[];
}

export type TariffAdvisorValues =
  | PersonsValue
  | DevicesValue
  | UsagesValue
  | OthersValue
  | CheckValue;

export interface OnUpdate {
  handleChange: (values: TariffAdvisorValues) => void;
}

const getAdvisorStep = ({
  id,
  currentSelection,
  updateValue,
  handleShowResult,
}: {
  id: TariffAdvisorStepId;
  currentSelection: TariffAdvisorValues;
  updateValue(values: TariffAdvisorValues): void;
  handleShowResult?(): void;
}) => {
  switch (id) {
    case TariffAdvisorStepId.Persons:
      return (
        <Persons
          persons={currentSelection?.[TariffAdvisorStepId.Persons]}
          handleChange={updateValue}
        />
      );
    case TariffAdvisorStepId.Devices:
      return (
        <Devices
          devices={currentSelection?.[TariffAdvisorStepId.Devices]}
          handleChange={updateValue}
        />
      );
    case TariffAdvisorStepId.Usages:
      return (
        <Usages
          usages={currentSelection?.[TariffAdvisorStepId.Usages]}
          handleChange={updateValue}
        />
      );
    case TariffAdvisorStepId.Others:
      return (
        <Others
          others={currentSelection?.[TariffAdvisorStepId.Others]}
          handleChange={updateValue}
        />
      );
    case TariffAdvisorStepId.Check:
      return (
        <AvailabilityCheck
          handleChange={updateValue}
          handleShowResult={handleShowResult}
        />
      );
    default:
      return null;
  }
};

export const initialValues: TariffAdvisorValues = {
  [TariffAdvisorStepId.Persons]: 0,
  [TariffAdvisorStepId.Devices]: [],
  [TariffAdvisorStepId.Usages]: [],
  [TariffAdvisorStepId.Others]: [],
  [TariffAdvisorStepId.Check]: null,
};

export const TariffAdvisor: React.FC<TariffAdvisor> = ({
  headline,
  footnotes,
}) => {
  const { saveFormData, formData } = useOrderContext();
  const { availability, handleAvailabilityCheck } = useAvailability();
  const [disabled, setDisabled] = useState(false);
  const [tariffAdvisorValues, setTariffAdvisorValues] =
    useState<TariffAdvisorValues>(initialValues);
  const [currentStep, setCurrentStep] = useState(0);
  const [showResult, setShowResult] = useState(false);
  const tariffRef = useRef<HTMLDivElement>(null);
  const [advisorSteps, setAdvisorSteps] = useState(steps);
  const scrollIntoView = useScrollTo(tariffRef);

  const updateValue = (values: TariffAdvisorValues) => {
    setTariffAdvisorValues({ ...tariffAdvisorValues, ...values });
  };

  const handleStepClick = (goto: number) => {
    setShowResult(false);
    setCurrentStep(goto);

    // prevent initial scroll into view on page load
    if (goto) scrollIntoView();
  };

  const handleShowResult = async () => {
    if (advisorSteps[currentStep].id === TariffAdvisorStepId.Check) {
      const {
        zipCode = '',
        street = '',
        houseNumber = '',
        city = '',
      } = tariffAdvisorValues?.[TariffAdvisorStepId.Check] || {};

      await handleAvailabilityCheck({
        address: { zipCode, street, houseNumber, city },
      });
    }
    setShowResult(true);
    scrollIntoView();
  };

  const resetAdvisor = () => {
    setShowResult(false);
    setTariffAdvisorValues(initialValues);
    setCurrentStep(0);
    scrollIntoView();
  };

  useEffect(() => {
    if (!advisorSteps[currentStep]) return;
    const currentValue = tariffAdvisorValues?.[advisorSteps[currentStep]?.id];

    if (advisorSteps[currentStep].id === TariffAdvisorStepId.Check) {
      setDisabled(
        !(
          currentValue?.zipCode?.length === 5 &&
          currentValue?.street?.length > 0 &&
          currentValue?.houseNumber?.length > 0
        ),
      );
    } else {
      setDisabled(
        advisorSteps[currentStep]?.isRequired
          ? currentValue?.length === 0 || currentValue === 0
          : false,
      );
      saveFormData({
        tariffAdvisorForm: tariffAdvisorValues,
      });
    }
  }, [tariffAdvisorValues, currentStep, advisorSteps]);

  useEffect(() => {
    setTariffAdvisorValues({
      ...initialValues,
      ...formData?.tariffAdvisorForm,
    });
  }, []);

  useEffect(() => {
    if (availability.maxDownload === null && availability.planned === null) {
      setAdvisorSteps(steps);
      setShowResult(false);
      handleStepClick(0);
    } else {
      setAdvisorSteps(
        steps.filter((steps) => steps.id !== TariffAdvisorStepId.Check),
      );
    }
  }, [availability.maxDownload, availability.planned]);

  return (
    <Grid className={styles.advisorWrapper}>
      <Grid.Row ref={tariffRef}>
        <Grid.Column>
          <Text appearance="t1" color="blue">
            {headline ? headline : `Dein Tarifberater von ${getEnv().SITE}.`}
          </Text>
          <Spacer t={4} b={3} block>
            <Stepper e2e="tariff-advisor-navigation">
              {advisorSteps.map((step, ix) => (
                <Step
                  {...step}
                  key={step.id}
                  state={
                    ix > currentStep
                      ? 'disabled'
                      : showResult
                      ? 'done'
                      : 'inProgress'
                  }
                  onClick={() => handleStepClick(ix)}
                />
              ))}
            </Stepper>
          </Spacer>
        </Grid.Column>
        {showResult ? (
          <Grid.Column>
            <AdvisorResult
              values={tariffAdvisorValues}
              maxDownload={
                availability.planned?.maxAvailableDownload ||
                availability.maxDownload
              }
              promotions={availability.promotions.order}
              onReset={resetAdvisor}
              footnotes={footnotes}
            />
          </Grid.Column>
        ) : (
          <>
            <Grid.Column e2e={steps[currentStep]?.id}>
              <SlideTransition index={currentStep} scrollTopAfterChange={false}>
                {getAdvisorStep({
                  id: advisorSteps[currentStep]?.id,
                  currentSelection: tariffAdvisorValues,
                  updateValue,
                  handleShowResult,
                })}
              </SlideTransition>
            </Grid.Column>
            <Grid.Column hEndS>
              <ButtonNavigation
                currentStep={currentStep}
                handleStepClick={handleStepClick}
                handleShowResult={handleShowResult}
                isSubmit={currentStep === advisorSteps.length - 1}
                disabled={disabled}
              />
            </Grid.Column>
          </>
        )}
      </Grid.Row>
    </Grid>
  );
};
