import React, { useEffect, useState } from 'react';
import { Checkbox, Grid, RadioGroup, Spacer, Text } from 'dss-ui-library';
import { FormikFields } from '../../../FormikFields';
import { cancellationFormModel, ContractType } from '../cancellationFormModel';
import { useFormikContext } from 'formik';
import { getEnv } from '@ncs-frontend-monorepo/utils';

export const Contract: React.FC = () => {
  const { values, setFieldValue, errors } = useFormikContext();
  const [selectedContracts, setSelectedContracts] = useState<ContractType[]>(
    [],
  );
  const [visiblePhoneNo, setVisiblePhoneNo] = useState(false);

  const handleContractSelection = (value: ContractType) => {
    setSelectedContracts(
      selectedContracts.includes(value)
        ? selectedContracts.filter((contract) => contract !== value)
        : [...selectedContracts, value],
    );
  };

  useEffect(() => {
    const cancelAll = values[cancellationFormModel.cancelSelection.name];
    if (cancelAll === 'all') {
      setFieldValue(cancellationFormModel.cancelContracts.name, []);
      setVisiblePhoneNo(true);
    } else {
      setVisiblePhoneNo(
        selectedContracts.length === 0 ||
          !(
            selectedContracts.length === 1 &&
            selectedContracts[0] === ContractType.Security
          ),
      );
      setFieldValue(
        cancellationFormModel.cancelContracts.name,
        selectedContracts,
        false,
      );
    }
  }, [selectedContracts, values[cancellationFormModel.cancelSelection.name]]);

  useEffect(() => {
    setSelectedContracts([]);
  }, [values[cancellationFormModel.cancelSelection.name]]);

  return (
    <>
      <Grid.Row>
        <Grid.Column s={10} m={8}>
          <Spacer y={3} block>
            <Text appearance="t2_2" element="h4">
              Welchen Vertrag möchtest du kündigen? *
            </Text>
          </Spacer>
          <RadioGroup e2e="cancel-contract">
            <FormikFields.Radio
              name={cancellationFormModel.cancelSelection.name}
              label="Ich möchte alle meine Verträge kündigen."
              e2e={cancellationFormModel.cancelSelection.name}
              value="all"
            />
            <Spacer y={1} block />
            <FormikFields.Radio
              name={cancellationFormModel.cancelSelection.name}
              label="Ich möchte nur einen bestimmten Vertrag kündigen."
              e2e={cancellationFormModel.cancelSelection.name}
              value="select"
            />
          </RadioGroup>
        </Grid.Column>
      </Grid.Row>
      {values[cancellationFormModel.cancelSelection.name] === 'select' && (
        <Grid.Row>
          <Grid.Column s={10} m={8}>
            <Spacer y={3} block>
              <Text appearance="t4_2" element="h4">
                Wähle den Vertrag, den du kündigen möchtest. Du kannst auch
                mehrere Verträge auswählen.
              </Text>
            </Spacer>
            <Checkbox
              name={cancellationFormModel.cancelLandline.name}
              label={cancellationFormModel.cancelLandline.label}
              e2e={cancellationFormModel.cancelLandline.name}
              onChange={() => {
                handleContractSelection(ContractType.Landline);
              }}
            />
            <Spacer y={1} block />
            <Checkbox
              name={cancellationFormModel.cancelTVInternet.name}
              label={cancellationFormModel.cancelTVInternet.label}
              e2e={cancellationFormModel.cancelTVInternet.name}
              onChange={() => {
                handleContractSelection(ContractType.TVInternet);
              }}
            />
            <Spacer y={1} block />
            <Checkbox
              name={cancellationFormModel.cancelSecurity.name}
              label={cancellationFormModel.cancelSecurity.label}
              e2e={cancellationFormModel.cancelSecurity.name}
              onChange={() => {
                handleContractSelection(ContractType.Security);
              }}
            />
            <Spacer y={1} block />
            <Checkbox
              name={cancellationFormModel.cancelMobile.name}
              label={cancellationFormModel.cancelMobile.label}
              e2e={cancellationFormModel.cancelMobile.name}
              onChange={() => {
                handleContractSelection(ContractType.Mobile);
              }}
            />
            <Spacer y={1} block />
            <Checkbox
              name={cancellationFormModel.cancelTVCable.name}
              label={cancellationFormModel.cancelTVCable.label}
              e2e={cancellationFormModel.cancelTVCable.name}
              onChange={() => {
                handleContractSelection(ContractType.TVCable);
              }}
            />
            {errors[cancellationFormModel.cancelContracts.name] && (
              <Spacer y={1} block />
            )}
            <FormikFields.Hidden
              name={cancellationFormModel.cancelContracts.name}
              label={cancellationFormModel.cancelContracts.name}
              e2e={cancellationFormModel.cancelContracts.name}
            />
          </Grid.Column>
        </Grid.Row>
      )}
      {visiblePhoneNo && (
        <Grid.Row>
          <Grid.Column s={10} m={8}>
            <Spacer t={3} block>
              <Text appearance="t4_2">
                Um deinen Vertrag zuordnen zu können, benötigen wir die
                Rufnummer deines {getEnv().SITE} Anschlusses, die du in der
                Auftragsbestätigung, deinen Rechnungen oder auch in
                &quot;MeineKundenwelt&quot; findest.
              </Text>
              <Spacer t={2} block />
              <FormikFields.TextField
                name={cancellationFormModel.installationPhoneNumber.name}
                label={cancellationFormModel.installationPhoneNumber.label}
                e2e={cancellationFormModel.installationPhoneNumber.name}
                placeholder={
                  cancellationFormModel.installationPhoneNumber.placeholder
                }
                isRequired={
                  selectedContracts.includes(ContractType.Landline) ||
                  values[cancellationFormModel.cancelSelection.name] === 'all'
                }
              />
            </Spacer>
          </Grid.Column>
        </Grid.Row>
      )}
    </>
  );
};
