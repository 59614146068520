import { OptionProps } from 'dss-ui-library/lib/components/Dropdown/Dropdown';
import { FormField } from '../../../interfaces/form';
import { Mandant } from '@ncs-frontend-monorepo/utils';

export interface CancellationFormModel {
  issue: FormField;
  message: FormField;
  cancelSelection: FormField;
  cancelContracts: FormField;
  cancelLandline: FormField;
  cancelTVInternet: FormField;
  cancelSecurity: FormField;
  cancelMobile: FormField;
  cancelTVCable: FormField;
  installationPhoneNumber: FormField;
  cancellationDateSelection: FormField;
  cancellationDate: FormField;
  customerNumber: FormField;
  salutation: FormField;
  title: FormField;
  firstName: FormField;
  lastName: FormField;
  zipCode: FormField;
  city: FormField;
  street: FormField;
  houseNumber: FormField;
  email: FormField;
  areaCode: FormField;
  phoneNo: FormField;
}

export const cancellationFormModel: CancellationFormModel = {
  issue: {
    name: 'issue',
    label: 'Mein Anliegen',
    placeholder: 'Bitte wähle ein Anliegen aus',
  },
  message: {
    name: 'message',
    label: 'Beschreibe hier dein Anliegen',
  },
  cancelSelection: {
    name: 'cancelContractSelection',
    label: '',
  },
  cancelContracts: {
    name: 'cancelContracts',
    label: '',
  },
  cancelLandline: {
    name: 'cancelLandline',
    label: 'Internet/Telefon',
  },
  cancelTVInternet: {
    name: 'cancelTvInternet',
    label: 'NetTV über Internet',
  },
  cancelSecurity: {
    name: 'cancelSecurity',
    label: 'Sicherheitspaket',
  },
  cancelMobile: {
    name: 'cancelMobile',
    label: 'Mobilfunk',
  },
  cancelTVCable: {
    name: 'cancelTvCable',
    label: 'NetTV über Kabel / Kabelfernsehen',
  },
  installationPhoneNumber: {
    name: 'installationPhoneNumber',
    label: 'Rufnummer',
    placeholder: 'Bitte trage die Rufnummer deines Anschlusses ein',
  },
  cancellationDateSelection: {
    name: 'cancellationDateSelection',
    label: '',
  },
  cancellationDate: {
    name: 'cancellationDate',
    label: 'Kündigungstermin',
  },
  customerNumber: {
    name: 'customerNumber',
    label: 'Kundennummer',
  },
  salutation: {
    name: 'salutation',
    label: 'Anrede',
  },
  title: {
    name: 'title',
    label: 'Titel',
  },
  firstName: {
    name: 'firstName',
    label: 'Vorname',
  },
  lastName: {
    name: 'lastName',
    label: 'Nachname',
  },
  zipCode: {
    name: 'zipCode',
    label: 'PLZ',
    placeholder: 'z. B. 50126',
  },
  city: {
    name: 'city',
    label: 'Ort',
  },
  street: {
    name: 'street',
    label: 'Straße',
    placeholder: 'Bitte gib hier deine Anschlussadresse an',
  },
  houseNumber: {
    name: 'houseNumber',
    label: 'Hausnummer',
    placeholder: 'z. B. 17a-17c',
  },
  email: {
    name: 'email',
    label: 'E-Mail-Adresse',
  },
  areaCode: {
    name: 'areaCode',
    label: 'Ländervorwahl',
  },
  phoneNo: {
    name: 'phoneNumber',
    label: 'Rufnummer bei Rückfragen',
    placeholder: 'z. B. 171 1234567',
  },
};

export enum ContractType {
  Landline = 'landline',
  TVInternet = 'nettv',
  Security = 'security',
  Mobile = 'mobile',
  TVCable = 'cabletv',
}

export enum IssueValues {
  OnTime = 'ordinaryCancellation',
  Exceeding = 'extraOrdinaryCancellation',
  Moving = 'moveToNewApartment',
  MovingOther = 'moveToAssistedLiving',
  Death = 'death',
}

export const issueOptions: OptionProps[] = [
  {
    value: IssueValues.OnTime,
    label: 'Ich möchte den Anschluss fristgerecht kündigen',
  },
  {
    value: IssueValues.Exceeding,
    label: 'Ich möchte den Anschluss außerordentlich kündigen',
  },
  {
    value: IssueValues.Moving,
    label: 'Ich ziehe um',
  },
  {
    value: IssueValues.MovingOther,
    label: 'Ich ziehe um in eine Form des betreuten Wohnens z. B. Pflegeheim',
  },
  {
    value: IssueValues.Death,
    label: 'Einen Sterbefall',
  },
];

export enum ReasonValues {
  Offer = 'betterDeal',
  Product = 'productNotOffered',
  Usage = 'productNotNeeded',
  Technology = 'noGlassFiber',
  Satisfaction = 'unhappyWithService',
  Bandwidth = 'unhappyWithBandwidth',
  Resign = 'other',
}

export const reasonOptions: OptionProps[] = [
  {
    value: ReasonValues.Offer,
    label: 'ein besseres Angebot erhalten habe',
  },
  {
    value: ReasonValues.Product,
    label: 'ein Produkt benötige, das ihr nicht anbietet',
  },
  {
    value: ReasonValues.Usage,
    label: 'das Produkt nicht mehr benötige',
  },
  {
    value: ReasonValues.Technology,
    label: 'keinen Glasfaseranschluss erhalten kann',
  },
  {
    value: ReasonValues.Satisfaction,
    label: 'mit dem Service nicht zufrieden bin',
  },
  {
    value: ReasonValues.Bandwidth,
    label: 'mit der Stabilität/Bandbreite unzufrieden bin',
  },
  {
    value: ReasonValues.Resign,
    label: 'mein Geschäft aufgebe',
  },
];

export interface CancellationRequest {
  mandant: Mandant;
  issue: IssueValues;
  reason: ReasonValues;
  message: string;
  evidenceUpload: string[];
  'cancel-contract-selection': 'select' | 'all';
  'cancel-contracts': ContractType[];
  'installation-phone-number': string;
  'cancellation-date-selection': string;
  'cancellation-date': string;
  'customer-number': string;
  salutation: string;
  title: string;
  'first-name': string;
  'last-name': string;
  'zip-code': string;
  city: string;
  street: string;
  'house-number': string;
  email: string;
  'area-code': string;
  'phone-number': string;
}
