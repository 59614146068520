import React, { useState } from 'react';
import { Grid, Spacer, Text } from 'dss-ui-library';
import { Formik } from 'formik';
import { initialData } from './initialData';
import { FormWrapper } from './Form';
import { schema } from './schema';
import { postCancellation } from '../../../utils/orderAPI';
import { Success } from './Success';
import { isNC } from '@ncs-frontend-monorepo/utils';

export const Cancellation: React.FC = () => {
  const [showSuccess, setShowSuccess] = useState(false);
  const [showError, setShowError] = useState(false);

  const handleSubmit = async (values, actions) => {
    try {
      const response = await postCancellation({
        mandant: isNC() ? 'nc' : 'na',
        ...values,
      });

      if (response) {
        setShowSuccess(true);
      } else {
        setShowError(true);
        actions.setSubmitting(false);
      }
    } catch (error) {
      setShowError(true);
      actions.setSubmitting(false);
    }
  };
  return (
    <>
      {showSuccess ? (
        <Success />
      ) : (
        <>
          <Grid>
            <Grid.Row>
              <Grid.Column>
                <Text appearance="t1_2" color="blue">
                  Vertrag über das Kontaktformular kündigen
                </Text>
                <Spacer t={2} block />
                <Text appearance="t3">
                  Um deinen Vertrag kündigen zu können, benötigen wir einige
                  Angaben von dir.
                </Text>
              </Grid.Column>
            </Grid.Row>
          </Grid>
          <Formik
            initialValues={initialData}
            onSubmit={handleSubmit}
            validationSchema={schema}
            validateOnChange={false}
          >
            <FormWrapper hasError={showError} />
          </Formik>
        </>
      )}
    </>
  );
};
