import * as Yup from 'yup';
import {
  addressValidation,
  emailValidation,
  generalFieldValidation,
  maxFieldLengthText,
  requiredText,
  urlValidation,
} from '@ncs-frontend-monorepo/forms';
import { resellerContactModel } from './model';

export const schema = Yup.object().shape({
  [resellerContactModel.company.name]: Yup.string()
    .max(50, maxFieldLengthText(50))
    .required(requiredText),
  [resellerContactModel.salutation.name]: Yup.string().required(requiredText),
  [resellerContactModel.firstName.name]: Yup.string()
    .max(30, maxFieldLengthText(30))
    .required(requiredText),
  [resellerContactModel.lastName.name]: Yup.string()
    .max(30, maxFieldLengthText(30))
    .required(requiredText),
  [resellerContactModel.zipCode.name]:
    addressValidation.zipCode.required(requiredText),
  [resellerContactModel.city.name]:
    addressValidation.city.required(requiredText),
  [resellerContactModel.street.name]:
    addressValidation.street.required(requiredText),
  [resellerContactModel.houseNumber.name]:
    addressValidation.houseNumber.required(requiredText),
  [resellerContactModel.email.name]: emailValidation.required(requiredText),
  [resellerContactModel.countryCode.name]: Yup.string().required(requiredText),
  [resellerContactModel.phoneNo.name]:
    generalFieldValidation.phoneLong.required(requiredText),
  [resellerContactModel.salesroom.name]: Yup.string().required(requiredText),
  [resellerContactModel.website.name]: urlValidation,
});
