import {
  initialFormData,
  validationSchema,
} from '../../AvailabilityCheck/Views/Form';
import { OnUpdate, TariffAdvisorStepId } from '../index';
import React, { useEffect } from 'react';
import { AvailabilityCheckForm } from './Form';
import { Formik } from 'formik';
import { useAvailability } from '@ncs-frontend-monorepo/availability';

export interface AvailabilityValues {
  zipCode: string;
  street: string;
  houseNumber: string;
  city: string;
}
export interface CheckValue {
  [TariffAdvisorStepId.Check]: AvailabilityValues | null;
}

type AvailabilityCheckProps = OnUpdate & {
  handleShowResult(): void;
};
export const AvailabilityCheck: React.FC<AvailabilityCheckProps> = ({
  handleChange,
  handleShowResult,
}) => {
  const {
    availability: { address },
  } = useAvailability();
  const updateValues = (values: AvailabilityValues) => {
    handleChange({ [TariffAdvisorStepId.Check]: values });
  };

  useEffect(() => {
    if (address) {
      handleChange({ [TariffAdvisorStepId.Check]: address });
    }
  }, [address]);

  return (
    <Formik
      validateOnBlur
      initialValues={initialFormData}
      validateOnChange={false}
      validationSchema={validationSchema}
      onSubmit={handleShowResult}
    >
      <AvailabilityCheckForm updateValues={updateValues} />
    </Formik>
  );
};
