import React from 'react';
import styles from '../TariffAdvisor.module.scss';
import { Link } from '@ncs-frontend-monorepo/content-library';
import { Button, IconChevron, useBreakpoints } from 'dss-ui-library';

interface ButtonNavigationProps {
  currentStep: number;
  handleStepClick(step: number): void;
  handleShowResult(): void;
  isSubmit: boolean;
  disabled: boolean;
}

export const ButtonNavigation: React.FC<ButtonNavigationProps> = ({
  currentStep,
  handleStepClick,
  handleShowResult,
  isSubmit,
  disabled,
}) => {
  const { isXS } = useBreakpoints();
  return (
    <div className={styles.buttonWrapper}>
      {currentStep > 0 && (
        <Link
          e2e="form-back-button"
          url={null}
          onClick={() => handleStepClick(currentStep - 1)}
          className={styles.backLink}
        >
          <span className={styles.backLinkContent}>
            <IconChevron scale="small" rotation="270" small /> Zurück
          </span>
        </Link>
      )}
      {isSubmit ? (
        <Button
          variant="primary"
          color="red"
          e2e="tariff-advisor-submit"
          onClick={handleShowResult}
          fullWidth={isXS}
          disabled={disabled}
        >
          Zum Ergebnis
        </Button>
      ) : (
        <Button
          variant="primary"
          color="blue"
          e2e="tariff-advisor-next-step"
          onClick={() => handleStepClick(currentStep + 1)}
          fullWidth={isXS}
          disabled={disabled}
        >
          Weiter
        </Button>
      )}
    </div>
  );
};
