import React, { useState } from 'react';
import { isNC } from '@ncs-frontend-monorepo/utils';
import { Formik } from 'formik';
import { initialData } from './initialData';
import { Form } from './Form';
import { postResellerContact } from '../../../utils/orderAPI';
import { schema } from './schema';
import { Grid, Spacer, Text } from 'dss-ui-library';
import { useOrderContext } from '../../OrderContext';
import { resellerContactModel } from './model';
import { FormsWrapper } from '../FormsWrapper';
import { useAvailability } from '@ncs-frontend-monorepo/availability';

export const ResellerContact: React.FC = () => {
  const { formData } = useOrderContext();
  const { address } = useAvailability();
  const [hasError, setHasError] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);

  const formValues = {
    ...initialData,
    ...(formData?.resellerContact || {}),
    ...(address && {
      [resellerContactModel.zipCode.name]: address.zipCode,
      [resellerContactModel.city.name]: address.city,
      [resellerContactModel.street.name]: address.street,
      [resellerContactModel.houseNumber.name]: address.houseNumber,
    }),
  };

  const handleSubmit = async (values, actions) => {
    try {
      const response = await postResellerContact({
        mandant: isNC() ? 'NC' : 'NA',
        ...values,
      });
      if (response) {
        setShowSuccess(true);
      } else {
        setHasError(true);
        actions.setSubmitting(false);
      }
    } catch (error) {
      setHasError(true);
      actions.setSubmitting(false);
    }
    setHasError(true);
  };

  return (
    <>
      <Grid>
        <Text appearance="t2" color="blue">
          Anmeldung Vertriebspartner.
        </Text>
      </Grid>
      <Spacer b={4} block />
      <FormsWrapper
        isSubmitted={showSuccess}
        contentBlock="reseller-contact-success"
        e2e="reseller-contact-success"
      >
        <Formik
          initialValues={formValues}
          onSubmit={handleSubmit}
          validateOnChange={false}
          validationSchema={schema}
        >
          <Form hasError={hasError} />
        </Formik>
      </FormsWrapper>
    </>
  );
};
