import { fetcherBoolean, Mandant } from '@ncs-frontend-monorepo/utils';
import { BuildingOwnerFormBody } from './data';
import { orderServiceURL } from '../../../utils/orderAPI';

interface SubmitData extends BuildingOwnerFormBody {
  mandant: Mandant;
}

export async function submitBuildingOwnerForm(
  data: SubmitData,
): Promise<boolean> {
  try {
    const isSuccess = await fetcherBoolean(
      `${orderServiceURL()}/support/website/building-owner`,
      {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      },
    );
    return isSuccess;
  } catch (e) {
    return false;
  }
}
