import React from 'react';
import useSWR from 'swr';
import { fetchPage } from '../../../utils';
import { DynamicContent } from '@ncs-frontend-monorepo/content-library';
import { LoadingSpinner } from 'dss-ui-library';
import { CANCELLATION_SUCCESS_PAGE_IDS } from '../../../constants';
import { getEnv } from '@ncs-frontend-monorepo/utils';

export const Success: React.FC = () => {
  const { data: page } = useSWR(
    CANCELLATION_SUCCESS_PAGE_IDS[getEnv().SITE as string],
    fetchPage,
  );

  return (
    <>
      {page ? (
        page.content.map((c) => (
          <div key={c.id}>
            <DynamicContent content={c} />
          </div>
        ))
      ) : (
        <LoadingSpinner theme="blue" />
      )}
    </>
  );
};
