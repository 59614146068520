import { ProductAction, ProductActionType, ProductState } from './index';
import { ProductModifiers } from '@ncs-frontend-monorepo/order';

export const productReducer = (state: ProductState, action: ProductAction) => {
  switch (action.type) {
    case ProductActionType.TOGGLE_TELEPHONY:
      return {
        ...state,
        telephonyEnabled: !state.telephonyEnabled,
      };
    case ProductActionType.TOGGLE_NETTV:
      return {
        ...state,
        nettvEnabled: !state.nettvEnabled,
      };
    case ProductActionType.SET_MODIFIER:
      return {
        ...state,
        ...(action.payload.modifier === ProductModifiers.withoutPhone && {
          nettvEnabled: true,
          telephonyEnabled: false,
        }),
        ...(action.payload.modifier === ProductModifiers.withoutTv && {
          nettvEnabled: false,
          telephonyEnabled: true,
        }),
        ...(action.payload.modifier === ProductModifiers.withoutAll && {
          nettvEnabled: false,
          telephonyEnabled: false,
        }),
        ...((action.payload.modifier === ProductModifiers.withoutModifier ||
          action.payload.modifier === null) && {
          nettvEnabled: true,
          telephonyEnabled: true,
        }),
      };
    case ProductActionType.TOGGLE_INFO:
      return {
        ...state,
        toggledDetail: state.toggledDetail.includes(
          action.payload.toggleDetailType,
        )
          ? state.toggledDetail.filter(
              (type) => type !== action.payload.toggleDetailType,
            )
          : [...state.toggledDetail, action.payload.toggleDetailType],
      };
    default:
      return state;
  }
};
