import { OthersSelection } from './Others';
import { BANDWIDTHS } from '../../constants';
import {
  getOrderPromotionTechnologyByBandwidth,
  getTemplateId,
} from '../../utils/plan';
import { TariffAdvisorStepId, TariffAdvisorValues } from './index';
import { deviceSelection } from './Devices';
import { usageSelection } from './Usages';
import { ProductId } from '@ncs-frontend-monorepo/order';
import {
  OrderPromotion,
  Technology,
} from '@ncs-frontend-monorepo/availability';

interface AdvisorTemplateIdItem {
  id: ProductId;
  technology: Technology;
}

export interface GetAdvisorTemplateIdsResponse {
  proper: AdvisorTemplateIdItem;
  alternative?: AdvisorTemplateIdItem;
  fallback?: AdvisorTemplateIdItem;
}

interface GetAdvisorTemplateIds {
  selection: OthersSelection[];
  total: number;
  maxDownload?: number;
  promotions?: OrderPromotion[];
}

const BANDWIDTH_BREAKPOINTS_INDEX = [10, 17, 28, 36];

const getBandwidth = (points: number): number => {
  const bandwidthIndex = BANDWIDTH_BREAKPOINTS_INDEX.findIndex(
    (threshold) => points < threshold,
  );
  return bandwidthIndex === -1 ? BANDWIDTHS.length - 1 : bandwidthIndex;
};

export const getAdvisorTemplateIds = ({
  selection,
  total,
  maxDownload = 0,
  promotions = [],
}: GetAdvisorTemplateIds): GetAdvisorTemplateIdsResponse => {
  const bandwidthIndex = getBandwidth(total);
  const recommendedBandwidth = BANDWIDTHS[bandwidthIndex];
  const alternativeBandwidth =
    BANDWIDTHS[bandwidthIndex < 3 ? bandwidthIndex + 1 : bandwidthIndex - 1];

  const proper = {
    id: getTemplateId({
      bandwidth: recommendedBandwidth,
      isYoung: selection.includes(OthersSelection.Student),
      withPhone: selection.includes(OthersSelection.Phone),
      withTv: selection.includes(OthersSelection.Tv),
    }),
    technology: getOrderPromotionTechnologyByBandwidth({
      promotions,
      bandwidth: recommendedBandwidth,
    }),
  };

  const alternative = {
    id: getTemplateId({
      bandwidth: alternativeBandwidth,
      isYoung: selection.includes(OthersSelection.Student),
      withPhone: selection.includes(OthersSelection.Phone),
      withTv: selection.includes(OthersSelection.Tv),
    }),
    technology: getOrderPromotionTechnologyByBandwidth({
      promotions,
      bandwidth: alternativeBandwidth,
    }),
  };

  // only consider marketable bandwidths
  if (maxDownload > 18) {
    const fallback = {
      id: getTemplateId({
        bandwidth: maxDownload,
        isYoung: selection.includes(OthersSelection.Student),
        withPhone: selection.includes(OthersSelection.Phone),
        withTv: selection.includes(OthersSelection.Tv),
      }),
      technology: getOrderPromotionTechnologyByBandwidth({
        promotions,
        bandwidth: maxDownload,
      }),
    };

    if (maxDownload < recommendedBandwidth) {
      return {
        proper,
        alternative,
        ...(alternative.id !== fallback.id && {
          fallback,
        }),
      };
    }

    if (maxDownload < alternativeBandwidth) {
      // edge case for 175 Mbit/s
      if (maxDownload > recommendedBandwidth) {
        return {
          proper,
          alternative: {
            id: getTemplateId({
              bandwidth: maxDownload,
              isYoung: selection.includes(OthersSelection.Student),
              withPhone: selection.includes(OthersSelection.Phone),
              withTv: selection.includes(OthersSelection.Tv),
            }),
            technology: getOrderPromotionTechnologyByBandwidth({
              promotions,
              bandwidth: maxDownload,
            }),
          },
        };
      }

      return {
        proper,
      };
    }
  }

  return {
    proper,
    alternative,
  };
};

export const getAdvisorPoints = (values: TariffAdvisorValues): number => {
  const personsResult = values[TariffAdvisorStepId.Persons];
  const devicesResult = deviceSelection
    .filter((device) =>
      values[TariffAdvisorStepId.Devices].includes(device.value),
    )
    .reduce((a, b) => a + b.points, 0);
  const usagesResult = usageSelection
    .filter((usage) => values[TariffAdvisorStepId.Usages].includes(usage.value))
    .reduce((a, b) => a + b.points, 0);

  return personsResult + devicesResult + usagesResult;
};
