import { FC } from 'react';
import dynamic from 'next/dynamic';

const AltstadtNord = dynamic(() =>
  import('./Districts/AltstadtNord').then(({ AltstadtNord }) => AltstadtNord),
);

const AltstadtSued = dynamic(() =>
  import('./Districts/AltstadtSued').then(({ AltstadtSued }) => AltstadtSued),
);

const Bayenthal = dynamic(() =>
  import('./Districts/Bayenthal').then(({ Bayenthal }) => Bayenthal),
);

const Bickendorf = dynamic(() =>
  import('./Districts/Bickendorf').then(({ Bickendorf }) => Bickendorf),
);

const Bilderstoeckchen = dynamic(() =>
  import('./Districts/Bilderstoeckchen').then(
    ({ Bilderstoeckchen }) => Bilderstoeckchen,
  ),
);

const Blumenberg = dynamic(() =>
  import('./Districts/Blumenberg').then(({ Blumenberg }) => Blumenberg),
);

const Bocklemuend = dynamic(() =>
  import('./Districts/Bocklemuend').then(({ Bocklemuend }) => Bocklemuend),
);

const Braunsfeld = dynamic(() =>
  import('./Districts/Braunsfeld').then(({ Braunsfeld }) => Braunsfeld),
);

const Brueck = dynamic(() =>
  import('./Districts/Brueck').then(({ Brueck }) => Brueck),
);

const Buchforst = dynamic(() =>
  import('./Districts/Buchforst').then(({ Buchforst }) => Buchforst),
);

const Buchheim = dynamic(() =>
  import('./Districts/Buchheim').then(({ Buchheim }) => Buchheim),
);

const Chorweiler = dynamic(() =>
  import('./Districts/Chorweiler').then(({ Chorweiler }) => Chorweiler),
);

const Dellbrueck = dynamic(() =>
  import('./Districts/Dellbrueck').then(({ Dellbrueck }) => Dellbrueck),
);

const Deutz = dynamic(() =>
  import('./Districts/Deutz').then(({ Deutz }) => Deutz),
);

const Duennwald = dynamic(() =>
  import('./Districts/Duennwald').then(({ Duennwald }) => Duennwald),
);

const Ehrenfeld = dynamic(() =>
  import('./Districts/Ehrenfeld').then(({ Ehrenfeld }) => Ehrenfeld),
);

const Eil = dynamic(() => import('./Districts/Eil').then(({ Eil }) => Eil));

const Elsdorf = dynamic(() =>
  import('./Districts/Elsdorf').then(({ Elsdorf }) => Elsdorf),
);

const Ensen = dynamic(() =>
  import('./Districts/Ensen').then(({ Ensen }) => Ensen),
);

const EschAuweiler = dynamic(() =>
  import('./Districts/EschAuweiler').then(({ EschAuweiler }) => EschAuweiler),
);

const Finkenberg = dynamic(() =>
  import('./Districts/Finkenberg').then(({ Finkenberg }) => Finkenberg),
);

const Flittard = dynamic(() =>
  import('./Districts/Flittard').then(({ Flittard }) => Flittard),
);

const Fuehlingen = dynamic(() =>
  import('./Districts/Fuehlingen').then(({ Fuehlingen }) => Fuehlingen),
);

const Godorf = dynamic(() =>
  import('./Districts/Godorf').then(({ Godorf }) => Godorf),
);

const Gremberghoven = dynamic(() =>
  import('./Districts/Gremberghoven').then(
    ({ Gremberghoven }) => Gremberghoven,
  ),
);

const Grengel = dynamic(() =>
  import('./Districts/Grengel').then(({ Grengel }) => Grengel),
);

const Hahnwald = dynamic(() =>
  import('./Districts/Hahnwald').then(({ Hahnwald }) => Hahnwald),
);

const Heimersdorf = dynamic(() =>
  import('./Districts/Heimersdorf').then(({ Heimersdorf }) => Heimersdorf),
);

const Hoehenberg = dynamic(() =>
  import('./Districts/Hoehenberg').then(({ Hoehenberg }) => Hoehenberg),
);

const Hoehenhaus = dynamic(() =>
  import('./Districts/Hoehenhaus').then(({ Hoehenhaus }) => Hoehenhaus),
);

const Holweide = dynamic(() =>
  import('./Districts/Holweide').then(({ Holweide }) => Holweide),
);

const HumboldtGremberg = dynamic(() =>
  import('./Districts/HumboldtGremberg').then(
    ({ HumboldtGremberg }) => HumboldtGremberg,
  ),
);

const Immendorf = dynamic(() =>
  import('./Districts/Immendorf').then(({ Immendorf }) => Immendorf),
);

const Junkersdorf = dynamic(() =>
  import('./Districts/Junkersdorf').then(({ Junkersdorf }) => Junkersdorf),
);

const Kalk = dynamic(() => import('./Districts/Kalk').then(({ Kalk }) => Kalk));

const Klettenberg = dynamic(() =>
  import('./Districts/Klettenberg').then(({ Klettenberg }) => Klettenberg),
);

const Langel = dynamic(() =>
  import('./Districts/Langel').then(({ Langel }) => Langel),
);

const Libur = dynamic(() =>
  import('./Districts/Libur').then(({ Libur }) => Libur),
);

const Lind = dynamic(() => import('./Districts/Lind').then(({ Lind }) => Lind));

const Lindenthal = dynamic(() =>
  import('./Districts/Lindenthal').then(({ Lindenthal }) => Lindenthal),
);

const Lindweiler = dynamic(() =>
  import('./Districts/Lindweiler').then(({ Lindweiler }) => Lindweiler),
);

const Loevenich = dynamic(() =>
  import('./Districts/Loevenich').then(({ Loevenich }) => Loevenich),
);

const Longerich = dynamic(() =>
  import('./Districts/Longerich').then(({ Longerich }) => Longerich),
);

const Marienburg = dynamic(() =>
  import('./Districts/Marienburg').then(({ Marienburg }) => Marienburg),
);

const Mauenheim = dynamic(() =>
  import('./Districts/Mauenheim').then(({ Mauenheim }) => Mauenheim),
);

const Merheim = dynamic(() =>
  import('./Districts/Merheim').then(({ Merheim }) => Merheim),
);

const Merkenich = dynamic(() =>
  import('./Districts/Merkenich').then(({ Merkenich }) => Merkenich),
);

const Meschenich = dynamic(() =>
  import('./Districts/Meschenich').then(({ Meschenich }) => Meschenich),
);

const Muelheim = dynamic(() =>
  import('./Districts/Muelheim').then(({ Muelheim }) => Muelheim),
);

const Muengersdorf = dynamic(() =>
  import('./Districts/Muengersdorf').then(({ Muengersdorf }) => Muengersdorf),
);

const Neubrueck = dynamic(() =>
  import('./Districts/Neubrueck').then(({ Neubrueck }) => Neubrueck),
);

const Neuehrenfeld = dynamic(() =>
  import('./Districts/Neuehrenfeld').then(({ Neuehrenfeld }) => Neuehrenfeld),
);

const NeustadtNord = dynamic(() =>
  import('./Districts/NeustadtNord').then(({ NeustadtNord }) => NeustadtNord),
);

const NeustadtSued = dynamic(() =>
  import('./Districts/NeustadtSued').then(({ NeustadtSued }) => NeustadtSued),
);

const Niehl = dynamic(() =>
  import('./Districts/Niehl').then(({ Niehl }) => Niehl),
);

const Nippes = dynamic(() =>
  import('./Districts/Nippes').then(({ Nippes }) => Nippes),
);

const Ossendorf = dynamic(() =>
  import('./Districts/Ossendorf').then(({ Ossendorf }) => Ossendorf),
);

const Ostheim = dynamic(() =>
  import('./Districts/Ostheim').then(({ Ostheim }) => Ostheim),
);

const Pesch = dynamic(() =>
  import('./Districts/Pesch').then(({ Pesch }) => Pesch),
);

const Poll = dynamic(() => import('./Districts/Poll').then(({ Poll }) => Poll));

const Porz = dynamic(() => import('./Districts/Porz').then(({ Porz }) => Porz));

const Raderberg = dynamic(() =>
  import('./Districts/Raderberg').then(({ Raderberg }) => Raderberg),
);

const Raderthal = dynamic(() =>
  import('./Districts/Raderthal').then(({ Raderthal }) => Raderthal),
);

const RathHeumar = dynamic(() =>
  import('./Districts/RathHeumar').then(({ RathHeumar }) => RathHeumar),
);

const Riehl = dynamic(() =>
  import('./Districts/Riehl').then(({ Riehl }) => Riehl),
);

const Rodenkirchen = dynamic(() =>
  import('./Districts/Rodenkirchen').then(({ Rodenkirchen }) => Rodenkirchen),
);

const RoggendorfThenhoven = dynamic(() =>
  import('./Districts/RoggendorfThenhoven').then(
    ({ RoggendorfThenhoven }) => RoggendorfThenhoven,
  ),
);

const Rondorf = dynamic(() =>
  import('./Districts/Rondorf').then(({ Rondorf }) => Rondorf),
);

const Seeberg = dynamic(() =>
  import('./Districts/Seeberg').then(({ Seeberg }) => Seeberg),
);
const Stammheim = dynamic(() =>
  import('./Districts/Stammheim').then(({ Stammheim }) => Stammheim),
);

const Suelz = dynamic(() =>
  import('./Districts/Suelz').then(({ Suelz }) => Suelz),
);

const Suerth = dynamic(() =>
  import('./Districts/Suerth').then(({ Suerth }) => Suerth),
);

const Urbach = dynamic(() =>
  import('./Districts/Urbach').then(({ Urbach }) => Urbach),
);

const Vingst = dynamic(() =>
  import('./Districts/Vingst').then(({ Vingst }) => Vingst),
);

const Vogelsang = dynamic(() =>
  import('./Districts/Vogelsang').then(({ Vogelsang }) => Vogelsang),
);

const VolkhovenWeiler = dynamic(() =>
  import('./Districts/VolkhovenWeiler').then(
    ({ VolkhovenWeiler }) => VolkhovenWeiler,
  ),
);

const Wahn = dynamic(() => import('./Districts/Wahn').then(({ Wahn }) => Wahn));

const Wahnheide = dynamic(() =>
  import('./Districts/Wahnheide').then(({ Wahnheide }) => Wahnheide),
);

const Weiden = dynamic(() =>
  import('./Districts/Weiden').then(({ Weiden }) => Weiden),
);

const Weidenpesch = dynamic(() =>
  import('./Districts/Weidenpesch').then(({ Weidenpesch }) => Weidenpesch),
);

const Weiss = dynamic(() =>
  import('./Districts/Weiss').then(({ Weiss }) => Weiss),
);

const Westhoven = dynamic(() =>
  import('./Districts/Westhoven').then(({ Westhoven }) => Westhoven),
);

const Widdersdorf = dynamic(() =>
  import('./Districts/Widdersdorf').then(({ Widdersdorf }) => Widdersdorf),
);

const Worringen = dynamic(() =>
  import('./Districts/Worringen').then(({ Worringen }) => Worringen),
);

const Zollstock = dynamic(() =>
  import('./Districts/Zollstock').then(({ Zollstock }) => Zollstock),
);

const Zuendorf = dynamic(() =>
  import('./Districts/Zuendorf').then(({ Zuendorf }) => Zuendorf),
);
export const CologneDistricts: FC = () => (
  <>
    <AltstadtNord />
    <AltstadtSued />
    <Bayenthal />
    <Bickendorf />
    <Bilderstoeckchen />
    <Blumenberg />
    <Bocklemuend />
    <Braunsfeld />
    <Chorweiler />
    <Brueck />
    <Buchforst />
    <Buchheim />
    <Dellbrueck />
    <Deutz />
    <Duennwald />
    <Ehrenfeld />
    <Eil />
    <Elsdorf />
    <Ensen />
    <EschAuweiler />
    <Flittard />
    <Fuehlingen />
    <Godorf />
    <Gremberghoven />
    <Grengel />
    <Hahnwald />
    <Heimersdorf />
    <Hoehenberg />
    <Hoehenhaus />
    <Holweide />
    <Immendorf />
    <Junkersdorf />
    <Kalk />
    <HumboldtGremberg />
    <Klettenberg />
    <Langel />
    <Libur />
    <Lind />
    <Lindenthal />
    <Loevenich />
    <Longerich />
    <Marienburg />
    <Merheim />
    <Merkenich />
    <Meschenich />
    <Muelheim />
    <Muengersdorf />
    <Neuehrenfeld />
    <NeustadtNord />
    <NeustadtSued />
    <Niehl />
    <Nippes />
    <Ossendorf />
    <Ostheim />
    <Pesch />
    <Lindweiler />
    <Poll />
    <Porz />
    <Finkenberg />
    <RathHeumar />
    <Neubrueck />
    <Riehl />
    <Rodenkirchen />
    <RoggendorfThenhoven />
    <Rondorf />
    <Seeberg />
    <Stammheim />
    <Suelz />
    <Suerth />
    <Urbach />
    <Vingst />
    <Vogelsang />
    <VolkhovenWeiler />
    <Wahn />
    <Wahnheide />
    <Weiden />
    <Weidenpesch />
    <Mauenheim />
    <Weiss />
    <Westhoven />
    <Widdersdorf />
    <Worringen />
    <Zollstock />
    <Raderthal />
    <Raderberg />
    <Zuendorf />
  </>
);
