import {
  Button,
  ErrorMessage,
  Grid,
  Spacer,
  Text,
  RadioGroup,
} from 'dss-ui-library';
import { Form, useFormikContext } from 'formik';
import {
  SalutationDropdown,
  TitleDropdown,
  TextField,
  CountryCodeDropdown,
  ZipCodeAutoSuggest,
  StreetAutoSuggest,
  RequiredInfo,
  PrivacyInfo,
  Payment,
  Decision,
  useAddressAutoSuggest,
  AddressSource,
  FetchAddressType,
} from '@ncs-frontend-monorepo/forms';
import { AssociationFormBody, associationForm } from './data';
import { ErrorFocus } from '../../ErrorFocus';
import { FormikFields } from '../../FormikFields';

interface AssociationFormProps {
  hasError: boolean;
}

export const AssociationForm: React.FC<AssociationFormProps> = ({
  hasError,
}) => {
  const { isSubmitting, values, setFieldValue } =
    useFormikContext<AssociationFormBody>();
  const { getZipCodes, onZipSelection, getAddresses, onStreetSelection } =
    useAddressAutoSuggest();
  const iban = values[associationForm.iban.name];

  return (
    <Form>
      <Grid>
        <Grid.Row>
          <Grid.Column s={10} m={8}>
            <Text appearance="t2" color="blue">
              Registrierung für Vereine.
            </Text>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <Spacer y={2} block>
              <Text appearance="t2_2">Angaben zum Verein:</Text>
            </Spacer>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column marginBottom={3} s={10} m={8}>
            <TextField
              field={associationForm.associationName}
              e2e="association-name"
            />
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column s={3}>
            <ZipCodeAutoSuggest
              e2e="association-zipCode"
              field={associationForm.zipCode}
              getOptions={(zipCode: string) =>
                getZipCodes({ zipCode, mode: AddressSource.local })
              }
              onSelection={(options) => {
                const selection = onZipSelection(options);
                setFieldValue('city', selection.city);
                return selection;
              }}
            />
          </Grid.Column>
          <Grid.Column marginBottom={3} m={5} s={7}>
            <TextField field={associationForm.city} e2e="association-city" />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column m={5} s={7}>
            <StreetAutoSuggest
              placeholder="Bitte geben Sie Ihre Anschlussadresse an	"
              field={associationForm.street}
              e2e="association-street"
              getOptions={(streetPartial) => {
                return getAddresses({
                  streetPartial,
                  zipCode: values.zipCode,
                  type: FetchAddressType.streets,
                  mode: AddressSource.local,
                });
              }}
              onSelection={onStreetSelection}
              targetCity={associationForm.city.name}
            />
          </Grid.Column>
          <Grid.Column marginBottom={3} s={3}>
            <TextField
              field={associationForm.houseNumber}
              e2e="association-houseNumber"
            />
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column>
            <Text appearance="t2_2">Ansprechpartner und Kontakt:</Text>
            <Spacer t={2} block />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column marginBottom={3} s={5} m={4}>
            <SalutationDropdown
              field={associationForm.salutation}
              e2e="association-salutation"
            />
          </Grid.Column>
          <Grid.Column marginBottom={3} s={5} m={4}>
            <TitleDropdown
              field={associationForm.title}
              e2e="association-title"
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column marginBottom={3} s={5} m={4}>
            <TextField
              field={associationForm.firstName}
              e2e="association-firstName"
            />
          </Grid.Column>
          <Grid.Column s={5} m={4}>
            <TextField
              field={associationForm.lastName}
              e2e="association-lastname"
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column marginBottom={3} s={10} m={8}>
            <TextField field={associationForm.email} e2e="association-email" />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column s={3}>
            <CountryCodeDropdown
              field={associationForm.countryCode}
              e2e="association-countryCode"
            />
            <Spacer t={3} block />
          </Grid.Column>
          <Grid.Column s={7} m={5}>
            <TextField
              field={associationForm.phoneNumber}
              e2e="association-phoneNumber"
            />
            <Spacer t={3} block />
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column marginBottom={3} s={10} m={8}>
            <Payment iban={iban} hasHeader={false} hasRequiredInfo={false} />
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column>
            <Text appearance="t2_2">Umsatzsteuerpflicht:</Text>
            <Spacer t={2} block />
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column marginBottom={3} s={10} m={8}>
            <TextField
              field={associationForm.taxNumber}
              e2e="association-taxNumber"
            />
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column marginBottom={3} s={10} m={8}>
            <Text appearance="t4">
              Separat ausgewiesene Umsatzsteuer nach §2 Abs.1 UStG *
            </Text>
            <Spacer t={2} block />
            <RadioGroup e2e="associationForm-taxes">
              <FormikFields.Radio
                name={associationForm.isValueAddedTax.name}
                e2e="is-added-tax-yes"
                label="Ja"
                value={Decision.yes}
              />
              <Spacer t={2} block />
              <FormikFields.Radio
                name={associationForm.isValueAddedTax.name}
                e2e="is-added-tax-no"
                label="Nein"
                value={Decision.no}
              />
            </RadioGroup>
          </Grid.Column>
        </Grid.Row>
        <ErrorFocus />
        <Grid.Row>
          <Grid.Column s={10} m={8}>
            <PrivacyInfo title="Datenschutz" isFormal={false} />
            <Spacer y={3} block>
              <RequiredInfo />
            </Spacer>
          </Grid.Column>
        </Grid.Row>
        {hasError && (
          <Grid.Row>
            <Grid.Column s={10} m={8}>
              <Spacer b={3} block>
                <ErrorMessage e2e="association">
                  Es ist ein Fehler aufgetreten. Ihre Anfrage konnte nicht
                  übermittelt werden. Bitte versuchen Sie es zu einem späteren
                  Zeitpunkt noch mal.
                </ErrorMessage>
              </Spacer>
            </Grid.Column>
          </Grid.Row>
        )}
        <Grid.Row>
          <Grid.Column>
            <Button
              type="submit"
              variant="primary"
              color="red"
              e2e="club-registration-submit"
              disabled={isSubmitting}
            >
              Absenden
            </Button>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Form>
  );
};
