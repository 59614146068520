import { Grid, OptionProp, Spacer, Text } from 'dss-ui-library';
import { Form, useFormikContext } from 'formik';
import React, { ChangeEvent, useEffect } from 'react';
import { FormFieldValues } from '../../AvailabilityCheck/Views/Form';
import {
  HouseNumber,
  HouseNumberProps,
  Street,
  StreetProps,
  ZipCode,
  ZipCodeProps,
} from '../../AvailabilityCheck/Views/Form/FormElements';
import { AvailabilityValues } from './index';
import { useAddressAutoSuggest } from '@ncs-frontend-monorepo/forms';

interface AvailabilityCheckFormProps {
  updateValues(values: AvailabilityValues): void;
}

export const AvailabilityCheckForm: React.FC<AvailabilityCheckFormProps> = ({
  updateValues,
}) => {
  const { values, setFieldValue } = useFormikContext<FormFieldValues>();
  const { getZipCodes, onZipSelection, getAddresses, onStreetSelection } =
    useAddressAutoSuggest();
  const minCharPostalCode = 3;
  const minCharStreet = 1;
  const handleZipSelection = ({
    option,
    targetZipCode,
  }: {
    option: OptionProp;
    targetZipCode: string;
  }) => {
    const { zipCode } = onZipSelection(option);
    // either set autocomplete result or trimmed user input
    setFieldValue(targetZipCode, zipCode ? zipCode : option.value.trim());
  };

  const handleStreetSelection = ({
    option,
    targetStreet,
    targetCity,
  }: {
    option: OptionProp;
    targetStreet: string;
    targetCity: string;
  }) => {
    const { street, city } = onStreetSelection(option);
    if (street && city) {
      setFieldValue(targetStreet, street);
      setFieldValue(targetCity, city);
    }
  };

  const zipCodeProps: ZipCodeProps = {
    background: 'white',
    minCharPostalCode,
    getZipCodes,
    handleZipSelection,
  };

  const streetProps: StreetProps = {
    background: 'white',
    getAddresses,
    handleStreetSelection,
    minCharStreet,
    zipCode: values.zipCode,
  };

  const houseNumberProps: HouseNumberProps = {
    background: 'white',
    onChange: (e: ChangeEvent<HTMLInputElement>) => {
      setFieldValue(e.target.name, e.target.value.trim());
    },
  };

  useEffect(() => {
    updateValues({
      zipCode: values.zipCode,
      street: values.street,
      houseNumber: values.houseNumber,
      city: values.city,
    });
  }, [values]);

  return (
    <Form>
      <Grid fullWidth>
        <Spacer b={2} block>
          <Text appearance="t2_2">An welcher Adresse möchtest du surfen?</Text>
        </Spacer>
        <Grid.Row>
          <Grid.Column s={3}>
            <ZipCode {...zipCodeProps} />
            <Spacer b={2} block />
          </Grid.Column>
          <Grid.Column s={6}>
            <Street {...streetProps} />
            <Spacer b={2} block />
          </Grid.Column>
          <Grid.Column s={3}>
            <HouseNumber {...houseNumberProps} />
            <Spacer b={2} block />
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <input type="submit" hidden />
    </Form>
  );
};
