import React from 'react';
import { Grid, OptionProp, Spacer, Text } from 'dss-ui-library';
import { FormikFields } from '../../../FormikFields';
import { resellerContactModel } from '../model';
import {
  AddressSource,
  FetchAddressType,
  countryCodes,
  salutations,
  titles,
  useAddressAutoSuggest,
} from '@ncs-frontend-monorepo/forms';
import { useFormikContext } from 'formik';

export const GeneralData: React.FC = () => {
  const {
    getZipCodes,
    getAddresses,
    handleStreetSelection,
    handleZipSelection,
  } = useAddressAutoSuggest();
  const { values } = useFormikContext();

  return (
    <Grid>
      <Grid.Row>
        <Grid.Column s={10} m={8}>
          <Text appearance="t2_2" element="h3">
            Allgemeine Angaben:
          </Text>
          <Spacer block t={2} />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column s={10} m={8}>
          <FormikFields.TextField
            name={resellerContactModel.company.name}
            label={resellerContactModel.company.label}
            e2e={resellerContactModel.company.name}
            isRequired={resellerContactModel.company.mandatory}
          />
          <Spacer t={3} block />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column s={5} m={4}>
          <FormikFields.Dropdown
            name={resellerContactModel.salutation.name}
            label={resellerContactModel.salutation.label}
            e2e={resellerContactModel.salutation.name}
            isRequired={resellerContactModel.salutation.mandatory}
            options={salutations}
          />
          <Spacer t={3} block />
        </Grid.Column>
        <Grid.Column s={5} m={4}>
          <FormikFields.Dropdown
            name={resellerContactModel.title.name}
            label={resellerContactModel.title.label}
            e2e={resellerContactModel.title.name}
            isRequired={resellerContactModel.title.mandatory}
            options={titles}
          />
          <Spacer t={3} block />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column s={5} m={4}>
          <FormikFields.TextField
            name={resellerContactModel.firstName.name}
            label={resellerContactModel.firstName.label}
            e2e={resellerContactModel.firstName.name}
            isRequired={resellerContactModel.firstName.mandatory}
          />
          <Spacer t={3} block />
        </Grid.Column>
        <Grid.Column s={5} m={4}>
          <FormikFields.TextField
            name={resellerContactModel.lastName.name}
            label={resellerContactModel.lastName.label}
            e2e={resellerContactModel.lastName.name}
            isRequired={resellerContactModel.lastName.mandatory}
          />
          <Spacer t={3} block />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column s={3}>
          <FormikFields.AutoSuggest
            label={resellerContactModel.zipCode.label}
            name={resellerContactModel.zipCode.name}
            e2e={resellerContactModel.zipCode.name}
            getOptions={(zipCode: string) =>
              getZipCodes({ zipCode, mode: AddressSource.combined })
            }
            minLength={3}
            placeholder={resellerContactModel.zipCode.placeholder}
            onChange={(option: OptionProp) => {
              handleZipSelection({
                option,
                targetField: resellerContactModel.zipCode.name,
                targetCity: resellerContactModel.city.name,
              });
            }}
            isRequired
            pattern="[0-9]*"
          />
          <Spacer t={3} block />
        </Grid.Column>
        <Grid.Column s={7} m={5}>
          <FormikFields.TextField
            name={resellerContactModel.city.name}
            label={resellerContactModel.city.label}
            e2e={resellerContactModel.city.name}
            isRequired={resellerContactModel.city.mandatory}
          />
          <Spacer t={3} block />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column s={7} m={5}>
          <FormikFields.AutoSuggest
            label={resellerContactModel.street.label}
            name={resellerContactModel.street.name}
            e2e={resellerContactModel.street.name}
            getOptions={(streetPartial: string) =>
              getAddresses({
                type: FetchAddressType.streets,
                mode: AddressSource.combined,
                streetPartial,
                zipCode: values[resellerContactModel.zipCode.name],
              })
            }
            minLength={2}
            placeholder={resellerContactModel.street.placeholder}
            onChange={(option: OptionProp) => {
              handleStreetSelection({
                option,
                targetField: resellerContactModel.street.name,
                targetCity: resellerContactModel.city.name,
              });
            }}
            isRequired={resellerContactModel.street.mandatory}
          />
          <Spacer t={3} block />
        </Grid.Column>
        <Grid.Column s={3}>
          <FormikFields.TextField
            name={resellerContactModel.houseNumber.name}
            label={resellerContactModel.houseNumber.label}
            e2e={resellerContactModel.houseNumber.name}
            placeholder={resellerContactModel.houseNumber.placeholder}
            isRequired={resellerContactModel.houseNumber.mandatory}
          />
          <Spacer t={3} block />
        </Grid.Column>
        <Grid.Column s={10} m={8}>
          <FormikFields.TextField
            name={resellerContactModel.email.name}
            label={resellerContactModel.email.label}
            e2e={resellerContactModel.email.name}
            isRequired={resellerContactModel.email.mandatory}
          />
          <Spacer t={3} block />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column s={3}>
          <FormikFields.Dropdown
            name={resellerContactModel.countryCode.name}
            label={resellerContactModel.countryCode.label}
            e2e={resellerContactModel.countryCode.name}
            options={countryCodes}
            isRequired={resellerContactModel.countryCode.mandatory}
          />
          <Spacer t={3} block />
        </Grid.Column>
        <Grid.Column s={7} m={5}>
          <FormikFields.TextField
            name={resellerContactModel.phoneNo.name}
            label={resellerContactModel.phoneNo.label}
            e2e={resellerContactModel.phoneNo.name}
            placeholder={resellerContactModel.phoneNo.placeholder}
            isRequired={resellerContactModel.phoneNo.mandatory}
          />
          <Spacer t={3} block />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};
