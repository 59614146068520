import { Grid, Spacer } from 'dss-ui-library';

interface ButtonRowBottomProps {
  submitButton?: React.ReactNode;
  link?: React.ReactNode;
}

export const ButtonRowBottom: React.FC<ButtonRowBottomProps> = ({
  submitButton,
  link,
}) => {
  return (
    <Grid.Row>
      <Grid.Column s={12} hEndM>
        {submitButton && (
          <>
            <Spacer t={2} block />
            {submitButton}
          </>
        )}
        {link && (
          <>
            <Spacer t={2} block />
            <Grid.Row>
              <Grid.Column hEndM>{link}</Grid.Column>
            </Grid.Row>
          </>
        )}
      </Grid.Column>
    </Grid.Row>
  );
};
