import {
  Button,
  ErrorMessage,
  Grid,
  Spacer,
  Text,
  Tooltip,
} from 'dss-ui-library';
import { Form, useFormikContext } from 'formik';
import {
  SalutationDropdown,
  TitleDropdown,
  TextField,
  TextArea,
  CountryCodeDropdown,
  FormikFields,
  ZipCodeAutoSuggest,
  StreetAutoSuggest,
  RequiredInfo,
  PrivacyInfo,
  useAddressAutoSuggest,
  FetchAddressType,
  AddressSource,
} from '@ncs-frontend-monorepo/forms';
import {
  BuildingOwnerFormBody,
  buildingOwnerForm,
  projectStatus,
} from './data';
import BuildingOwnerFileUpload from './BuildingOwnerFileUpload';
import { text } from './text';
import { ErrorFocus } from '../../ErrorFocus';
import { BUILDINGOWNER_MESSAGE_LENGTH } from './schema';

interface BuildingOwnerFormProps {
  hasError: boolean;
}

export const BuildingOwnerForm: React.FC<BuildingOwnerFormProps> = ({
  hasError,
}) => {
  const { isSubmitting, values, setFieldValue } =
    useFormikContext<BuildingOwnerFormBody>();

  const { getZipCodes, onZipSelection, getAddresses, onStreetSelection } =
    useAddressAutoSuggest();

  return (
    <Form>
      <Grid>
        <Grid.Row>
          <Grid.Column s={10} m={8}>
            <Text appearance="t2" color="blue">
              Nehmen Sie Kontakt zum Bauherren-Service auf.
            </Text>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <Text appearance="t1">
              <Text appearance="t2_2">Ihre Kontaktdaten:</Text>
            </Text>
          </Grid.Column>
        </Grid.Row>
        <Spacer t={2} block />
        <Grid.Row>
          <Grid.Column marginBottom={3} s={5} m={4}>
            <SalutationDropdown
              field={buildingOwnerForm.salutation}
              e2e="buildingowner-salutation"
            />
          </Grid.Column>
          <Grid.Column marginBottom={3} s={5} m={4}>
            <TitleDropdown
              field={buildingOwnerForm.title}
              e2e="buildingowner-title"
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column marginBottom={3} s={5} m={4}>
            <TextField
              field={buildingOwnerForm.firstName}
              e2e={'buildingowner-firstName'}
            />
          </Grid.Column>
          <Grid.Column s={5} m={4}>
            <TextField
              field={buildingOwnerForm.lastName}
              e2e={'buildingowner-lastname'}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column marginBottom={3} s={10} m={8}>
            <TextField
              field={buildingOwnerForm.email}
              e2e={'buildingowner-email'}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column s={3}>
            <CountryCodeDropdown
              field={buildingOwnerForm.countryCode}
              e2e={'buildingowner-countryCode'}
            />
            <Spacer t={3} block />
          </Grid.Column>
          <Grid.Column s={7} m={5}>
            <TextField
              field={buildingOwnerForm.phoneNumber}
              e2e={'buildingowner-phoneNumber'}
            />
            <Spacer t={3} block />
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column>
            <Text appearance="t2_2">Angaben zum Objekt: *</Text>
            <Spacer t={2} block />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column marginBottom={3} s={10}>
            <div className="flex">
              <FormikFields.Radio
                name={buildingOwnerForm.isAddressKnown.name}
                label="Adresse bekannt"
                e2e="buildingowner-isAddressKnown-yes"
                value="yes"
              />
              <Spacer block l={3} />
              <FormikFields.Radio
                name={buildingOwnerForm.isAddressKnown.name}
                label="Adresse nicht bekannt"
                e2e="buildingowner-isAddressKnown-no"
                value="no"
              />
            </div>
          </Grid.Column>
        </Grid.Row>
        {values.isAddressKnown === 'yes' ? (
          <>
            <Grid.Row>
              <Grid.Column s={3}>
                <ZipCodeAutoSuggest
                  e2e="buildingowner-zipCode"
                  field={buildingOwnerForm.zipCode}
                  getOptions={(zipCode: string) =>
                    getZipCodes({ zipCode, mode: AddressSource.local })
                  }
                  onSelection={(options) => {
                    const selection = onZipSelection(options);
                    setFieldValue('city', selection.city);
                    return selection;
                  }}
                />
              </Grid.Column>
              <Grid.Column marginBottom={3} m={5} s={7}>
                <TextField
                  field={buildingOwnerForm.city}
                  e2e="buildingowner-city"
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column m={5} s={7}>
                <StreetAutoSuggest
                  placeholder="Bitte geben Sie Ihre Anschlussadresse an	"
                  e2e="buildingowner-street"
                  field={buildingOwnerForm.street}
                  getOptions={(streetPartial) => {
                    return getAddresses({
                      streetPartial,
                      zipCode: values.zipCode,
                      type: FetchAddressType.streets,
                      mode: AddressSource.local,
                    });
                  }}
                  onSelection={onStreetSelection}
                  targetCity={buildingOwnerForm.city.name}
                />
              </Grid.Column>
              <Grid.Column marginBottom={3} s={3}>
                <TextField
                  field={buildingOwnerForm.houseNumber}
                  e2e="buildingowner-houseNumber"
                />
              </Grid.Column>
            </Grid.Row>
          </>
        ) : (
          <Grid.Row>
            <Grid.Column s={10} m={8}>
              <Text appearance="t5">
                Damit wir wissen, wo genau sich Ihr Objekt befindet, fügen Sie
                bitte einen Lageplan als Anlage hinzu.
              </Text>
              <Spacer t={1} block />
            </Grid.Column>
          </Grid.Row>
        )}
        <Grid.Row>
          <Grid.Column s={10} m={8}>
            <FormikFields.Dropdown
              name={buildingOwnerForm.projectStatus.name}
              label={buildingOwnerForm.projectStatus.label}
              e2e="buildingowner-projectStatus"
              isRequired={buildingOwnerForm.projectStatus.mandatory}
              options={projectStatus}
            />
            <Spacer t={3} block />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column xs={8} s={6}>
            <Text appearance="t2_2" element="h3">
              Ihre Nachricht an uns: *
            </Text>
            <Spacer t={2} block />
          </Grid.Column>
          <Grid.Column hideM xs={4} hEnd>
            <Tooltip>
              <Text appearance="t5" color="white">
                {text.message}
              </Text>
            </Tooltip>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column marginBottom={3} s={10} m={8}>
            <TextArea
              maxChars={BUILDINGOWNER_MESSAGE_LENGTH}
              field={buildingOwnerForm.message}
              e2e="buildingowner-message"
            />
          </Grid.Column>
          <Grid.Column hide showM m={3}>
            <Text appearance="t6" color="grey">
              {text.message}
            </Text>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column xs={8} s={6}>
            <Text appearance="t2_2" element="h3">
              Anhänge hochladen:
            </Text>
            <Spacer t={2} block />
          </Grid.Column>
          <Grid.Column hideM xs={4} hEnd>
            <Tooltip>
              <Text appearance="t5" color="white">
                {text.upload}
              </Text>
            </Tooltip>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column marginBottom={3} s={10} m={8}>
            <BuildingOwnerFileUpload
              name={buildingOwnerForm.fileUploadIds.name}
            />
          </Grid.Column>
          <Grid.Column hide showM m={3}>
            <Text appearance="t6" color="grey">
              {text.upload}
            </Text>
          </Grid.Column>
        </Grid.Row>
        {hasError && (
          <Grid.Row>
            <Grid.Column s={10} m={8}>
              <Spacer y={2} block>
                <ErrorMessage e2e="buildingowner-error">
                  Es ist ein Fehler aufgetreten. Ihre Anfrage konnte nicht
                  übermittelt werden. Bitte versuchen Sie es zu einem späteren
                  Zeitpunkt noch mal.
                </ErrorMessage>
              </Spacer>
            </Grid.Column>
          </Grid.Row>
        )}
        <ErrorFocus />
        <Grid.Row>
          <Grid.Column s={10} m={8}>
            <PrivacyInfo />
            <Spacer b={3} block />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <RequiredInfo />
            <Spacer b={3} block />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <Button
              type="submit"
              variant="primary"
              color="red"
              e2e="buildingowner-submit"
              disabled={isSubmitting}
            >
              Absenden
            </Button>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Form>
  );
};
