import React from 'react';
import { Grid, Spacer, Text } from 'dss-ui-library';
import { FormikFields } from '../../../FormikFields';
import { cancellationFormModel } from '../cancellationFormModel';

export const Detail: React.FC = () => {
  return (
    <Grid fullWidth>
      <Grid.Row>
        <Grid.Column s={10} m={8}>
          <Spacer t={3} block>
            <Text appearance="t2_2" element="h4">
              Meine Nachricht an euch
            </Text>
          </Spacer>
          <Spacer b={3} block>
            <Text appearance="t4_2" element="h4">
              Hier kannst du deinen Kündigungsgrund angeben.
            </Text>
          </Spacer>

          <FormikFields.Textarea
            e2e={cancellationFormModel.message.name}
            name={cancellationFormModel.message.name}
            label={''}
            placeholder={cancellationFormModel.message.label}
            maxChars={300}
          ></FormikFields.Textarea>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};
