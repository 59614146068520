import React, {
  createContext,
  FC,
  ReactNode,
  useContext,
  useReducer,
} from 'react';
import { productReducer } from './productReducer';
import { ProductModifiers } from '@ncs-frontend-monorepo/order';

// Stolen from https://medium.com/@martin_hotell/react-children-composition-patterns-with-typescript-56dfc8923c64
type IsFunction<T> = T extends (...args: any[]) => any ? T : never;
// eslint-disable-next-line
const isFunction = <T extends {}>(value: T): value is IsFunction<T> =>
  typeof value === 'function';

export type ProductState = {
  telephonyEnabled?: boolean;
  nettvEnabled?: boolean;
  toggledDetail: Array<keyof typeof ProductInfoDetails>;
};

export const initialState: ProductState = {
  telephonyEnabled: true,
  nettvEnabled: true,
  toggledDetail: [],
};

export enum ProductActionType {
  TOGGLE_TELEPHONY = 'TOGGLE_TELEPHONY',
  TOGGLE_NETTV = 'TOGGLE_NETTV',
  TOGGLE_INFO = 'TOGGLE_INFO',
  SET_MODIFIER = 'SET_MODIFIER',
}

type ActionToggleTelephony = {
  type: ProductActionType.TOGGLE_TELEPHONY;
};

type ActionToggleNettv = {
  type: ProductActionType.TOGGLE_NETTV;
};

type ActionSetModifier = {
  type: ProductActionType.SET_MODIFIER;
  payload: {
    modifier: ProductModifiers | null;
  };
};

type ActionToggleInfo = {
  type: ProductActionType.TOGGLE_INFO;
  payload: {
    toggleDetailType: ProductInfoDetails | null;
  };
};

export type ProductAction =
  | ActionToggleTelephony
  | ActionToggleNettv
  | ActionToggleInfo
  | ActionSetModifier;

export enum ProductInfoDetails {
  netspeed = 'netspeed',
  tv = 'tv',
  phone = 'phone',
  proposals = 'proposals',
  netServiceInfo = 'netServiceInfo',
  treeInfo = 'treeInfo',
}

export interface ProductContext {
  togglePhone?: () => void;
  toggleNetTV?: () => void;
  modifier?: ProductModifiers;
  setModifier?: (modifier?: ProductModifiers) => void;
  toggleInfo?: (type: ProductInfoDetails) => void;
  toggledDetail?: Array<keyof typeof ProductInfoDetails>;
}

const Context = createContext<ProductContext | null>(null);
Context.displayName = 'ProductContext';

const useProductContext: () => ProductContext = () => {
  const contextState = useContext(Context);
  if (contextState === null) {
    throw new Error(
      'useProductContext must be used within a <ProductProvider> tag',
    );
  }
  return contextState;
};

const initializer = (initialValue: ProductState): ProductState => {
  if (initialValue) {
    return {
      ...initialState,
      ...initialValue,
    };
  }

  return initialState;
};

interface ProductProviderProps {
  value?: ProductState;
  children:
    | (({
        modifier,
        togglePhone,
        toggleNetTV,
        setModifier,
        toggleInfo,
      }: ProductContext) => ReactNode)
    | ReactNode;
}

const ProductProvider: FC<ProductProviderProps> = ({ value, children }) => {
  const [state, dispatch] = useReducer(productReducer, value, initializer);

  const togglePhone = () => {
    dispatch({ type: ProductActionType.TOGGLE_TELEPHONY });
  };

  const toggleNetTV = () => {
    dispatch({ type: ProductActionType.TOGGLE_NETTV });
  };

  const toggleInfo = (toggleDetailType?: ProductInfoDetails) => {
    dispatch({
      type: ProductActionType.TOGGLE_INFO,
      payload: { toggleDetailType },
    });
  };

  const setModifier = (modifier?: ProductModifiers) => {
    dispatch({ type: ProductActionType.SET_MODIFIER, payload: { modifier } });
  };

  let modifier: ProductModifiers;
  switch (true) {
    case !state.nettvEnabled && !state.telephonyEnabled:
      modifier = ProductModifiers.withoutAll;
      break;
    case !state.telephonyEnabled:
      modifier = ProductModifiers.withoutPhone;
      break;
    case !state.nettvEnabled:
      modifier = ProductModifiers.withoutTv;
      break;
    default:
      modifier = null;
      break;
  }

  return (
    <Context.Provider
      value={{
        modifier,
        togglePhone,
        toggleNetTV,
        setModifier,
        toggleInfo,
        ...state,
      }}
    >
      {!isFunction(children)
        ? children
        : children({
            modifier,
            togglePhone,
            toggleNetTV,
            setModifier,
            toggleInfo,
            ...state,
          })}
    </Context.Provider>
  );
};

export { ProductProvider, useProductContext };
