import React from 'react';
import { StaticContentType, StaticContentTypes } from '../../interfaces';
import {
  AvailabilityEmbedded,
  EmbeddedTypes,
} from '../AvailabilityCheck/Embedded';
import { Cancellation } from '../Forms/Cancellation';
import { Contact } from '../Forms/Contact';
import { LeadContact } from '../FTTH/LeadContact';
import { ExpansionMap } from '../ExpansionMap';
import { SmartcardActivation } from '../Forms/SmartcardActivation';
import { TariffAdvisor } from '../TariffAdvisor';
import { ResellerContact } from '../Forms/ResellerContact';
import { BuildingOwner } from '../Forms/BuildingOwner';
import { Association } from '../Forms/Association';

export interface StaticContentProps {
  content: StaticContentType;
}

export const StaticContent: React.FC<StaticContentProps> = ({ content }) => {
  // TODO: make it more dynamic so we can remove as .....
  switch (content.type) {
    case StaticContentTypes.AvailabilityTvStandalone:
      return (
        <AvailabilityEmbedded
          type={EmbeddedTypes.TV_CHECK_STANDALONE}
          params={content.params}
        />
      );
    case StaticContentTypes.AvailabilityNetSpeed:
      return (
        <AvailabilityEmbedded
          type={EmbeddedTypes.NETSPEED_CHECK}
          params={content.params}
        />
      );
    case StaticContentTypes.AvailabilitySmall:
      return (
        <AvailabilityEmbedded
          type={EmbeddedTypes.NETSPEED_CHECK}
          params={{
            ...content.params,
            fullWidth: true,
            viewType: 'standalone',
            portfolio: null,
          }}
          isSmall
        />
      );
    case StaticContentTypes.CancellationForm:
      return <Cancellation />;
    case StaticContentTypes.ContactPK:
      return <Contact />;
    case StaticContentTypes.LeadContactForm:
      return <LeadContact {...content.params} />;
    case StaticContentTypes.ExpansionMap:
      return <ExpansionMap {...content.params} />;
    case StaticContentTypes.SmartcardActivation:
      return <SmartcardActivation />;
    case StaticContentTypes.TariffAdvisor:
      return (
        <TariffAdvisor {...content.params} footnotes={content?.footnotes} />
      );
    case StaticContentTypes.ResellerContact:
      return <ResellerContact />;
    case StaticContentTypes.BuildingOwnerForm:
      return <BuildingOwner />;
    case StaticContentTypes.AssociationForm:
      return <Association />;
    default:
      return <></>;
  }
};
