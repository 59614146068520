import * as Yup from 'yup';
import {
  contactFields,
  contactSchema,
  requiredText,
  yes,
} from '@ncs-frontend-monorepo/forms';
import { buildingOwnerForm } from './data';

export const BUILDINGOWNER_MESSAGE_LENGTH = 1400;

export const schema = Yup.object().shape({
  message: Yup.string()
    .required(requiredText)
    .max(BUILDINGOWNER_MESSAGE_LENGTH, ''),
  isAddressKnown: Yup.string().required(requiredText),
  projectStatus: Yup.string().required(requiredText),

  [contactFields.salutation.name]: contactSchema[contactFields.salutation.name],
  [contactFields.firstName.name]: contactSchema[contactFields.firstName.name],
  [contactFields.lastName.name]: contactSchema[contactFields.lastName.name],
  [contactFields.email.name]: contactSchema[contactFields.email.name],
  [contactFields.countryCode.name]:
    contactSchema[contactFields.countryCode.name],
  [contactFields.phoneNumber.name]:
    contactSchema[contactFields.phoneNumber.name],

  [contactFields.zipCode.name]: yes([buildingOwnerForm.isAddressKnown.name], {
    then: () => contactSchema[contactFields.zipCode.name],
  }),

  [contactFields.city.name]: yes([buildingOwnerForm.isAddressKnown.name], {
    then: () => contactSchema[contactFields.city.name],
  }),

  [contactFields.street.name]: yes([buildingOwnerForm.isAddressKnown.name], {
    then: () => contactSchema[contactFields.street.name],
  }),

  [contactFields.houseNumber.name]: yes(
    [buildingOwnerForm.isAddressKnown.name],
    {
      then: () => contactSchema[contactFields.houseNumber.name],
    },
  ),
});
