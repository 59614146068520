import { resellerContactModel } from './model';

export const initialData = {
  [resellerContactModel.company.name]: '',
  [resellerContactModel.salutation.name]: '',
  [resellerContactModel.title.name]: '',
  [resellerContactModel.firstName.name]: '',
  [resellerContactModel.lastName.name]: '',
  [resellerContactModel.zipCode.name]: '',
  [resellerContactModel.city.name]: '',
  [resellerContactModel.street.name]: '',
  [resellerContactModel.houseNumber.name]: '',
  [resellerContactModel.email.name]: '',
  [resellerContactModel.countryCode.name]: '49',
  [resellerContactModel.phoneNo.name]: '',
  [resellerContactModel.salesroom.name]: '',
  [resellerContactModel.website.name]: '',
};
