import React from 'react';
import styles from './Icon.module.scss';
import cn from 'classnames';

interface IconProps {
  isCentered?: boolean;
}

export const WlanIcon: React.FC<IconProps> = ({ isCentered }) => {
  return (
    <div
      className={cn(styles.wlanIcon, {
        [styles.center]: isCentered,
      })}
    >
      <svg viewBox="0 0 144 108" fill="currentColor">
        <path d="M71.797,0C58.461,-0.007 45.254,2.617 32.934,7.722C20.614,12.827 9.422,20.314 0,29.752L8.842,38.594C17.143,30.291 27.006,23.712 37.86,19.237C48.715,14.761 60.348,12.478 72.089,12.518C83.83,12.557 95.448,14.92 106.272,19.469C117.096,24.018 126.913,30.663 135.158,39.023L144,30.18C134.565,20.616 123.324,13.023 110.928,7.842C98.533,2.661 85.232,-0.005 71.797,0Z" />
        <path d="M20.143,49.895L28.985,58.646C34.655,53.004 41.385,48.539 48.788,45.51C56.191,42.48 64.12,40.945 72.119,40.993C80.118,41.041 88.028,42.672 95.394,45.791C102.76,48.909 109.436,53.455 115.037,59.165L123.88,50.323C117.111,43.453 109.051,37.99 100.162,34.248C91.274,30.506 81.733,28.559 72.089,28.519C62.445,28.479 52.889,30.347 43.969,34.016C35.05,37.684 26.945,43.081 20.12,49.895L20.143,49.895Z" />
        <path d="M40.105,69.925L48.947,78.767C51.985,75.739 55.595,73.346 59.568,71.727C63.54,70.108 67.794,69.296 72.083,69.338C76.372,69.38 80.61,70.275 84.55,71.971C88.49,73.668 92.052,76.131 95.03,79.218L103.895,70.354C99.753,66.105 94.808,62.721 89.349,60.398C83.889,58.074 78.023,56.857 72.09,56.817C66.156,56.777 60.274,57.915 54.784,60.165C49.293,62.415 44.304,65.733 40.105,69.925Z" />
        <path d="M71.797,107.076C78.088,107.076 83.188,101.976 83.188,95.685C83.188,89.393 78.088,84.294 71.797,84.294C65.506,84.294 60.406,89.393 60.406,95.685C60.406,101.976 65.506,107.076 71.797,107.076Z" />
      </svg>
    </div>
  );
};
