import React from 'react';
import {
  Grid,
  IconEducation,
  IconPhone,
  IconTelevision,
  Spacer,
  Text,
} from 'dss-ui-library';
import { Selection } from '../Elements/Selection';
import { OnUpdate, TariffAdvisorStepId } from '../index';
import styles from '../TariffAdvisor.module.scss';

export enum OthersSelection {
  Student = 'student',
  Phone = 'phone',
  Tv = 'tv',
}

const othersSelection = [
  {
    text: 'Ich studiere oder mache eine Ausbildung.',
    value: OthersSelection.Student,
    e2e: OthersSelection.Student,
    icon: <IconEducation />,
  },
  {
    text: 'Ich telefoniere viel ins deutsche Festnetz.',
    value: OthersSelection.Phone,
    e2e: OthersSelection.Phone,
    icon: <IconPhone />,
  },
  {
    text: 'Ich schaue Live-TV und interessiere mich für TV-Produkte.',
    value: OthersSelection.Tv,
    e2e: OthersSelection.Tv,
    icon: <IconTelevision />,
  },
];

export interface OthersValue {
  [TariffAdvisorStepId.Others]: OthersSelection[];
}

export type OthersProps = OthersValue & OnUpdate;

export const Others: React.FC<OthersProps> = ({ others, handleChange }) => {
  const handleSelection = (other: OthersSelection) => {
    handleChange({
      others: others?.includes(other)
        ? others.filter((item) => item !== other)
        : [...others, ...[other]],
    });
  };

  return (
    <div className={styles.stepContainer} data-e2e="tariff-advisor-step-other">
      <Text appearance="t2_2">
        Treffen eine oder mehrere Aussagen auf dich zu?{' '}
      </Text>
      <Text appearance="t3">(Mehrfachauswahl möglich)</Text>
      <Spacer b={2} block />
      <Grid fullWidth>
        <Grid.Row>
          {othersSelection.map((other, ix) => (
            <Grid.Column s={4} key={`${ix}-${other.value}`}>
              <Selection
                isSelected={others?.includes(other.value)}
                onChange={() => {
                  handleSelection(other.value);
                }}
                multiSelect={true}
                name={other.e2e}
                {...other}
              />
            </Grid.Column>
          ))}
        </Grid.Row>
      </Grid>
    </div>
  );
};
