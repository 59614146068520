import React, { ReactElement } from 'react';
import styles from './Selection.module.scss';
import { Checkbox, Radio, Text } from 'dss-ui-library';
import cx from 'classnames';
import { UsageSelection } from '../Usages';
import { DeviceSelection } from '../Devices';
import { OthersSelection } from '../Others';

export interface SelectionProps {
  multiSelect: boolean;
  isSelected: boolean;
  icon?: ReactElement;
  text: string;
  value: number | UsageSelection | DeviceSelection | OthersSelection;
  name?: string;
  points?: number;
  e2e: string;
  onChange(): void;
}

export const Selection: React.FC<SelectionProps> = React.memo(
  ({ multiSelect, isSelected, icon, text, value, name, e2e, onChange }) => (
    <div
      className={cx(styles.selection, {
        [styles.isSelected]: isSelected,
        [styles.withIcon]: icon,
      })}
      onClick={onChange}
    >
      {icon && <div className={styles.selectionIcon}>{icon}</div>}

      {multiSelect ? (
        <Checkbox
          label={
            <Text appearance="t4_2" className={styles.label}>
              {text}
            </Text>
          }
          name={name}
          value={`${value}`}
          e2e={e2e}
          checked={isSelected}
          background={isSelected ? 'blue' : 'white'}
        />
      ) : (
        <Radio
          label={<Text appearance="t4_2">{text}</Text>}
          value={`${value}`}
          name={name}
          e2e={e2e}
          checked={isSelected}
          background={isSelected ? 'blue' : 'white'}
        />
      )}
    </div>
  ),
);
