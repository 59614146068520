import React, { useEffect, useState } from 'react';
import { LoadingSpinner } from 'dss-ui-library';
import useSWRImmutable from 'swr/immutable';
import PlanRow, { PlanRowProps, PlanRowType } from '../../PlanRows/PlanRow';
import { pageIds } from '../../../constants/pageIds';
import styles from '../TariffAdvisor.module.scss';
import { ViewType } from '../../AvailabilityCheck';
import { AvailabilityTypes } from '../../AvailabilityCheck/Context';
import { OpenModalProps } from '../../../hooks/useAvailabilityModal';
import {
  ProductId,
  Product,
  getProducts,
  Footnote,
} from '@ncs-frontend-monorepo/order';
import { Technology } from '@ncs-frontend-monorepo/availability';

interface ResultBoxProps {
  product: {
    id: ProductId;
    technology: Technology;
  };
  handleAvailabilityCheck: (props: OpenModalProps) => void;
  handleOfferToggle: () => void;
  isLongTerm: boolean;
  footnotes?: Footnote[];
  flagLabel?: string;
  isAlternative?: boolean;
}

export const ResultPlan: React.FC<ResultBoxProps> = React.memo(
  ({
    product,
    handleAvailabilityCheck,
    handleOfferToggle,
    isLongTerm,
    footnotes,
    flagLabel,
    isAlternative = false,
  }) => {
    const { data: plan } = useSWRImmutable<Product>(
      { id: product.id, technoloy: product.technology },
      () =>
        getProducts({ templateId: product.id, technology: product.technology }),
    );
    const [planProps, setPlanProps] = useState<PlanRowProps>();

    useEffect(() => {
      if (plan) {
        const templatePageLink = pageIds?.[plan.id] || '';
        setPlanProps({
          plan: { ...plan, templatePageLink },
          onAvailabilityCheckClick: () =>
            handleAvailabilityCheck({
              plan,
              desiredEntrypoint: ViewType.CHECKOUT,
              isLongTerm,
              availabilityType: AvailabilityTypes.TariffAdvisor,
            }),
          className: isAlternative
            ? styles.planContainerAlternative
            : styles.planContainer,
          type: PlanRowType.Advisor,
          flagLabel,
          handleOfferToggle: () => handleOfferToggle(),
          disableShortTerm: !plan.isWithoutRuntimeAvailable || false,
          isLongTerm: plan.isWithoutRuntimeAvailable ? isLongTerm : true,
          footnotes,
        });
      }
    }, [
      plan,
      product.technology,
      isLongTerm,
      isAlternative,
      flagLabel,
      footnotes,
      isLongTerm,
    ]);

    if (planProps) {
      return <PlanRow {...planProps} />;
    }

    return <LoadingSpinner theme="blue" />;
  },
);
