export const HotlineNumber = {
  NetCologne: '0221 2222-800',
  NetAachen: '0800 2222-333',
} as const;

export const HotlineNumberSmartcard = {
  NetCologne: {
    formatted: '0221 – 2222 800',
    href: 'tel:02212222800',
  },
  NetAachen: {
    formatted: '0800 - 2222 333',
    href: 'tel:08002222333',
  },
} as const;
