import React from 'react';
import { Grid, Spacer, Text } from 'dss-ui-library';
import { FormikFields } from '../../../FormikFields';
import { resellerContactModel } from '../model';
import { yesNoOptions } from '@ncs-frontend-monorepo/forms';
export const StoreData: React.FC = () => (
  <Grid>
    <Grid.Row>
      <Grid.Column s={10} m={8}>
        <Text appearance="t2_2" element="h3">
          Angaben zum Fachhandel:{' '}
        </Text>
        <Spacer block t={2} />
      </Grid.Column>
    </Grid.Row>
    <Grid.Row>
      <Grid.Column s={10} m={8}>
        <FormikFields.Dropdown
          name={resellerContactModel.salesroom.name}
          label={resellerContactModel.salesroom.label}
          e2e={resellerContactModel.salesroom.name}
          isRequired={resellerContactModel.salesroom.mandatory}
          options={yesNoOptions}
        />
        <Spacer t={3} block />
      </Grid.Column>
    </Grid.Row>
    <Grid.Row>
      <Grid.Column s={10} m={8}>
        <FormikFields.TextField
          name={resellerContactModel.website.name}
          label={resellerContactModel.website.label}
          e2e={resellerContactModel.website.name}
          isRequired={resellerContactModel.website.mandatory}
          placeholder={resellerContactModel.website.placeholder}
        />
        <Spacer t={3} block />
      </Grid.Column>
    </Grid.Row>
  </Grid>
);
