import React from 'react';
import { Grid, OptionProp, Spacer, Text } from 'dss-ui-library';
import { cancellationFormModel } from '../cancellationFormModel';
import { FormikFields } from '../../../FormikFields';
import {
  AddressSource,
  FetchAddressType,
  countryCodes,
  salutations,
  titles,
  useAddressAutoSuggest,
} from '@ncs-frontend-monorepo/forms';
import { useFormikContext } from 'formik';

export const Personal: React.FC = () => {
  const { values } = useFormikContext();
  const {
    getZipCodes,
    getAddresses,
    handleStreetSelection,
    handleZipSelection,
  } = useAddressAutoSuggest();

  return (
    <>
      <Grid.Row>
        <Grid.Column s={10} m={8}>
          <Spacer y={3} block>
            <Text appearance="t2_2" element="h4">
              Persönliche Daten
            </Text>
          </Spacer>
          <FormikFields.TextField
            name={cancellationFormModel.customerNumber.name}
            label={cancellationFormModel.customerNumber.label}
            e2e={cancellationFormModel.customerNumber.name}
            isRequired
          />
          <Spacer t={3} block />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column s={5} m={4}>
          <FormikFields.Dropdown
            name={cancellationFormModel.salutation.name}
            label={cancellationFormModel.salutation.label}
            e2e={cancellationFormModel.salutation.name}
            options={salutations}
            isRequired
          />
          <Spacer t={3} block />
        </Grid.Column>
        <Grid.Column s={5} m={4}>
          <FormikFields.Dropdown
            name={cancellationFormModel.title.name}
            label={cancellationFormModel.title.label}
            e2e={cancellationFormModel.title.name}
            options={titles}
          />
          <Spacer t={3} block />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column s={5} m={4}>
          <FormikFields.TextField
            name={cancellationFormModel.firstName.name}
            label={cancellationFormModel.firstName.label}
            e2e={cancellationFormModel.firstName.name}
            isRequired
          />
          <Spacer t={3} block />
        </Grid.Column>
        <Grid.Column s={5} m={4}>
          <FormikFields.TextField
            name={cancellationFormModel.lastName.name}
            label={cancellationFormModel.lastName.label}
            e2e={cancellationFormModel.lastName.name}
            isRequired
          />
          <Spacer t={3} block />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column s={3}>
          <FormikFields.AutoSuggest
            label={cancellationFormModel.zipCode.label}
            name={cancellationFormModel.zipCode.name}
            e2e={cancellationFormModel.zipCode.name}
            getOptions={(zipCode: string) =>
              getZipCodes({ zipCode, mode: AddressSource.combined })
            }
            minLength={3}
            placeholder={cancellationFormModel.zipCode.placeholder}
            onChange={(option: OptionProp) => {
              handleZipSelection({
                option,
                targetField: cancellationFormModel.zipCode.name,
                targetCity: cancellationFormModel.city.name,
              });
            }}
            isRequired
            pattern="[0-9]*"
          />
          <Spacer t={3} block />
        </Grid.Column>
        <Grid.Column s={7} m={5}>
          <FormikFields.TextField
            name={cancellationFormModel.city.name}
            label={cancellationFormModel.city.label}
            e2e={cancellationFormModel.city.name}
            isRequired
          />
          <Spacer t={3} block />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column s={7} m={5}>
          <FormikFields.AutoSuggest
            label={cancellationFormModel.street.label}
            name={cancellationFormModel.street.name}
            e2e={cancellationFormModel.street.name}
            getOptions={(streetPartial: string) =>
              getAddresses({
                type: FetchAddressType.streets,
                mode: AddressSource.combined,
                streetPartial,
                zipCode: values[cancellationFormModel.zipCode.name],
              })
            }
            minLength={2}
            placeholder={cancellationFormModel.street.placeholder}
            onChange={(option: OptionProp) => {
              handleStreetSelection({
                option,
                targetField: cancellationFormModel.street.name,
                targetCity: cancellationFormModel.city.name,
              });
            }}
            isRequired
          />
          <Spacer t={3} block />
        </Grid.Column>
        <Grid.Column s={3}>
          <FormikFields.TextField
            name={cancellationFormModel.houseNumber.name}
            label={cancellationFormModel.houseNumber.label}
            e2e={cancellationFormModel.houseNumber.name}
            placeholder={cancellationFormModel.houseNumber.placeholder}
            isRequired
          />
          <Spacer t={3} block />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column s={10} m={8}>
          <FormikFields.TextField
            name={cancellationFormModel.email.name}
            label={cancellationFormModel.email.label}
            e2e={cancellationFormModel.email.name}
            isRequired
          />
          <Spacer t={3} block />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column s={3}>
          <FormikFields.Dropdown
            name={cancellationFormModel.areaCode.name}
            label={cancellationFormModel.areaCode.label}
            e2e={cancellationFormModel.areaCode.name}
            options={countryCodes}
          />
          <Spacer t={3} block />
        </Grid.Column>
        <Grid.Column s={7} m={5}>
          <FormikFields.TextField
            name={cancellationFormModel.phoneNo.name}
            label={cancellationFormModel.phoneNo.label}
            e2e={cancellationFormModel.phoneNo.name}
            placeholder={cancellationFormModel.phoneNo.placeholder}
          />
        </Grid.Column>
      </Grid.Row>
    </>
  );
};
