import cx from 'classnames';
import {
  Button,
  Flag,
  Footnote,
  Grid,
  IconDownload,
  IconUpload,
  LoadingSpinner,
  Spacer,
  Text,
  ToggleRuntime,
  useBreakpoints,
} from 'dss-ui-library';
import { DynamicContentProps } from '@ncs-frontend-monorepo/content-library';
import dynamic from 'next/dynamic';
import React from 'react';
import useSWR from 'swr/immutable';
import { BESTSELLER_TEXT, TOPPRICETEXT } from '../../constants';
import { SitemapEntry } from '../../interfaces';
import {
  contentApiURL,
  createShortDescriptionByPlan,
  prepareFootnotes,
} from '../../utils';
import {
  buildPlanDetailPageUrl,
  getModifier,
  getPlanParts,
  getPortfolioByPromotionBandwidth,
} from '../../utils/plan';
import { useModalContext } from '../ModalContext';
import { NotAvailableInfo } from '../PlanElements/NotAvailableInfo';
import { TemplatePageLink } from '../PlanElements/TemplatePageLink';
import styles from './PlanRows.module.scss';
import { ProductName } from '../PlanElements/ProductName';
import { ProductDescriptionText } from '../PlanElements/ProductDescription';
import { fetcher } from '@ncs-frontend-monorepo/utils';
import {
  AvailabilityStatus,
  ExpansionStatus,
  Portfolio,
  useAvailability,
} from '@ncs-frontend-monorepo/availability';
import {
  Pricing,
  Product,
  usePlanState,
  useReset,
  Footnote as IFootnote,
  usePlans,
} from '@ncs-frontend-monorepo/order';

const PlanInfoModal = dynamic(
  () =>
    import('../Modals/PlanInfoModal').then(
      ({ PlanInfoModal }) => PlanInfoModal,
    ),
  {
    ssr: false,
    loading: () => <LoadingSpinner theme="blue" />,
  },
);

export interface PlanRowProps {
  plan: Product & {
    templatePageLink?: SitemapEntry['path'];
    isBestseller?: boolean;
    isHighlighted?: boolean;
    isTopprice?: boolean;
    isFTTHPrePresale?: boolean;
  };
  onAvailabilityCheckClick?: (plan: Product, tryToCheckout: boolean) => void;
  isLongTerm?: boolean;
  footnotes?: IFootnote[];
  handleOfferToggle?: () => void;
  disableShortTerm?: boolean;
  className?: string;
  flagLabel?: string;
  type?: PlanRowType;
  portfolio?: Portfolio;
}

export enum PlanRowType {
  Default = 'default',
  Advisor = 'advisor',
}

const PlanRow: React.FC<PlanRowProps> = ({
  plan,
  onAvailabilityCheckClick,
  isLongTerm = true,
  footnotes = [],
  handleOfferToggle,
  disableShortTerm,
  className,
  flagLabel,
  type = PlanRowType.Default,
  portfolio,
}) => {
  const { resetAll } = useReset();
  const { availability } = useAvailability();
  const planPortfolio = portfolio
    ? portfolio
    : getPortfolioByPromotionBandwidth({
        promotions: availability.promotions.order,
        bandwidth: plan.internet.download,
      });
  const { usedPlan } = usePlans({ plan, portfolio: planPortfolio });
  const { isFTTHPrePresale } = plan;
  const {
    isDisabled,
    isPresale,
    isPlanStateChecked,
    portfolioMismatch,
    availablePortfolio,
  } = usePlanState({
    plan,
    isFTTHPresale: isFTTHPrePresale,
    portfolio: planPortfolio,
  });
  const { handleModal } = useModalContext();
  const shortDescription = createShortDescriptionByPlan(usedPlan);
  const urlDescription = `${contentApiURL()}pksite-infomodal-${
    plan.id
  }-description`;
  const urlTiles = `${contentApiURL()}pksite-infomodal-${plan.id}-tiles`;
  const { data: description } = useSWR<DynamicContentProps>(
    urlDescription,
    fetcher,
  );
  const { data: tiles } = useSWR<DynamicContentProps>(urlTiles, fetcher);

  const highlightFlags = [
    isPresale && (
      <>
        <Text appearance="t5">Ab</Text>{' '}
        {availability.planned?.plannedAvailabilityDateDescription}
      </>
    ),
    plan.isBestseller && BESTSELLER_TEXT,
    plan.isTopprice && TOPPRICETEXT,
  ].filter((e) => e);

  const { isXS } = useBreakpoints();

  const handleTooltipClick = () => {
    handleModal({
      content: (
        <PlanInfoModal
          plan={plan}
          description={description?.content}
          tiles={tiles?.content}
        />
      ),
      e2e: `${plan.id}-details`,
    });
  };

  const buttonE2E = isPlanStateChecked
    ? isPresale
      ? `preorder-now-${availability.planned?.plannedAvailabilityDateDescription?.replaceAll(
          ' ',
          '',
        )}`
      : 'order-now'
    : 'check-availability';

  const getFootnote = prepareFootnotes(footnotes);
  const footnoteUpload = getFootnote('upload');
  const footnotePrice = getFootnote('price');
  const { isYoung, withCableTV, withPhone, withTv } = getPlanParts(plan.id);
  const detailPlanUrl = buildPlanDetailPageUrl(
    usedPlan?.internet?.download,
    usedPlan.id,
    planPortfolio,
    isYoung ? getModifier({ withCableTV, withPhone, withTv }) : null,
  );

  return (
    <Grid.Row
      className={cx(
        styles.planRow,
        plan.isHighlighted && styles.isHighlighted,
        className,
      )}
      e2e={plan.id}
    >
      {/* Plan name, bandwidth and flag */}
      <Grid.Column
        xs={12}
        s={4}
        m={3}
        className={cx(styles.planName, isDisabled && styles.disabled)}
      >
        {(highlightFlags.length > 0 || flagLabel) && (
          <Flag
            color="red"
            className={cx(
              styles.highlightedFlag,
              isDisabled && styles.isDisabledFlag,
            )}
          >
            {highlightFlags[0] || flagLabel}
          </Flag>
        )}
        <div>
          <Spacer t={highlightFlags.length > 0 ? 1 : 0} b={2}>
            <ProductName plan={plan} />
            {type === PlanRowType.Advisor && (
              <ProductDescriptionText items={shortDescription} />
            )}
          </Spacer>
        </div>

        {type === PlanRowType.Default && (
          <div className={styles.bandwidth}>
            <div className={styles.bandwidthItem}>
              <IconDownload
                className={cx(styles.icon, styles.downloadIcon)}
                color="blue"
                width={24}
                height={24}
              />
              <Spacer r={1} />
              <Text appearance="t5">
                Download bis zu{' '}
                <span data-e2e="download">{plan.internet?.download}</span>{' '}
                Mbit/s
              </Text>
            </div>
            <div className={styles.bandwidthItem}>
              <IconUpload
                className={styles.icon}
                color="blue"
                width={24}
                height={24}
              />
              <Spacer r={1} />
              <Text appearance="t5">
                Upload bis zu{' '}
                <span data-e2e="upload">{plan.internet?.upload}</span> Mbit/s{' '}
                {footnoteUpload && <Footnote number={footnoteUpload.number} />}
              </Text>
            </div>
          </div>
        )}
      </Grid.Column>

      <Grid.Column
        xs={12}
        s={5}
        m={4}
        className={cx(styles.priceWrapper, isDisabled && styles.disabled)}
      >
        <div className={isXS ? styles.mobileSpacing : undefined}>
          <Pricing
            plan={plan}
            e2e={plan.id}
            onTooltipClick={handleTooltipClick}
            priceTextInfo="mtl."
            footnote={
              footnotePrice && <Footnote number={footnotePrice.number} />
            }
            isLongTerm={isLongTerm}
            wordBreakOnMobile
            layout="horizontal"
          />
        </div>

        {!disableShortTerm && (
          <ToggleRuntime
            name="runtime-toggle"
            label="Monate Laufzeit"
            e2e={plan.id}
            isLongTerm={isLongTerm}
            onChange={handleOfferToggle}
            disabled={disableShortTerm}
          />
        )}
      </Grid.Column>

      <Grid.Column xs={12} s={3} m={5} hEndS>
        {isDisabled && availability.ftthPresalesInformation ? (
          <NotAvailableInfo
            availablePortfolio={availablePortfolio}
            templateId={plan.id}
            portfolioMismatch={portfolioMismatch}
            availabilityStatus={
              isFTTHPrePresale &&
              availability.ftthPresalesInformation
                ?.alternativeProductAvailability
                ? availability.ftthPresalesInformation
                    .alternativeProductAvailability
                : AvailabilityStatus.NOT_AVAILABLE
            }
            preSalesStatus={
              !isFTTHPrePresale
                ? availability.ftthPresalesInformation?.status
                : ExpansionStatus.NOT_PLANNED
            }
            landingPage={availability.ftthPresalesInformation?.landingPage}
            planBandwidth={plan.internet.download}
            onChangeClick={() => {
              resetAll();
              onAvailabilityCheckClick?.(plan, false);
            }}
          />
        ) : (
          <Button
            color={isPlanStateChecked ? 'red' : 'blue'}
            variant={isPlanStateChecked ? 'primary' : 'secondary'}
            e2e={buttonE2E}
            onClick={() => onAvailabilityCheckClick?.(plan, isPlanStateChecked)}
            fullWidth
          >
            {isPlanStateChecked
              ? isPresale
                ? 'Jetzt vorbestellen'
                : 'Jetzt bestellen'
              : 'Adresse prüfen'}
          </Button>
        )}
        <Spacer t={1} block className={cx(isDisabled && styles.disabled)}>
          <TemplatePageLink url={detailPlanUrl} />
        </Spacer>
      </Grid.Column>
    </Grid.Row>
  );
};

export default PlanRow;
