import React from 'react';
import {
  Grid,
  IconAvatar,
  IconGroup,
  IconGroupPlus,
  Spacer,
  Text,
} from 'dss-ui-library';
import { Selection } from '../Elements/Selection';
import { OnUpdate, TariffAdvisorStepId } from '../index';
import styles from '../TariffAdvisor.module.scss';

export const personsSelection = [
  {
    text: '1 - 2 Personen',
    icon: <IconAvatar />,
    value: 1,
    e2e: 'person-2',
    points: 1,
  },
  {
    text: '2 - 4 Personen',
    icon: <IconGroup />,
    value: 3,
    e2e: 'person-4',
    points: 3,
  },
  {
    text: 'Mehr als 4 Personen',
    icon: <IconGroupPlus />,
    value: 5,
    e2e: 'person-5',
    points: 5,
  },
];

export interface PersonsValue {
  [TariffAdvisorStepId.Persons]: number;
}

export type PersonsProps = PersonsValue & OnUpdate;

export const Persons: React.FC<PersonsProps> = ({ persons, handleChange }) => (
  <div className={styles.stepContainer} data-e2e="tariff-advisor-step-persons">
    <Text appearance="t2_2">
      Wie viele Personen surfen bei dir zuhause im Internet?
    </Text>
    <Spacer b={2} block />
    <Grid fullWidth>
      <Grid.Row>
        {personsSelection.map((person, ix) => (
          <Grid.Column s={4} key={`${ix}-${person.value}`}>
            <Selection
              isSelected={person.value === persons}
              onChange={() => {
                handleChange({ persons: person.value });
              }}
              multiSelect={false}
              name="persons"
              {...person}
            />
          </Grid.Column>
        ))}
      </Grid.Row>
    </Grid>
  </div>
);
