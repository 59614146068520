import { MouseEvent } from 'react';
import {
  ChevronLink,
  Grid,
  IconPhone,
  RichText,
  Spacer,
  Text,
} from 'dss-ui-library';
import { AddressRow } from '../../Elements/AddressRow';
import { ResultContentProps } from '.';
import { Link } from '@ncs-frontend-monorepo/content-library';
import { CONTACT_URL, EXPANSION_URL_NC } from '../../../../constants/urls';
import {
  AvailabilityStatus,
  DataLayerEvents,
} from '../../../../interfaces/tracking';
import { pushAvailability } from '../../../../utils/dataLayer/availability';
import { AvailabilityTypes, useAvailabilityContext } from '../../Context';
import { useAvailability } from '@ncs-frontend-monorepo/availability';

export const NotAvailableResult: React.FC<ResultContentProps> = ({
  content,
  address,
  onChangeAddressClick,
}) => {
  const { templateId } = useAvailabilityContext();
  const { availability } = useAvailability();
  const { eventLinks } = pushAvailability();
  const handleExpansionLink = (e: MouseEvent<HTMLElement>) => {
    e.preventDefault();
    eventLinks({
      event: DataLayerEvents.ExpansionLink,
      status: AvailabilityStatus.NotAvailable,
      zipCode: address.zipCode,
      checkSource: AvailabilityTypes.SmallGlobal,
      maxDownload: 0,
      targetPage: EXPANSION_URL_NC,
      ...(availability?.objectInformation && {
        expansionStatus: availability.objectInformation.status,
      }),
    });
    location.href = EXPANSION_URL_NC;
  };

  const handleContactLink = (e: MouseEvent<HTMLElement>) => {
    e.preventDefault();
    eventLinks({
      event: DataLayerEvents.ContactLink,
      status: AvailabilityStatus.NotAvailable,
      zipCode: address.zipCode,
      checkSource: AvailabilityTypes.SmallGlobal,
      maxDownload: 0,
      targetPage: CONTACT_URL,
      ...(availability?.objectInformation && {
        expansionStatus: availability.objectInformation.status,
      }),
    });
    location.href = CONTACT_URL;
  };

  return (
    <Grid>
      <Spacer b={2} />
      <Grid.Row>
        <Grid.Column s={12} m={5}>
          <AddressRow
            address={address}
            noSpace
            onChangeAddressClick={onChangeAddressClick}
          />
          <Spacer t={1} />
          <RichText text={content.text} />
          <Spacer b={2} block />
        </Grid.Column>
        <Grid.Column s={6} m={3}>
          <Text appearance="t5">
            Stimmt deine Adresse? Weitere Hilfe findest du hier:
          </Text>
          <Spacer t={1} block />
          <Link
            appearance="t5_2"
            url={CONTACT_URL}
            e2e={`${templateId}-contact`}
            icon={<IconPhone color="blue" width={24} height={24} />}
            onClick={handleContactLink}
          >
            Kontakt aufnehmen
          </Link>
          <Spacer b={2} block />
        </Grid.Column>
        <Grid.Column s={6} m={4}>
          <Text appearance="t5">
            Mehr zur Glasfaser-Verfügbarkeit für die Region findest du hier:
          </Text>
          <Spacer t={1} block />
          <ChevronLink
            appearance="t5_2"
            url={EXPANSION_URL_NC}
            e2e={`${templateId}-glass-fiber`}
            indent
            onClick={handleExpansionLink}
          >
            Anschluss- und Ausbaugebiete
          </ChevronLink>
          <Spacer b={2} block />
        </Grid.Column>
      </Grid.Row>
      <Spacer b={2} />
    </Grid>
  );
};
