import { Grid, RichText } from 'dss-ui-library';
import styles from './IconTextBox.module.scss';
import { WlanIcon } from '../../Icons';
import dynamic from 'next/dynamic';

const RocketAnimation = dynamic(
  () =>
    import('../../../../../Animation/Rocket').then(
      ({ RocketAnimation }) => RocketAnimation,
    ),
  { ssr: false },
);

interface IconTextBoxProps {
  text: string;
  icon?: 'rocket' | 'wlan' | 'warning';
  textCols?: number;
}

export const IconTextBox: React.FC<IconTextBoxProps> = ({
  text,
  icon,
  textCols = 12,
}) => {
  return (
    <Grid className={styles.boxWrapper}>
      <Grid.Row>
        {icon && (
          <Grid.Column s={1} className={styles.icon} hCenter center>
            {icon === 'rocket' && <RocketAnimation hasLaunch />}
            {icon === 'wlan' && <WlanIcon isCentered />}
          </Grid.Column>
        )}
        <Grid.Column s={icon ? textCols - 1 : textCols} className={styles.box}>
          <RichText text={text} />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};
