import React from 'react';
import { Grid, Spacer, Text } from 'dss-ui-library';
import { Selection } from '../Elements/Selection';
import { OnUpdate } from '../index';
import styles from '../TariffAdvisor.module.scss';

export enum UsageSelection {
  Browse = 'browse',
  Stream = 'stream',
  Social = 'social',
  SmartHome = 'smarthome',
  Gaming = 'gaming',
  HomeOffice = 'home-office',
}

export const usageSelection = [
  {
    text: 'Surfen, Online-Shopping, E-Mails',
    value: UsageSelection.Browse,
    e2e: UsageSelection.Browse,
    points: 1,
  },
  {
    text: 'Streaming (z. B. Netflix, Prime Video, auch in HD, 4K)',
    value: UsageSelection.Stream,
    e2e: UsageSelection.Stream,
    points: 4,
  },
  {
    text: 'Social Media und Video-Chat',
    value: UsageSelection.Social,
    e2e: UsageSelection.Social,
    points: 2,
  },
  {
    text: 'Smart Home (z. B. Kamera, Lichtsteuerung)',
    value: UsageSelection.SmartHome,
    e2e: UsageSelection.SmartHome,
    points: 2,
  },
  {
    text: 'Online-Spiele und Gaming',
    value: UsageSelection.Gaming,
    e2e: UsageSelection.Gaming,
    points: 4,
  },
  {
    text: 'Home-Office/E-Learning (z. B. VPN, Cloud Services)',
    value: UsageSelection.HomeOffice,
    e2e: UsageSelection.HomeOffice,
    points: 3,
  },
];

export interface UsagesValue {
  usages: UsageSelection[];
}

export type UsagesProps = UsagesValue & OnUpdate;

export const Usages: React.FC<UsagesProps> = ({ usages, handleChange }) => {
  const handleSelection = (usage: UsageSelection) => {
    handleChange({
      usages: usages.includes(usage)
        ? usages.filter((item) => item !== usage)
        : [...usages, ...[usage]],
    });
  };

  return (
    <div className={styles.stepContainer} data-e2e="tariff-advisor-step-usages">
      <Text appearance="t2_2">Wozu nutzt du zuhause das Internet? </Text>
      <Text appearance="t3">(Mehrfachauswahl möglich)</Text>
      <Spacer b={2} block />
      <Grid fullWidth>
        <Grid.Row>
          {usageSelection.map((usage, ix) => (
            <Grid.Column s={6} key={`${ix}-${usage.value}`}>
              <Selection
                isSelected={usages?.includes(usage.value)}
                onChange={() => {
                  handleSelection(usage.value);
                }}
                name={usage.e2e}
                multiSelect={true}
                {...usage}
              />
            </Grid.Column>
          ))}
        </Grid.Row>
      </Grid>
    </div>
  );
};
