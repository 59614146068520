import * as Yup from 'yup';
import {
  contactSchema,
  bankSchema,
  requiredText,
  requiredOptionText,
  contactFields,
  bankFields,
} from '@ncs-frontend-monorepo/forms';

export const schema = Yup.object().shape({
  associationName: Yup.string().required(requiredText),
  taxNumber: Yup.string().required(requiredText),
  isValueAddedTax: Yup.string().required(requiredOptionText),
  [contactFields.salutation.name]: contactSchema[contactFields.salutation.name],
  [contactFields.firstName.name]: contactSchema[contactFields.firstName.name],
  [contactFields.lastName.name]: contactSchema[contactFields.lastName.name],
  [contactFields.email.name]: contactSchema[contactFields.email.name],
  [contactFields.countryCode.name]:
    contactSchema[contactFields.countryCode.name],
  [contactFields.phoneNumber.name]:
    contactSchema[contactFields.phoneNumber.name],
  [contactFields.zipCode.name]: contactSchema[contactFields.zipCode.name],
  [contactFields.city.name]: contactSchema[contactFields.city.name],
  [contactFields.street.name]: contactSchema[contactFields.street.name],
  [contactFields.houseNumber.name]:
    contactSchema[contactFields.houseNumber.name],
  [bankFields.accountOwner.name]: bankSchema[bankFields.accountOwner.name],
  [bankFields.iban.name]: bankSchema[bankFields.iban.name],
  [bankFields.sepaAgreement.name]: bankSchema[bankFields.sepaAgreement.name],
});
