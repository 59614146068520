import {
  ILeadContact,
  ILeadContactRequestData,
} from '../interfaces/leadContact';
import { fetchLogging, getEnv } from '@ncs-frontend-monorepo/utils';

export const sendLeadContactForm = async (
  targetFeedbackPagePath: string,
  leadContact: ILeadContact,
) => sendFtthLead('POST', '', { leadContact, targetFeedbackPagePath });

export const sendOptIn = async (hash: string) =>
  sendFtthLead('POST', `/${hash}/optIn`);

export const sendOptOut = async (hash: string) =>
  sendFtthLead('DELETE', `/${hash}`);

const sendFtthLead = async (
  method: 'POST' | 'DELETE',
  url?: string,
  body?: ILeadContactRequestData,
) => {
  const headers = new Headers();
  if (method === 'POST') {
    headers.append('Content-Type', 'application/json');
  }

  const response = await fetch(`${getEnv().PK.DSS_API_URL}/ftth/lead${url}`, {
    headers,
    body: JSON.stringify(body),
    method,
  });

  if (!(response.ok && (response.status === 200 || response.status === 204))) {
    fetchLogging({
      message: 'Error during request',
      url: `${getEnv().PK.DSS_API_URL}/ftth/lead"`,
      error: true,
    });
    throw new Error(
      `Error during request towards "${getEnv().PK.DSS_API_URL}/ftth/lead"`,
    );
  }

  return true;
};
