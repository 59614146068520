import { useState } from 'react';
import { Formik } from 'formik';
import { AssociationFormBody, initialFormData } from './data';
import { submitAssociationForm } from './submit';
import { isNC } from '@ncs-frontend-monorepo/utils';
import { FormsWrapper } from '../FormsWrapper';
import { schema } from './schema';
import { AssociationForm } from './Form';
import { useAssociationFormStore } from '../../../store/forms/associationForm';

export const Association: React.FC = () => {
  const { initialState, updateInitialState } = useAssociationFormStore(
    (state) => state,
  );
  const [hasError, setHasError] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);

  const initialValues = {
    ...initialFormData,
    ...initialState,
  };

  const handleSubmit = async (values: AssociationFormBody) => {
    updateInitialState(values);

    try {
      const response = await submitAssociationForm({
        mandant: isNC() ? 'NC' : 'NA',
        ...values,
      });
      setShowSuccess(response);
    } catch (error) {
      setHasError(true);
    }
  };

  return (
    <FormsWrapper
      isSubmitted={showSuccess}
      contentBlock="association-form-success"
      e2e="associationForm-success"
    >
      <Formik
        initialValues={initialValues}
        validationSchema={schema}
        onSubmit={handleSubmit}
        validateOnChange={true}
        validateOnMount={false}
      >
        <AssociationForm hasError={hasError} />
      </Formik>
    </FormsWrapper>
  );
};
