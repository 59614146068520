import * as Yup from 'yup';
import {
  cancellationFormModel,
  ContractType,
  IssueValues,
} from './cancellationFormModel';
import {
  addressValidation,
  customerNoValidation,
  emailValidation,
  generalFieldValidation,
  maxFieldLengthText,
  requiredOptionText,
  requiredText,
} from '@ncs-frontend-monorepo/forms';
import { DateDecision } from './FormElements/Date';

export const schema = Yup.object().shape({
  [cancellationFormModel.issue.name]: Yup.string().required(requiredOptionText),
  [cancellationFormModel.message.name]: Yup.string().when(
    [cancellationFormModel.issue.name],
    {
      is: (issue) => issue !== IssueValues.Exceeding,
      then: (schema) => schema.max(300, maxFieldLengthText(300)),
    },
  ),
  [cancellationFormModel.cancelSelection.name]:
    Yup.string().required(requiredOptionText),
  [cancellationFormModel.cancelContracts.name]: Yup.array().when(
    [cancellationFormModel.cancelSelection.name],
    {
      is: 'select',
      then: (schema) =>
        schema.required(requiredOptionText).min(1, requiredOptionText),
    },
  ),
  [cancellationFormModel.installationPhoneNumber.name]: Yup.string()
    .concat(generalFieldValidation.phoneLong)
    .when(
      [
        `${[cancellationFormModel.cancelSelection.name]}`,
        `${[cancellationFormModel.cancelContracts.name]}`,
      ],
      {
        is: (cancelSelection, contracts = []) =>
          cancelSelection === 'all' ||
          (cancelSelection === 'select' &&
            contracts.includes(ContractType.Landline)),
        then: (schema) => schema.required(requiredText),
      },
    ),
  [cancellationFormModel.cancellationDateSelection.name]:
    Yup.string().required(requiredOptionText),
  [cancellationFormModel.cancellationDate.name]: Yup.string().when(
    [cancellationFormModel.cancellationDateSelection.name],
    {
      is: DateDecision.select,
      then: (schema) => schema.required(requiredText),
    },
  ),
  [cancellationFormModel.customerNumber.name]:
    customerNoValidation.required(requiredText),
  [cancellationFormModel.salutation.name]: Yup.string().required(requiredText),
  [cancellationFormModel.firstName.name]: Yup.string()
    .max(30, maxFieldLengthText(30))
    .required(requiredText),
  [cancellationFormModel.lastName.name]: Yup.string()
    .max(30, maxFieldLengthText(30))
    .required(requiredText),
  [cancellationFormModel.zipCode.name]: addressValidation.zipCode,
  [cancellationFormModel.city.name]: addressValidation.city,
  [cancellationFormModel.street.name]: addressValidation.street,
  [cancellationFormModel.houseNumber.name]: addressValidation.houseNumber,
  [cancellationFormModel.email.name]: emailValidation.required(requiredText),
  [cancellationFormModel.phoneNo.name]: generalFieldValidation.phoneLong,
});
