import { useState } from 'react';
import { Formik, FormikHelpers } from 'formik';
import { ChevronLink, Grid, Spacer, Text } from 'dss-ui-library';
import { schema, smartcardError } from './schema';
import { activateSmartcard } from './activateSmartcard';
import { SmartcardForm, SmartcardFormValues } from './Form';
import { Success } from './Success';
import { isNC } from '@ncs-frontend-monorepo/utils';
import { HotlineNumberSmartcard } from '../../../constants/hotlineNumber';

const { NetAachen, NetCologne } = HotlineNumberSmartcard;

export const SmartcardActivation = () => {
  const [isSmartcardNumberValid, setIsSmartcardNumberValid] = useState(false);

  const handleSubmit = async (
    { smartcardnumber }: SmartcardFormValues,
    actions: FormikHelpers<SmartcardFormValues>,
  ) => {
    try {
      const isSmartcardValid = await activateSmartcard(smartcardnumber);
      setIsSmartcardNumberValid(isSmartcardValid);

      if (!isSmartcardValid) {
        actions.setFieldError('smartcardnumber', smartcardError.general);
      }
    } catch (e) {
      setIsSmartcardNumberValid(false);
      actions.setFieldError('smartcardnumber', smartcardError.general);
    }
  };

  return (
    <div>
      <Formik
        initialValues={{ smartcardnumber: '' }}
        onSubmit={handleSubmit}
        validationSchema={schema}
        validateOnBlur
        validateOnChange
      >
        {({ isValid }) =>
          isValid && isSmartcardNumberValid ? <Success /> : <SmartcardForm />
        }
      </Formik>
      <Spacer t={6} block />
      <Grid>
        <Text element="p" appearance="t3">
          Solltest du weiterhin Probleme mit der Freischaltung haben,
          kontaktiere bitte unsere kostenfreie Hotline unter:{' '}
          <Text appearance="t3" noWrap>
            {isNC() ? NetCologne.formatted : NetAachen.formatted}
          </Text>
          .
        </Text>
        <ChevronLink
          color="blue"
          href={isNC() ? NetCologne.href : NetAachen.href}
          e2e="clicktracking-smartcard-telephone"
        >
          Jetzt anrufen
        </ChevronLink>
      </Grid>
    </div>
  );
};
