import { ProductId } from '@ncs-frontend-monorepo/order';

export const pageIds = {
  [ProductId.NETSPEED_25]: `${process.env.NEXT_PUBLIC_SITE_PREFIX}/internet-tarife/netspeed-25/`,
  [ProductId.NETSPEED_50]: `${process.env.NEXT_PUBLIC_SITE_PREFIX}/internet-tarife/netspeed-50/`,
  [ProductId.NETSPEED_100]: `${process.env.NEXT_PUBLIC_SITE_PREFIX}/internet-tarife/netspeed-100/`,
  [ProductId.NETSPEED_175]: `${process.env.NEXT_PUBLIC_SITE_PREFIX}/internet-tarife/netspeed-175/`,
  [ProductId.NETSPEED_250]: `${process.env.NEXT_PUBLIC_SITE_PREFIX}/internet-tarife/netspeed-250/`,
  [ProductId.NETSPEED_500]: `${process.env.NEXT_PUBLIC_SITE_PREFIX}/internet-tarife/netspeed-500/`,
  [ProductId.NETSPEED_1000]: `${process.env.NEXT_PUBLIC_SITE_PREFIX}/internet-tarife/netspeed-1000/`,
  [ProductId.NETSPEED_YOUNG_25]: `${process.env.NEXT_PUBLIC_SITE_PREFIX}/young-tarife/netspeed-young-25/#withoutAll`,
  [ProductId.NETSPEED_YOUNG_50]: `${process.env.NEXT_PUBLIC_SITE_PREFIX}/young-tarife/netspeed-young-50/#withoutAll`,
  [ProductId.NETSPEED_YOUNG_100]: `${process.env.NEXT_PUBLIC_SITE_PREFIX}/young-tarife/netspeed-young-100/#withoutAll`,
  [ProductId.NETSPEED_YOUNG_175]: `${process.env.NEXT_PUBLIC_SITE_PREFIX}/young-tarife/netspeed-young-175/#withoutAll`,
  [ProductId.NETSPEED_YOUNG_250]: `${process.env.NEXT_PUBLIC_SITE_PREFIX}/young-tarife/netspeed-young-250/#withoutAll`,
  [ProductId.NETSPEED_YOUNG_500]: `${process.env.NEXT_PUBLIC_SITE_PREFIX}/young-tarife/netspeed-young-500/#withoutAll`,
  [ProductId.NETSPEED_YOUNG_1000]: `${process.env.NEXT_PUBLIC_SITE_PREFIX}/young-tarife/netspeed-young-1000/#withoutAll`,
  [ProductId.NETSPEED_PHONE_25]: `${process.env.NEXT_PUBLIC_SITE_PREFIX}/kombipaket-tarife/internet-telefon-25/`,
  [ProductId.NETSPEED_PHONE_50]: `${process.env.NEXT_PUBLIC_SITE_PREFIX}/kombipaket-tarife/internet-telefon-50/`,
  [ProductId.NETSPEED_PHONE_100]: `${process.env.NEXT_PUBLIC_SITE_PREFIX}/kombipaket-tarife/internet-telefon-100/`,
  [ProductId.NETSPEED_PHONE_175]: `${process.env.NEXT_PUBLIC_SITE_PREFIX}/kombipaket-tarife/internet-telefon-175/`,
  [ProductId.NETSPEED_PHONE_250]: `${process.env.NEXT_PUBLIC_SITE_PREFIX}/kombipaket-tarife/internet-telefon-250/`,
  [ProductId.NETSPEED_PHONE_500]: `${process.env.NEXT_PUBLIC_SITE_PREFIX}/kombipaket-tarife/internet-telefon-500/`,
  [ProductId.NETSPEED_PHONE_1000]: `${process.env.NEXT_PUBLIC_SITE_PREFIX}/kombipaket-tarife/internet-telefon-1000/`,
  [ProductId.NETSPEED_TV_25]: `${process.env.NEXT_PUBLIC_SITE_PREFIX}/kombipaket-tarife/internet-tv-25/`,
  [ProductId.NETSPEED_TV_50]: `${process.env.NEXT_PUBLIC_SITE_PREFIX}/kombipaket-tarife/internet-tv-50/`,
  [ProductId.NETSPEED_TV_100]: `${process.env.NEXT_PUBLIC_SITE_PREFIX}/kombipaket-tarife/internet-tv-100/`,
  [ProductId.NETSPEED_TV_175]: `${process.env.NEXT_PUBLIC_SITE_PREFIX}/kombipaket-tarife/internet-tv-175/`,
  [ProductId.NETSPEED_TV_250]: `${process.env.NEXT_PUBLIC_SITE_PREFIX}/kombipaket-tarife/internet-tv-250/`,
  [ProductId.NETSPEED_TV_500]: `${process.env.NEXT_PUBLIC_SITE_PREFIX}/kombipaket-tarife/internet-tv-500/`,
  [ProductId.NETSPEED_TV_1000]: `${process.env.NEXT_PUBLIC_SITE_PREFIX}/kombipaket-tarife/internet-tv-1000/`,
  [ProductId.NETSPEED_PHONE_TV_25]: `${process.env.NEXT_PUBLIC_SITE_PREFIX}/kombipaket-tarife/internet-telefon-tv-25/`,
  [ProductId.NETSPEED_PHONE_TV_50]: `${process.env.NEXT_PUBLIC_SITE_PREFIX}/kombipaket-tarife/internet-telefon-tv-50/`,
  [ProductId.NETSPEED_PHONE_TV_100]: `${process.env.NEXT_PUBLIC_SITE_PREFIX}/kombipaket-tarife/internet-telefon-tv-100/`,
  [ProductId.NETSPEED_PHONE_TV_175]: `${process.env.NEXT_PUBLIC_SITE_PREFIX}/kombipaket-tarife/internet-telefon-tv-175/`,
  [ProductId.NETSPEED_PHONE_TV_250]: `${process.env.NEXT_PUBLIC_SITE_PREFIX}/kombipaket-tarife/internet-telefon-tv-250/`,
  [ProductId.NETSPEED_PHONE_TV_500]: `${process.env.NEXT_PUBLIC_SITE_PREFIX}/kombipaket-tarife/internet-telefon-tv-500/`,
  [ProductId.NETSPEED_PHONE_TV_1000]: `${process.env.NEXT_PUBLIC_SITE_PREFIX}/kombipaket-tarife/internet-telefon-tv-1000/`,
  [ProductId.NETSPEED_YOUNG_PHONE_25]: `${process.env.NEXT_PUBLIC_SITE_PREFIX}/young-tarife/netspeed-young-25/#withoutTv`,
  [ProductId.NETSPEED_YOUNG_PHONE_50]: `${process.env.NEXT_PUBLIC_SITE_PREFIX}/young-tarife/netspeed-young-50/#withoutTv`,
  [ProductId.NETSPEED_YOUNG_PHONE_100]: `${process.env.NEXT_PUBLIC_SITE_PREFIX}/young-tarife/netspeed-young-100/#withoutTv`,
  [ProductId.NETSPEED_YOUNG_PHONE_175]: `${process.env.NEXT_PUBLIC_SITE_PREFIX}/young-tarife/netspeed-young-175/#withoutTv`,
  [ProductId.NETSPEED_YOUNG_PHONE_250]: `${process.env.NEXT_PUBLIC_SITE_PREFIX}/young-tarife/netspeed-young-250/#withoutTv`,
  [ProductId.NETSPEED_YOUNG_PHONE_500]: `${process.env.NEXT_PUBLIC_SITE_PREFIX}/young-tarife/netspeed-young-500/#withoutTv`,
  [ProductId.NETSPEED_YOUNG_PHONE_1000]: `${process.env.NEXT_PUBLIC_SITE_PREFIX}/young-tarife/netspeed-young-1000/#withoutTv`,
  [ProductId.NETSPEED_YOUNG_TV_25]: `${process.env.NEXT_PUBLIC_SITE_PREFIX}/young-tarife/netspeed-young-25/#withoutPhone`,
  [ProductId.NETSPEED_YOUNG_TV_50]: `${process.env.NEXT_PUBLIC_SITE_PREFIX}/young-tarife/netspeed-young-50/#withoutPhone`,
  [ProductId.NETSPEED_YOUNG_TV_100]: `${process.env.NEXT_PUBLIC_SITE_PREFIX}/young-tarife/netspeed-young-100/#withoutPhone`,
  [ProductId.NETSPEED_YOUNG_TV_175]: `${process.env.NEXT_PUBLIC_SITE_PREFIX}/young-tarife/netspeed-young-175/#withoutPhone`,
  [ProductId.NETSPEED_YOUNG_TV_250]: `${process.env.NEXT_PUBLIC_SITE_PREFIX}/young-tarife/netspeed-young-250/#withoutPhone`,
  [ProductId.NETSPEED_YOUNG_TV_500]: `${process.env.NEXT_PUBLIC_SITE_PREFIX}/young-tarife/netspeed-young-500/#withoutPhone`,
  [ProductId.NETSPEED_YOUNG_TV_1000]: `${process.env.NEXT_PUBLIC_SITE_PREFIX}/young-tarife/netspeed-young-1000/#withoutPhone`,
  [ProductId.NETSPEED_YOUNG_PHONE_TV_25]: `${process.env.NEXT_PUBLIC_SITE_PREFIX}/young-tarife/netspeed-young-25/`,
  [ProductId.NETSPEED_YOUNG_PHONE_TV_50]: `${process.env.NEXT_PUBLIC_SITE_PREFIX}/young-tarife/netspeed-young-50/`,
  [ProductId.NETSPEED_YOUNG_PHONE_TV_100]: `${process.env.NEXT_PUBLIC_SITE_PREFIX}/young-tarife/netspeed-young-100/`,
  [ProductId.NETSPEED_YOUNG_PHONE_TV_175]: `${process.env.NEXT_PUBLIC_SITE_PREFIX}/young-tarife/netspeed-young-175/`,
  [ProductId.NETSPEED_YOUNG_PHONE_TV_250]: `${process.env.NEXT_PUBLIC_SITE_PREFIX}/young-tarife/netspeed-young-250/`,
  [ProductId.NETSPEED_YOUNG_PHONE_TV_500]: `${process.env.NEXT_PUBLIC_SITE_PREFIX}/young-tarife/netspeed-young-500/`,
  [ProductId.NETSPEED_YOUNG_PHONE_TV_1000]: `${process.env.NEXT_PUBLIC_SITE_PREFIX}/young-tarife/netspeed-young-1000/`,
};
