import { Button, Grid, Spacer, Text } from 'dss-ui-library';
import { Form, useFormikContext } from 'formik';
import { TextField } from '../../FormikFields/TextField';

export type SmartcardFormValues = {
  smartcardnumber: string;
};

export const SmartcardForm: React.FC = () => {
  const { isSubmitting } = useFormikContext();
  return (
    <Form>
      <Grid>
        <Text element="h4" appearance="t2_2">
          Bitte gib hier deine Smartcard-Nummer ein (ohne Bindestrich):{' '}
        </Text>
        <Spacer t={2} />
        <Grid.Row>
          <Grid.Column xs={10} s={6} m={4}>
            <TextField
              e2e="smartcardnumber"
              label="Smartcard-Nummer"
              name="smartcardnumber"
              isRequired
            />
          </Grid.Column>
        </Grid.Row>
        <Spacer t={4} block />
        <Text element="div" color="grey" appearance="t6">
          *Pflichtfelder
        </Text>
        <Spacer t={2} block />
        <Button
          type="submit"
          e2e="smartcard-registration-submit"
          color="red"
          variant="primary"
          disabled={isSubmitting}
        >
          Jetzt freischalten
        </Button>
      </Grid>
    </Form>
  );
};
