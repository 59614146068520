import { useState } from 'react';
import {
  Button,
  Grid,
  Link,
  Modal,
  RichText,
  Spacer,
  Text,
} from 'dss-ui-library';
import { AddressRow } from '../../Elements/AddressRow';
import {
  ResultContentProps,
  contraryAvailablePlans,
  presalePlansWithAlternative,
} from '.';
import styles from './Result.module.scss';
import cx from 'classnames';
import { ModalContentPresale } from './ModalContent/ModalContentPresale';
import { flushSync } from 'react-dom';
import { AvailabilityOptionSelect } from '../../Views/OptionSelect';
import dynamic from 'next/dynamic';
import { useAvailability } from '@ncs-frontend-monorepo/availability';
import { Media, replaceDynamicText } from '@ncs-frontend-monorepo/utils';

const ResultPlanButtons = dynamic(
  () =>
    import('./Elements/ResultPlanButtons').then(
      ({ ResultPlanButtons }) => ResultPlanButtons,
    ),
  {
    ssr: false,
  },
);

const RocketAnimation = dynamic(
  () =>
    import('../../../Animation/Rocket').then(
      ({ RocketAnimation }) => RocketAnimation,
    ),
  { ssr: false },
);

export const PresaleResult: React.FC<ResultContentProps> = ({
  content,
  address,
  availabilityType,
  portfolio,
  isManualChecked,
  onChangeAddressClick,
  onScrollToPlans,
  onPortfolioClick,
  viewType,
}) => {
  const [isModalVisible, setIsModalVisible] = useState(isManualChecked);
  const { availability } = useAvailability();

  const handleClose = () => {
    flushSync(() => setIsModalVisible(false));
    onScrollToPlans();
  };

  const handleButtonClick = () => {
    availabilityType.portfolio === portfolio
      ? onScrollToPlans()
      : onPortfolioClick();
  };
  return (
    <>
      <Modal
        e2e="presale"
        open={isModalVisible}
        onClose={() => setIsModalVisible(false)}
      >
        {viewType === 'plan' ? (
          <ModalContentPresale
            hasAlternative={presalePlansWithAlternative.includes(
              availabilityType.key,
            )}
            portfolio={portfolio}
            content={content}
            address={address}
            handleRedirect={onPortfolioClick}
            handleClose={handleClose}
            handleAddressReset={onChangeAddressClick}
          />
        ) : (
          <AvailabilityOptionSelect
            onChangeAddressClick={onChangeAddressClick}
          />
        )}
      </Modal>
      <Grid className={styles.fullHeight}>
        <Grid.Row className={styles.fullHeight}>
          <Grid.Column showM hide vCenter s={1} className={styles.fullHeight}>
            <RocketAnimation hasLaunch />
          </Grid.Column>
          <Grid.Column s={12} m={7}>
            <Spacer b={2} />
            <Grid.Column s={12}>
              {address && (
                <AddressRow
                  className={cx({
                    [styles.fadeInAddressRow]:
                      availabilityType.portfolio === 'FIBER',
                  })}
                  address={address}
                  noSpace
                  onChangeAddressClick={onChangeAddressClick}
                />
              )}
              <Spacer />
            </Grid.Column>
            <Grid.Column className={styles.icon}>
              <RocketAnimation hasLaunch />
              <Spacer l={2} block />
            </Grid.Column>
            <Grid.Column
              className={cx({
                [styles.textContentInline]: contraryAvailablePlans.includes(
                  availabilityType.key,
                ),
                [styles.fadeInText]: availabilityType.portfolio === 'FIBER',
              })}
            >
              <RichText
                text={replaceDynamicText(content.text, {
                  presaleDate:
                    availability?.promotions.presalePromotion
                      ?.plannedAvailabilityDateDescription,
                })}
              />
              {viewType === 'plan' && (
                <Link onClick={() => setIsModalVisible(true)}>
                  <Text appearance="t4">
                    <strong> Mehr Informationen</strong>
                  </Text>
                </Link>
              )}
            </Grid.Column>
            <Spacer />
          </Grid.Column>
          <Grid.Column s={12} m={4} vCenter>
            {viewType === 'plan' ? (
              <ResultPlanButtons
                portfolio={availabilityType.portfolio}
                buttonText="Zu den Glasfaser-Tarifen"
                content={content}
                handleOnClick={handleButtonClick}
              />
            ) : (
              <Button
                fullWidth
                color="blue"
                e2e="show-global-check"
                variant="primary"
                onClick={() => setIsModalVisible(true)}
              >
                Mehr Informationen
              </Button>
            )}
            <Media between={['xs', 'm']}>
              <Spacer b={1} />
            </Media>
          </Grid.Column>
        </Grid.Row>
        <Spacer b={1} />
      </Grid>
    </>
  );
};
