import { fetcher, getEnv } from '@ncs-frontend-monorepo/utils';
import useSWR from 'swr';
const fetchContent = async ({ contentId }: ContentKey) => {
  try {
    let contentKey: string;
    const isContentArray = Array.isArray(contentId);
    if (isContentArray) {
      contentKey =
        '?' +
        new URLSearchParams(
          contentId.map((key) => ['tx_mask_api_slug[]', key]),
        ).toString();
    } else {
      contentKey = `/${contentId}`;
    }
    const result = await fetcher(`${getEnv().API_URL}/content${contentKey}`);

    return isContentArray ? result?.content : result?.content?.props;
  } catch (e) {
    console.error(e);
    throw new Error(e);
  }
};

interface ContentKey {
  contentId: string | string[];
}

export const useFetchContent = <T>(args: ContentKey) => {
  const { data, error } = useSWR<T>(() => (args ? args : null), fetchContent, {
    revalidateIfStale: false,
    revalidateOnFocus: false,
  });

  return {
    data,
    error,
  };
};
