import { useFormikContext } from 'formik';
import React, { useEffect } from 'react';

const scrollTo = (
  from: number,
  to: number,
  duration: number,
  onDone: () => void,
) => {
  const start = from,
    change = to - start,
    startTime = performance.now();
  let now: number, elapsed: number, t: number;

  function animateScroll() {
    now = performance.now();
    elapsed = (now - startTime) / 1000;
    t = elapsed / duration;

    const next = start + change * easeInOut(t);
    document.body.scrollTop = document.documentElement.scrollTop = next;

    if (t < 1) window.requestAnimationFrame(animateScroll);
    else onDone && onDone();
  }
  animateScroll();
};

const easeInOut = (t: number) => {
  return t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t;
};

const ErrorFocus: React.FC = () => {
  const { errors, isSubmitting } = useFormikContext();

  useEffect(() => {
    const errorList = Object.keys(errors);
    if (isSubmitting && errorList.length) {
      const fieldName = Object.keys(errors)[0];
      const elements = document.getElementsByName(fieldName);

      if (elements.length > 0) {
        let element = elements[0];
        if (window.getComputedStyle(element).display === 'none') {
          element = element.parentElement;
        }
        const scrollTop = Math.max(
          window.pageYOffset,
          document.documentElement.scrollTop,
          document.body.scrollTop,
        );

        const position = scrollTop + element.getBoundingClientRect().top - 200;

        scrollTo(scrollTop, position, 0.4, () => {
          if (elements.length > 0) {
            elements[0].focus();
          }
        });
      }
    }
  }, [errors, isSubmitting]);

  return null;
};

export { ErrorFocus };
