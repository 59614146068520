import { Form as FormikForm, useFormikContext } from 'formik';
import { GeneralData } from './GeneralData';
import { Button, ErrorMessage, Grid, Spacer } from 'dss-ui-library';
import React, { useEffect } from 'react';
import { isNC } from '@ncs-frontend-monorepo/utils';
import { PRIVACY_POLICY_URL } from '../../../../constants/urls';
import { StoreData } from './StoreData';
import { useOrderContext } from '../../../OrderContext';
import { ResellerContactRequest } from '../model';
import { ErrorFocus } from '../../../ErrorFocus';
import { PrivacyInfo, RequiredInfo } from '@ncs-frontend-monorepo/forms';

export const Form: React.FC<{ hasError: boolean }> = ({ hasError }) => {
  const { saveFormData } = useOrderContext();
  const { values, isSubmitting } = useFormikContext();
  const privacyLink =
    (isNC() ? 'https://www.netcologne.de' : 'https://www.netaachen.de') +
    PRIVACY_POLICY_URL;

  useEffect(() => {
    saveFormData({
      resellerContact: values as ResellerContactRequest,
    });
  }, [values]);

  return (
    <FormikForm>
      <GeneralData />
      <StoreData />
      <Grid>
        <Grid.Row>
          <Grid.Column s={10} m={8}>
            <Spacer t={2} block />
            <PrivacyInfo
              url={privacyLink}
              e2e="reseller-privacy"
              isFormal={false}
              title="Datenschutz"
            />
            <Spacer b={3} block />
            <RequiredInfo />
            <Spacer b={2} block />
          </Grid.Column>
        </Grid.Row>
        {hasError && (
          <Grid.Row>
            <Grid.Column s={10} m={8}>
              <Spacer y={2} block>
                <ErrorMessage e2e="reseller-registration-error">
                  Es ist ein Fehler aufgetreten. Ihre Anfrage konnte nicht
                  übermittelt werden. Bitte versuchen Sie es zu einem späteren
                  Zeitpunkt noch mal.
                </ErrorMessage>
              </Spacer>
            </Grid.Column>
          </Grid.Row>
        )}
        <ErrorFocus />
        <Button
          variant="primary"
          color="red"
          e2e="reseller-registration-submit"
          disabled={isSubmitting}
          type="submit"
        >
          Absenden
        </Button>
      </Grid>
    </FormikForm>
  );
};
