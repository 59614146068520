import React, { useState } from 'react';
import {
  Button,
  Grid,
  Link,
  Modal,
  RichText,
  Spacer,
  Text,
} from 'dss-ui-library';
import cx from 'classnames';
import { AddressRow } from '../../Elements/AddressRow';
import { ResultContentProps } from '.';
import styles from './Result.module.scss';
import { ModalContentFTTH } from './ModalContent/ModalContentFTTH';
import { flushSync } from 'react-dom';
import { ContentKey } from '../utils';
import { useRouter } from 'next/router';
import { AvailabilityOptionSelect } from '../../Views/OptionSelect';
import { ADSLFallback } from '../../Views/ADSLFallback';
import { Media } from '@ncs-frontend-monorepo/utils';
import dynamic from 'next/dynamic';
import { Fallback, useAvailability } from '@ncs-frontend-monorepo/availability';

const RocketAnimation = dynamic(
  () =>
    import('../../../Animation/Rocket').then(
      ({ RocketAnimation }) => RocketAnimation,
    ),
  { ssr: false },
);

export const ftthButtonLinkText: ContentKey[] = [
  'CLASSIC_AVAILABLE_UNDER_CONSTRUCTION_DATE',
  'CLASSIC_AVAILABLE_UNDER_CONSTRUCTION',
  'CLASSIC_AVAILABLE_PRE_MARKETING_DATE',
  'CLASSIC_AVAILABLE_PRE_MARKETING',
  'UNDER_CONSTRUCTION_DATE',
  'UNDER_CONSTRUCTION',
  'PRE_MARKETING_DATE',
  'PRE_MARKETING',
];

export const FTTHResult: React.FC<ResultContentProps> = ({
  content,
  address,
  availabilityType,
  portfolio,
  isManualChecked,
  onChangeAddressClick,
  onScrollToPlans,
  viewType,
}) => {
  const [isModalVisible, setIsModalVisible] = useState(
    // for FTTH results, modals should always pop up after availability check
    content && isManualChecked,
  );
  const { push } = useRouter();
  const { availability } = useAvailability();

  const handleButtonClick = () => {
    const expansionLink = availability.objectInformation?.landingpagePk;
    push(expansionLink);
  };

  const handleClose = () => {
    flushSync(() => setIsModalVisible(false));
    onScrollToPlans();
  };

  return (
    <>
      <Modal
        e2e={`smallAC-${availabilityType.key}`}
        open={isModalVisible}
        onClose={() => setIsModalVisible(false)}
      >
        {viewType === 'plan' ? (
          <ModalContentFTTH
            content={content}
            address={address}
            portfolio={portfolio}
            handleFtth={handleButtonClick}
            handleClose={handleClose}
            handleAddressReset={onChangeAddressClick}
            availabilityType={availabilityType}
          />
        ) : (
          <>
            {availability?.promotions?.fallback === Fallback.ADSL ? (
              <ADSLFallback resetAddress={onChangeAddressClick} />
            ) : (
              <AvailabilityOptionSelect
                onChangeAddressClick={onChangeAddressClick}
              />
            )}
          </>
        )}
      </Modal>

      <Grid className={styles.fullHeight}>
        <Grid.Row className={styles.fullHeight}>
          <Grid.Column showM hide vCenter s={1} className={styles.fullHeight}>
            <RocketAnimation hasLaunch />
          </Grid.Column>
          <Grid.Column s={12} m={7}>
            <Spacer b={2} />
            <Grid.Column s={12}>
              {address && (
                <AddressRow
                  className={cx({
                    [styles.fadeInAddressRow]: portfolio === 'FIBER',
                  })}
                  address={address}
                  noSpace
                  onChangeAddressClick={onChangeAddressClick}
                />
              )}
              <Spacer />
            </Grid.Column>
            <Grid.Column className={styles.icon}>
              <RocketAnimation hasLaunch />
              <Spacer l={2} block />
            </Grid.Column>
            <Grid.Column
              e2e={`smallAC-result-${availabilityType.key}`}
              className={styles.fadeInText}
            >
              <RichText text={content.text} />{' '}
              {viewType === 'plan' && (
                <Link onClick={() => setIsModalVisible(true)}>
                  <Text appearance="t4_2">Mehr Informationen</Text>
                </Link>
              )}
            </Grid.Column>
            <Spacer />
          </Grid.Column>
          <Grid.Column s={12} m={4} vCenter className={styles.fadeInText}>
            {viewType === 'plan' ? (
              <Button
                color="blue"
                e2e={`btn-smallAC-ftth-landingpage-${availabilityType.key}`}
                variant="primary"
                onClick={handleButtonClick}
              >
                {ftthButtonLinkText.includes(availabilityType.key)
                  ? 'Informieren & vorbestellen'
                  : 'Jetzt informieren'}
              </Button>
            ) : (
              <Button
                fullWidth
                color="blue"
                e2e="show-global-check"
                variant="primary"
                onClick={() => setIsModalVisible(true)}
              >
                Mehr Informationen
              </Button>
            )}
            <Media between={['xs', 'm']}>
              <Spacer b={1} />
            </Media>
          </Grid.Column>
        </Grid.Row>
        <Spacer b={1} />
      </Grid>
    </>
  );
};
