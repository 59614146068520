import cx from 'classnames';
import {
  AutoSuggest,
  Button,
  Grid,
  IconClose,
  IconSearch,
  OptionProp,
  Spacer,
  Text,
  useBreakpoints,
} from 'dss-ui-library';
import dynamic from 'next/dynamic';
import {
  createContext,
  FC,
  RefObject,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { districtOptions, Districts } from '../../constants/districts';
import { Media, useIntersectionObserver } from '@ncs-frontend-monorepo/utils';
import { useModalContext } from '../ModalContext';
import { CologneDistricts } from './Cologne/CologneDistricts';
import styles from './ExpensionMap.module.scss';

const Popup = dynamic(
  () => import('dss-ui-library').then(({ Popup }) => Popup),
  {
    ssr: false,
  },
);

const Expandable = dynamic(() =>
  import('dss-ui-library').then(({ Expandable }) => Expandable),
);

const ContentProvider = dynamic(
  () =>
    import('../ExpansionCheck/Modals').then(
      ({ ContentProvider }) => ContentProvider,
    ),
  {
    ssr: false,
  },
);

export type ExpansionDistricts = {
  [key in Districts]: number;
};

interface ExpansionMapsContext {
  selectedDistrict: Districts | null;
  expansionState: ExpansionDistricts;
  handleSpotted(district: Districts): void;
  resetDistrict(): void;
  handleSelection(params: DistrictSelectionProps): void;
}

const Context = createContext<ExpansionMapsContext>(null);

Context.displayName = 'ExpansionMapContext';

export const useExpansionMapContext: () => ExpansionMapsContext = () => {
  const contextState = useContext(Context);
  if (contextState === null) {
    throw new Error(
      'useExpansionMapContext must be used within a <ExpansionMap> tag',
    );
  }
  return contextState;
};

interface DistrictSelectionProps {
  district: Districts;
  ref: RefObject<SVGElement>;
}

const SMALL_DISTRICTS = [Districts.Finkenberg, Districts.Mauenheim];

export const ExpansionMap: FC<ExpansionDistricts> = (districts) => {
  const { handleModal } = useModalContext();
  const { isXS, isS, isM } = useBreakpoints();
  const [showPopup, setShowPopup] = useState(false);
  const [selectedDistrict, setSelectedDistrict] = useState<Districts>();
  const [spottedDistricts, setSpottedDistricts] = useState<Districts[]>([]);
  const [currentValue, setCurrentValue] = useState<OptionProp>();
  const [showAnimation, setShowAnimation] = useState(false);
  const mapRef = useRef<SVGSVGElement>();
  const districtRef = useRef<SVGElement>();
  const expansionPopupRef = useRef<HTMLDivElement>();
  const { entry } = useIntersectionObserver(mapRef);

  const popupOffsetTop = isS ? 119 : isM ? 136 : 172;

  const handleSelection = useCallback(
    ({ district, ref }: DistrictSelectionProps) => {
      if (districtRef.current) {
        resetDistrict();
      } else {
        districtRef.current = ref.current;
        setSelectedDistrict(district);
      }
    },
    [selectedDistrict],
  );

  const handleSpotted = useCallback(
    (district?: Districts) => {
      setSpottedDistricts(district ? [district] : []);
    },
    [selectedDistrict],
  );

  const resetDistrict = () => {
    districtRef.current = null;
    setSelectedDistrict(null);
    setShowPopup(false);
  };

  const handleOptionSelect = (selection: OptionProp) => {
    const selectedDistrict = mapRef.current.querySelector(
      `#${selection.value} text`,
    );

    if (selectedDistrict) {
      districtRef.current = selectedDistrict as SVGElement;
      setSelectedDistrict(selection.value as Districts);
      setSpottedDistricts([]);
    }
  };

  const getOptions = (value: string): Promise<OptionProp[]> => {
    return new Promise((resolve) => {
      resetDistrict();
      const filteredOptions = districtOptions.filter((i) =>
        i.label.toLowerCase().startsWith(value.toLowerCase()),
      );

      if (filteredOptions.length > 0) {
        const filteredDistricts = filteredOptions.map(
          (district) => district.value,
        );
        setSpottedDistricts(filteredDistricts as Districts[]);
      } else {
        setSpottedDistricts([]);
      }
      resolve(filteredOptions);
    });
  };

  const onChange = (optionValue: OptionProp) => {
    setCurrentValue({ label: optionValue.label, value: optionValue.value });
    setSpottedDistricts([]);
    setShowPopup(false);
  };

  const handleOptionFocus = () => {
    resetDistrict();
  };

  const handleCheck = () => {
    handleModal({
      e2e: `modal-expansion-map-check`,
      content: <ContentProvider e2e="modal-expansion-map-check-content" />,
    });
  };

  const popupContent = () => (
    <div className={styles.popupContent}>
      <header>
        <Text appearance="t5_2" e2e="expansion-map-district" element="h2">
          {districtOptions.filter(
            (district) => district.value === selectedDistrict,
          )?.[0]?.label || ''}
        </Text>
        <IconClose
          width={19}
          height={19}
          color="blue"
          className={styles.popupContentClose}
          onClick={resetDistrict}
        />
      </header>
      <Text appearance="t6">
        Wir haben bereits bis zu {districts[selectedDistrict]} % deines Veedels
        mit Glasfaser versorgt. Prüfe jetzt, ob deine Adresse auch schon
        angeschlossen ist.
      </Text>
      <Spacer t={1} block>
        <Button
          variant="primary"
          color="red"
          e2e="expansion-map-check"
          fullWidth
          onClick={handleCheck}
        >
          Ausbaustatus prüfen
        </Button>
      </Spacer>
    </div>
  );

  const expansionLegend = () => (
    <ul className={styles.expansionLegend}>
      <li className={cx(styles.expansionLegendItem, styles.expansionLegend100)}>
        Glasfaser-Abdeckung {'>'} 70 % bis 100 %
      </li>
      <li className={cx(styles.expansionLegendItem, styles.expansionLegend70)}>
        Glasfaser-Abdeckung {'>'} 50 % bis 70 %
      </li>
      <li className={cx(styles.expansionLegendItem, styles.expansionLegend50)}>
        Glasfaser-Abdeckung ≤ 50 %
      </li>
    </ul>
  );

  useEffect(() => {
    if (districtRef.current && selectedDistrict) {
      setShowPopup(true);
    }
    if (!districtRef.current && !selectedDistrict) {
      setShowPopup(false);
    }
  }, [districtRef.current, selectedDistrict]);

  useEffect(() => {
    if (currentValue?.value === '') {
      resetDistrict();
    }
  }, [currentValue]);

  useEffect(() => {
    if (showPopup) {
      isXS
        ? expansionPopupRef?.current?.scrollIntoView?.({
            behavior: 'smooth',
            block: 'start',
          })
        : districtRef?.current?.scrollIntoView?.({
            behavior: 'smooth',
            block: 'nearest',
          });
    }
  }, [showPopup]);

  useEffect(() => {
    if (entry?.isIntersecting && !showAnimation) {
      setShowAnimation(true);
    }
  }, [entry, showAnimation]);

  return (
    <Context.Provider
      value={{
        expansionState: districts,
        selectedDistrict,
        handleSelection,
        resetDistrict,
        handleSpotted,
      }}
    >
      <Grid>
        <Grid.Row>
          <Grid.Column>
            <Text appearance="t2" element="h2" color="blue">
              Prüfe hier, ob dein Veedel bereits an unser Glasfasernetz
              angeschlossen ist.
            </Text>
            <Spacer b={4} block />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column
            s={6}
            m={5}
            l={4}
            offsetStartS={7}
            offsetStartM={8}
            offsetStartL={9}
            className={styles.expansionMapOptions}
          >
            <div className={styles.container}>
              <AutoSuggest
                name="district"
                label="Suche dein Veedel"
                e2e="district"
                onItemSelected={handleOptionSelect}
                defaultOptions={districtOptions}
                getOptions={getOptions}
                value={currentValue}
                onChange={onChange}
                hideLoadingMessage
                hideNooptionsMessage
                minLength={1}
                icon={<IconSearch scale="small" color="blue" />}
                debounce={false}
                onFocus={handleOptionFocus}
              />
              <Media greaterThan="xs">{expansionLegend()}</Media>
              <Media lessThan="s">
                <div ref={expansionPopupRef}>
                  <Expandable expanded={showPopup} e2e="expansion-map-poup">
                    {popupContent()}
                  </Expandable>
                </div>
              </Media>
            </div>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <Grid>
        <Grid.Row>
          <Grid.Column>
            <section className={styles.expansionMapWrapper}>
              <Media greaterThan="xs">
                <Popup
                  content={popupContent()}
                  e2e="expansion-map"
                  showPopup={showPopup}
                  wrapperRef={districtRef}
                  selfControl
                  offsetTop={popupOffsetTop}
                />
              </Media>

              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 1112 1112"
                x="0px"
                y="0px"
                className={cx(styles.expansionMap, {
                  [styles.hasSelection]: !!selectedDistrict,
                  [styles.hasSpottedDistrict]: spottedDistricts.length > 0,
                })}
                onMouseLeave={() => {
                  handleSpotted();
                }}
                ref={mapRef}
                data-e2e="expansion-map"
              >
                <CologneDistricts />
                <path
                  className={styles.rhineOverlay}
                  d="M596.1 1044.8c5.8-15.7 37.1-34.1 38.3-34.9 6.2-4 16.2-10 21.4-13.6 6-3.9 11.4-9.4 16.4-14.6 1.3-1.4 2.4-2.9 3.7-4.3 14.7-16.2 32-43 48.5-52 6.1-3.3 18.9-10.3 25.3-13 3.6-1.5 16.2-10.2 19.7-12.4 11.7-7.4 16.8-15.7 16.6-28.7h0c-.2-18.2-10.4-36.1-27.1-43.9-9.4-4.1-21-5.5-31.5-5.7-1.9 0-3.7.1-5.6.2-5.2.4-14.7-.4-18.8-.7-6.8-.4-19.2-.2-23.2-.2-11.1.1-23.4-2.8-34-7.1-3.5-1.4-6.8-3.2-10-5.3-9.2-6-18.7-12.1-24.8-21.1-8-10.1-15.8-26.4-22-37.8-7.5-13.7-13.1-27.1-17.9-42.4-.7-2.4-2.6-12.8-3.1-15.3-6.1-28.8-16.7-67.7-3-98.1 6.1-13.6 15.7-19.9 26.3-32 1.4-1.6 4.5-3.9 6.3-5.1 4.6-2.9 10.5-7 17.6-9.3 20.6-6.7 27.5-38.3 27.5-38.4 4-17.3-1-36-13.8-45.9-17.2-13.2-38.7-22.6-50.9-29.6-3.8-2.2-7.2-4.9-10.3-8l-5.1-5.2c-.8-.8-1.6-1.7-2.4-2.6-11.1-11.4-10.2-27.7-9.3-43.1 1.1-17.9 11.5-31.7 18.3-44.6 4.1-8.6 5.8-11.7 8.2-19.7 1.1-3.6 2.1-19 1.4-22.7 0-.1 1.1-14-13.7-32.1-19.9-24.2-52.6-45.6-74.8-63.5-32.5-23.4-32.7-27.4-112.7-87-1.2-.9-28.1-13.4-29.4-14.1-45.5-25.7-87.2-4.1-87.9-67.4"
                />
                <path
                  className={cx(styles.rhine, {
                    [styles.showAnimation]: showAnimation,
                  })}
                  d="M596.1 1044.8c5.8-15.7 37.1-34.1 38.3-34.9 6.2-4 16.2-10 21.4-13.6 6-3.9 11.4-9.4 16.4-14.6 1.3-1.4 2.4-2.9 3.7-4.3 14.7-16.2 32-43 48.5-52 6.1-3.3 18.9-10.3 25.3-13 3.6-1.5 16.2-10.2 19.7-12.4 11.7-7.4 16.8-15.7 16.6-28.7h0c-.2-18.2-10.4-36.1-27.1-43.9-9.4-4.1-21-5.5-31.5-5.7-1.9 0-3.7.1-5.6.2-5.2.4-14.7-.4-18.8-.7-6.8-.4-19.2-.2-23.2-.2-11.1.1-23.4-2.8-34-7.1-3.5-1.4-6.8-3.2-10-5.3-9.2-6-18.7-12.1-24.8-21.1-8-10.1-15.8-26.4-22-37.8-7.5-13.7-13.1-27.1-17.9-42.4-.7-2.4-2.6-12.8-3.1-15.3-6.1-28.8-16.7-67.7-3-98.1 6.1-13.6 15.7-19.9 26.3-32 1.4-1.6 4.5-3.9 6.3-5.1 4.6-2.9 10.5-7 17.6-9.3 20.6-6.7 27.5-38.3 27.5-38.4 4-17.3-1-36-13.8-45.9-17.2-13.2-38.7-22.6-50.9-29.6-3.8-2.2-7.2-4.9-10.3-8l-5.1-5.2c-.8-.8-1.6-1.7-2.4-2.6-11.1-11.4-10.2-27.7-9.3-43.1 1.1-17.9 11.5-31.7 18.3-44.6 4.1-8.6 5.8-11.7 8.2-19.7 1.1-3.6 2.1-19 1.4-22.7 0-.1 1.1-14-13.7-32.1-19.9-24.2-52.6-45.6-74.8-63.5-32.5-23.4-32.7-27.4-112.7-87-1.2-.9-28.1-13.4-29.4-14.1-45.5-25.7-87.2-4.1-87.9-67.4"
                />

                {!selectedDistrict && (
                  <>
                    {spottedDistricts?.map((spottedDistrict) => (
                      <use
                        href={`#${spottedDistrict}`}
                        className={cx(styles.spottedDistrict, {
                          [styles.zoomLarge]:
                            SMALL_DISTRICTS.includes(spottedDistrict),
                        })}
                        key={spottedDistrict}
                        data-e2e={`expansion-map-spotted-${spottedDistrict}`}
                      />
                    ))}
                  </>
                )}

                <use
                  key={selectedDistrict}
                  href={`#${selectedDistrict || ''}`}
                  className={styles.highlightedDistrict}
                  data-e2e={`expansion-map-selected-${selectedDistrict}`}
                />
              </svg>
            </section>
            <Media lessThan="s">{expansionLegend()}</Media>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Context.Provider>
  );
};
