import { UploadedFileProps } from 'dss-ui-library/lib/components/FileUpload/File';
import {
  correlationIdHeader,
  getCorrelationId,
} from '@ncs-frontend-monorepo/utils';

export const fileUploadHelper = (uploadPath: string) => {
  const handleUpload = (
    file: UploadedFileProps,
    setProgress: (progress: number, errorMessage?: string) => void,
  ) => {
    const request = new XMLHttpRequest();
    const formData = new FormData();
    formData.append('file', file.blob);

    request.open('POST', uploadPath);

    request.setRequestHeader(correlationIdHeader, getCorrelationId());

    request.upload.addEventListener('progress', (e) => {
      const progressPercentage = (e.loaded / e.total) * 100;
      if (progressPercentage < 100) {
        setProgress(isNaN(progressPercentage) ? 0 : progressPercentage);
      }
    });

    request.addEventListener('load', () => {
      if (request.status !== 201) {
        setProgress(0, 'Bitte prüfe Dateigröße oder -format.');
      } else {
        const response = JSON.parse(request.response);
        file.uid = response.fileId;
        setProgress(100);
      }
    });
    request.send(formData);
  };

  const handleDelete = (file: UploadedFileProps) =>
    new Promise<void>((resolve) => {
      fetch(uploadPath + '/' + file.uid, {
        method: 'DELETE',
        headers: {
          [correlationIdHeader]: getCorrelationId(),
        },
      }).finally(() => resolve());
    });

  return {
    handleUpload,
    handleDelete,
  };
};
