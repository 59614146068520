import * as Yup from 'yup';

export const smartcardError = {
  // client side
  required: 'Die Smartcard-Nummer ist ungültig.',
  wrongLength: 'Die Smartcard-Nummer muss 12 Ziffern enthalten.',
  numbers: 'Die Smartcard-Nummer darf nur Ziffern von 0-9 enthalten.',

  // generic api response
  general: 'Es ist ein Fehler aufgetreten, bitte versuche es noch einmal.',
} as const;

export const schema = Yup.object().shape({
  smartcardnumber: Yup.string()
    .required(smartcardError.required)
    .matches(/^[0-9]+$/, smartcardError.numbers)
    .min(12, smartcardError.wrongLength)
    .max(12, smartcardError.wrongLength),
});
