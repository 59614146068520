import React, { useState } from 'react';
import {
  Button,
  Grid,
  Link,
  Modal,
  RichText,
  Spacer,
  Text,
} from 'dss-ui-library';
import { AddressRow } from '../../Elements/AddressRow';
import {
  ResultContentProps,
  contraryAvailablePlans,
  classicAvailablePlans,
} from '.';
import { WlanIcon } from './Icons';
import styles from './Result.module.scss';
import cx from 'classnames';
import { ModalContentAvailable } from './ModalContent';
import { AvailabilityOptionSelect } from '../../Views/OptionSelect';
import dynamic from 'next/dynamic';
import { Media } from '@ncs-frontend-monorepo/utils';

const RocketAnimation = dynamic(
  () =>
    import('../../../Animation/Rocket').then(
      ({ RocketAnimation }) => RocketAnimation,
    ),
  { ssr: false },
);

const ResultPlanButtons = dynamic(
  () =>
    import('./Elements/ResultPlanButtons').then(
      ({ ResultPlanButtons }) => ResultPlanButtons,
    ),
  {
    ssr: false,
  },
);

export const AvailableResult: React.FC<ResultContentProps> = ({
  content,
  address,
  availabilityType,
  portfolio,
  isManualChecked,
  onChangeAddressClick,
  onScrollToPlans,
  onPortfolioClick,
  viewType,
}) => {
  const [isModalVisible, setIsModalVisible] = useState(
    isManualChecked &&
      (availabilityType.portfolio !== portfolio || viewType === 'standalone'),
  );

  const isFiber = availabilityType.portfolio === 'FIBER';

  const buttonText = classicAvailablePlans.includes(availabilityType.key)
    ? 'Zu den DSL/Kabel-Tarifen'
    : 'Zu den Glasfaser-Tarifen';

  const handleButtonClick = () => {
    availabilityType.portfolio === portfolio
      ? onScrollToPlans()
      : onPortfolioClick();
  };

  return (
    <>
      <Modal
        e2e={
          viewType === 'plan'
            ? 'contrary-availability'
            : 'availability-check-global'
        }
        open={isModalVisible}
        onClose={() => setIsModalVisible(false)}
      >
        {viewType === 'plan' ? (
          <ModalContentAvailable
            portfolio={availabilityType.portfolio}
            content={content}
            address={address}
            handleAddressReset={onChangeAddressClick}
          />
        ) : (
          <AvailabilityOptionSelect
            onChangeAddressClick={onChangeAddressClick}
          />
        )}
      </Modal>
      <Grid className={styles.fullHeight}>
        <Grid.Row className={styles.fullHeight}>
          <Grid.Column showM hide vCenter s={1} className={styles.fullHeight}>
            {classicAvailablePlans.includes(availabilityType.key) ? (
              <WlanIcon isCentered />
            ) : (
              <RocketAnimation hasLaunch />
            )}
          </Grid.Column>
          <Grid.Column s={12} m={7}>
            <Spacer b={2} />
            <Grid.Column s={12}>
              {address && (
                <AddressRow
                  className={cx({
                    [styles.fadeInAddressRow]: isFiber,
                  })}
                  address={address}
                  noSpace
                  onChangeAddressClick={onChangeAddressClick}
                />
              )}
              <Spacer />
            </Grid.Column>
            <Grid.Column className={styles.icon}>
              {availabilityType.key === 'CLASSIC_AVAILABLE' ||
              availabilityType.key === 'CONTRARY_CLASSIC_AVAILABLE' ? (
                <WlanIcon isCentered />
              ) : (
                <RocketAnimation hasLaunch />
              )}
              <Spacer l={2} block />
            </Grid.Column>
            <Grid.Column
              className={cx({
                [styles.textContentInline]: contraryAvailablePlans.includes(
                  availabilityType.key,
                ),
                [styles.fadeInText]: isFiber,
              })}
            >
              <RichText text={content.text} />{' '}
              {contraryAvailablePlans.includes(availabilityType.key) &&
                viewType === 'plan' && (
                  <Link onClick={() => setIsModalVisible(true)}>
                    <Text appearance="t4">
                      <strong> Mehr Informationen</strong>
                    </Text>
                  </Link>
                )}
            </Grid.Column>
            <Spacer />
          </Grid.Column>
          <Grid.Column s={12} m={4} vCenter>
            {viewType === 'plan' ? (
              <ResultPlanButtons
                portfolio={availabilityType.portfolio}
                buttonText={buttonText}
                content={content}
                handleOnClick={handleButtonClick}
              />
            ) : (
              <Button
                fullWidth
                color="blue"
                e2e="show-global-check"
                variant="primary"
                onClick={() => setIsModalVisible(true)}
              >
                Mehr Informationen
              </Button>
            )}
            <Media between={['xs', 'm']}>
              <Spacer b={1} />
            </Media>
          </Grid.Column>
        </Grid.Row>
        <Spacer b={1} />
      </Grid>
    </>
  );
};
