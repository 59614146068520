import { Grid, IconPhone, RichText, Spacer, Text } from 'dss-ui-library';
import { AddressRow } from '../../Elements/AddressRow';
import { ResultContentProps } from '.';
import { Link } from '@ncs-frontend-monorepo/content-library';
import { useAvailabilityContext } from '../../Context';
import { isNC } from '@ncs-frontend-monorepo/utils';
import { HotlineNumberSmartcard } from '../../../../constants/hotlineNumber';

const { NetAachen, NetCologne } = HotlineNumberSmartcard;

export const ADSLResult: React.FC<ResultContentProps> = ({
  content,
  address,
  onChangeAddressClick,
}) => {
  const { templateId } = useAvailabilityContext();

  return (
    <Grid>
      <Spacer b={2} />
      <Grid.Row>
        <Grid.Column>
          <AddressRow
            address={address}
            noSpace
            onChangeAddressClick={onChangeAddressClick}
          />
          <Spacer />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column s={12} m={8}>
          <RichText text={content.text} />
          <Spacer t={2} block />
        </Grid.Column>
        <Grid.Column s={12} m={4}>
          <Link
            appearance="t4_2"
            url={isNC() ? NetCologne.href : NetAachen.href}
            e2e={`${templateId}-contact`}
            icon={<IconPhone color="blue" width={24} height={24} />}
          >
            {isNC() ? NetCologne.formatted : NetAachen.formatted}
          </Link>
          <Spacer t={1} block />
          <Text appearance="t5">Wir sind rund um die Uhr für dich da. </Text>
        </Grid.Column>
      </Grid.Row>
      <Spacer b={2} />
    </Grid>
  );
};
