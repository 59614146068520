import { Mandant } from '@ncs-frontend-monorepo/utils';
import { FormField } from '../../../interfaces/form';

export interface ResellerContactModel {
  company: FormField;
  salutation: FormField;
  title: FormField;
  firstName: FormField;
  lastName: FormField;
  zipCode: FormField;
  city: FormField;
  street: FormField;
  houseNumber: FormField;
  email: FormField;
  countryCode: FormField;
  phoneNo: FormField;
  salesroom: FormField;
  website: FormField;
}

export const resellerContactModel: ResellerContactModel = {
  company: {
    name: 'company',
    label: 'Firma',
    mandatory: true,
  },
  salutation: {
    name: 'salutation',
    label: 'Anrede',
    mandatory: true,
  },
  title: {
    name: 'title',
    label: 'Titel',
  },
  firstName: {
    name: 'first-name',
    label: 'Vorname',
    mandatory: true,
  },
  lastName: {
    name: 'last-name',
    label: 'Nachname',
    mandatory: true,
  },
  zipCode: {
    name: 'zip-code',
    label: 'PLZ',
    mandatory: true,
    placeholder: 'z. B. 50126',
  },
  city: {
    name: 'city',
    label: 'Ort',
    mandatory: true,
  },
  street: {
    name: 'street',
    label: 'Straße',
    mandatory: true,
  },
  houseNumber: {
    name: 'house-number',
    label: 'Hausnummer',
    mandatory: true,
    placeholder: 'z. B. 17a-17c',
  },
  email: {
    name: 'email',
    label: 'E-Mail-Adresse',
    mandatory: true,
  },
  countryCode: {
    name: 'country-code',
    label: 'Ländervorwahl',
    mandatory: true,
  },
  phoneNo: {
    name: 'phone-number',
    label: 'Rufnummer bei Rückfragen',
    placeholder: 'z. B. 171 1234567',
    mandatory: true,
  },
  salesroom: {
    name: 'salesroom',
    label: 'Ladenlokal vorhanden?',
    mandatory: true,
  },
  website: {
    name: 'website',
    label: 'URL zum Webauftritt',
    placeholder: 'z. B. www.example.de',
  },
};

export interface ResellerContactRequest {
  mandant?: Mandant;
  company: string;
  salutation: string;
  title: string;
  'first-name': string;
  'last-name': string;
  'zip-code': string;
  city: string;
  street: string;
  'house-number': string;
  email: string;
  'country-code': string;
  'phone-number': string;
  salesroom: string;
  website: string;
}
