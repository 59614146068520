import { LoadingSpinner } from 'dss-ui-library';
import { FC, useEffect, useState } from 'react';
import { CombinedContentType } from '../../../interfaces';
import { getContentByContentSlug } from '../../../utils';
import { pushDataLayer } from '../../../utils/dataLayer';
import CombinedContent from '../../CombinedContent';

export enum Opt {
  in = 'optIn',
  out = 'optOut',
}

export interface OptInOrOutProps {
  mode: string;
  hash: string;
  successContentSlug: string;
  errorContentSlug: string;
  doOptCall(hash: string): Promise<boolean>;
}

export const OptInOrOut: FC<OptInOrOutProps> = ({
  mode,
  hash,
  successContentSlug,
  errorContentSlug,
  doOptCall,
}) => {
  const [content, setContent] = useState<CombinedContentType>(null);

  useEffect(() => {
    if (mode !== Opt.in && mode !== Opt.out) {
      renderErrorContent();
      return;
    }

    doOptCall(hash)
      .then(() => {
        renderSuccessContent();
        pushDataLayer({
          event: 'prospect_registration',
          status: 'success',
          operation: mode,
        });
      })
      .catch(() => {
        renderErrorContent();
        pushDataLayer({
          event: 'prospect_registration',
          status: 'error',
          operation: mode,
        });
      });
  }, []);

  const renderSuccessContent = async () => {
    const { content } = await getContentByContentSlug(successContentSlug);
    setContent(content);
  };

  const renderErrorContent = async () => {
    const { content } = await getContentByContentSlug(errorContentSlug);
    setContent(content);
  };

  if (content) {
    return <CombinedContent spacerSize={0} content={[content]} />;
  }

  return <LoadingSpinner theme="blue" />;
};
