import React, { useCallback, useEffect, useState } from 'react';
import { AvailabilityCheckFormProps } from '../../../Views/Form/Form';
import { LoadingSpinner } from 'dss-ui-library';
import { pushAvailability } from '../../../../../utils/dataLayer/availability';
import { AvailabilityTypes, useAvailabilityContext } from '../../../Context';
import {
  AvailabilityStatus,
  DataLayerEvents,
} from '../../../../../interfaces/tracking';
import dynamic from 'next/dynamic';
import {
  getProducts,
  Product,
  ProductId,
  useOrder,
} from '@ncs-frontend-monorepo/order';
import {
  NetTvType,
  FetchAddressReponse,
  Portfolio,
  getMaxDownloads,
  useAvailability,
  hasCableTV,
} from '@ncs-frontend-monorepo/availability';

interface TvCheckStandaloneProps {
  resetCheck(): void;
}

enum TvCheckResultType {
  Options = 'Options',
  Cable = 'Cable',
  Internet = 'Internet',
}

export interface TVCheckResultView {
  product: Product;
  address: FetchAddressReponse;
  onResetCheck(): void;
  onHandleClick(target: string): void;
  inkassoTypeValidFrom?: string;
  isCustomer?: boolean;
  availablePortfolio?: Portfolio;
  hasTvOptions?: boolean;
}

const OptionResult = dynamic(() =>
  import('./Views/TVOptions').then(({ TVOptions }) => TVOptions),
);

const CableTVResult = dynamic(() =>
  import('./Views/CableTV').then(({ CableTV }) => CableTV),
);

const TVInternetResult = dynamic(() =>
  import('./Views/TVInternet').then(({ TVInternet }) => TVInternet),
);

export const tvCheckStandaloneWordings = (
  siteName: string,
): Pick<AvailabilityCheckFormProps, 'headline' | 'subline'> => ({
  headline: 'Verfügbarkeitscheck',
  subline: `Sicher dir dein exklusives TV-Angebot - mit oder ohne Internet-Anschluss von ${siteName}.`,
});

export const TvCheckStandalone: React.FC<TvCheckStandaloneProps> = ({
  resetCheck,
}) => {
  const { isCustomer } = useOrder();
  const { address, availability } = useAvailability();
  const { objectInformation, promotions } = availability;
  const { tvPromotions, presalePromotion, availablePromotions } = promotions;
  const { inkassoTypeValidFrom } = objectInformation;
  const { manualCheck, availablePortfolio } = useAvailabilityContext();
  const { maxDownload } = getMaxDownloads({
    availablePromotions,
    presalePromotion,
  });
  const [product, setProduct] = useState<Product>();
  const [tvCheckResultType, setTvCheckResultType] =
    useState<TvCheckResultType>();
  const { eventLinks, eventResult } = pushAvailability();

  useEffect(() => {
    let resultType: TvCheckResultType;
    let templateId: ProductId;
    switch (true) {
      case tvPromotions.includes(NetTvType.NET_TV_CABLE_OPTIONS):
        templateId = hasCableTV(tvPromotions)
          ? isCustomer
            ? ProductId.TV_CABLE_EXISTING_CUSTOMER
            : ProductId.TV_CABLE
          : ProductId.TV_CABLE_HD;
        resultType = hasCableTV(tvPromotions)
          ? TvCheckResultType.Cable
          : TvCheckResultType.Options;
        break;
      case tvPromotions.includes(NetTvType.NET_TV_CABLE):
      case tvPromotions.includes(NetTvType.NET_TV_FTTH):
        templateId = isCustomer
          ? ProductId.TV_CABLE_EXISTING_CUSTOMER
          : ProductId.TV_CABLE;
        resultType = TvCheckResultType.Cable;
        break;
      default:
        resultType = TvCheckResultType.Internet;
        templateId = ProductId.TV_INTERNET;
        break;
    }
    setTvCheckResultType(resultType);
    getProducts({ templateId }).then((productInfo) => {
      setProduct(productInfo);
    });
  }, [tvPromotions]);

  useEffect(() => {
    const presaleDescription = presalePromotion?.plannedAvailabilityDate || '';

    eventResult({
      event: DataLayerEvents.Result,
      status: AvailabilityStatus.Available,
      expansionStatus: availability?.objectInformation?.status || null,
      checkSource: AvailabilityTypes.TvContent,
      checkStatus: manualCheck ? 'nicht-geprueft' : 'bereits-geprueft',
      zipCode: address.zipCode,
      maxDownload: maxDownload,
      presaleAvailability: presaleDescription !== '',
      ...(presaleDescription !== '' && {
        plannedAvailabilityDate: presaleDescription,
      }),
      tvPromotions,
    });
  }, [
    maxDownload,
    address.zipCode,
    availability?.objectInformation?.status,
    manualCheck,
  ]);

  const handleClick = useCallback(
    (targetPage: string) => {
      eventLinks({
        event: DataLayerEvents.PlanLink,
        status: AvailabilityStatus.Available,
        zipCode: address.zipCode,
        checkSource: AvailabilityTypes.TvContent,
        maxDownload: maxDownload,
        targetPage,
      });
    },
    [address.zipCode, maxDownload],
  );

  return (
    <>
      {product ? (
        <>
          {tvCheckResultType === TvCheckResultType.Options && (
            <OptionResult
              product={product}
              onHandleClick={handleClick}
              onResetCheck={resetCheck}
              address={address}
            />
          )}
          {tvCheckResultType === TvCheckResultType.Cable && (
            <CableTVResult
              product={product}
              onHandleClick={handleClick}
              onResetCheck={resetCheck}
              address={address}
              isCustomer={isCustomer}
              inkassoTypeValidFrom={inkassoTypeValidFrom}
              availablePortfolio={availablePortfolio}
              hasTvOptions={tvPromotions.includes('NET_TV_CABLE_OPTIONS')}
            />
          )}
          {tvCheckResultType === TvCheckResultType.Internet && (
            <TVInternetResult
              product={product}
              onHandleClick={handleClick}
              onResetCheck={resetCheck}
              address={address}
              isCustomer={isCustomer}
            />
          )}
        </>
      ) : (
        <LoadingSpinner theme="blue" />
      )}
    </>
  );
};
