import { useState } from 'react';
import { BuildingOwnerForm } from './Form';
import { Formik } from 'formik';
import { BuildingOwnerFormBody, initialFormData } from './data';
import { submitBuildingOwnerForm } from './submit';
import { schema } from './schema';
import { isNC } from '@ncs-frontend-monorepo/utils';
import { useBuildingOwnerFormStore } from '../../../store/forms/buildingOwnerForm';
import { FormsWrapper } from '../FormsWrapper';

export const BuildingOwner: React.FC = () => {
  const { initialState, updateInitialState } = useBuildingOwnerFormStore(
    (state) => state,
  );
  const [hasError, setHasError] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);

  const initialValues = {
    ...initialFormData,
    ...initialState,
  };

  const handleSubmit = async (values: BuildingOwnerFormBody) => {
    updateInitialState(values);
    try {
      const response = await submitBuildingOwnerForm({
        mandant: isNC() ? 'NC' : 'NA',
        ...values,
      });
      if (response) {
        setShowSuccess(true);
      } else {
        setHasError(true);
      }
    } catch (error) {
      setHasError(true);
    }
    setHasError(true);
  };

  return (
    <FormsWrapper
      isSubmitted={showSuccess}
      contentBlock="buildingowner-success"
      e2e="buildingowner-success"
    >
      <Formik
        initialValues={initialValues}
        enableReinitialize
        validationSchema={schema}
        onSubmit={handleSubmit}
        validateOnChange={false}
      >
        <BuildingOwnerForm hasError={hasError} />
      </Formik>
    </FormsWrapper>
  );
};
