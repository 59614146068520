import { AvailabilityForm, FormFieldValues } from '../Views/Form';
import { Grid, LoadingSpinner } from 'dss-ui-library';
import styles from './Embedded.module.scss';
import cx from 'classnames';
import { LayoutType } from '../Availability';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { AvailabilityError } from '../Views/Error';
import { AvailabilityCheckFormProps } from '../Views/Form/Form';
import { AvailabilityOptionSelect } from '../Views/OptionSelect';
import { AvailabilityProvider, AvailabilityTypes } from '../Context';
import {
  AvailabilityStatus,
  DataLayerEvents,
} from '../../../interfaces/tracking';
import { pushAvailability } from '../../../utils/dataLayer/availability';
import { ResultSelector, ResultSelectorType } from './Results';
import {
  TvCheckStandalone,
  tvCheckStandaloneWordings,
} from './Results/TV/TvCheckStandalone';
import { checkMandantType, getEnv } from '@ncs-frontend-monorepo/utils';
import { ProductId, useReset } from '@ncs-frontend-monorepo/order';
import {
  MandantMismatch,
  Portfolio,
  removeAddressParams,
  useAvailability,
} from '@ncs-frontend-monorepo/availability';
import { useYoungStore } from '../../../store/youngStore';

export enum EmbeddedTypes {
  NETSPEED_CHECK = 'netspeedCheck',
  TV_CHECK_STANDALONE = 'tvCheckStandalone',
}

export type GeneralParams = {
  fullWidth: boolean;
  portfolio?: Portfolio;
  viewType?: ResultSelectorType;
  isYoungChecked?: string;
};

export interface AvailabilityEmbeddedProps {
  type: EmbeddedTypes;
  params: GeneralParams;
  isSmall?: boolean;
  templateId?: ProductId;
}

export enum ViewType {
  LOADING = 'loading',
  FORM = 'form',
  RESULT = 'result',
  MISMATCH = 'mismatch',
  ERROR = 'error',
}

export type AvailabilityWordings = Pick<
  AvailabilityCheckFormProps,
  'headline' | 'subline'
>;

const netspeedCheckWordings: AvailabilityWordings = {
  headline: 'Verfügbarkeitscheck',
  subline: 'Jetzt verfügbare Produkte prüfen.',
};

export const AvailabilityEmbedded: React.FC<AvailabilityEmbeddedProps> = ({
  type,
  params,
  isSmall = false,
  templateId,
}) => {
  const portfolio =
    params?.viewType === 'standalone' ? null : params?.portfolio ?? 'FIBER';
  const { eventCheck, eventChangeAddress, eventResult, eventLinks } =
    pushAvailability();
  const { resetAll } = useReset();
  const { address, availability, handleAvailabilityCheck, hasAvailability } =
    useAvailability();
  const [wording, setWording] = useState<AvailabilityWordings>();
  const [view, setView] = useState<ViewType>(ViewType.LOADING);
  const [manualChecked, setManualChecked] = useState(false);
  const mandantType = checkMandantType(getEnv().SITE, address?.mandant);
  const embeddedCheckRef = useRef<HTMLElement>(null);

  const availabilityStatus = availability?.promotions.presalePromotion
    ? availability.maxDownload > 0
      ? AvailabilityStatus.AvailablePresale
      : AvailabilityStatus.PresaleOnly
    : AvailabilityStatus.Available;

  const checkSource =
    type === EmbeddedTypes.TV_CHECK_STANDALONE
      ? AvailabilityTypes.TvContent
      : AvailabilityTypes.NetspeedContent;

  // this part is used for SMAC param
  const isYoungChecked = params?.isYoungChecked;
  const setIsYoungChecked = useYoungStore((state) => state.setIsYoungChecked);

  useEffect(() => {
    setIsYoungChecked(isYoungChecked);
  }, [isYoungChecked, setIsYoungChecked]);

  const handleCheck = async (address: FormFieldValues, manualCheck = false) => {
    setManualChecked(manualCheck);
    await handleAvailabilityCheck({
      address,
      loggingInfo: {
        manualChecked,
      },
    });

    if (manualCheck) {
      eventCheck({
        event: isSmall ? DataLayerEvents.CheckSmall : DataLayerEvents.Check,
        checkSource: isSmall ? AvailabilityTypes.SmallGlobal : checkSource,
        portfolio: isSmall ? portfolio : null,
        zipCode: address.zipCode,
      });
    }
  };

  const resetCheck = () => {
    eventChangeAddress({
      event: DataLayerEvents.ChangeAddress,
      status:
        availability.promotions.tvPromotions?.length > 0
          ? AvailabilityStatus.Available
          : AvailabilityStatus.NotAvailable,
      checkSource,
      zipCode: address?.zipCode,
      maxDownload: availability.maxDownload,
      expansionStatus: availability?.objectInformation?.status,
    });
    removeAddressParams();
    setView(ViewType.FORM);
    resetAll();
  };

  useEffect(() => {
    switch (type) {
      case EmbeddedTypes.TV_CHECK_STANDALONE:
        setWording(tvCheckStandaloneWordings(getEnv().SITE));
        break;
      case EmbeddedTypes.NETSPEED_CHECK:
        setWording(netspeedCheckWordings);
        break;
      default:
        setWording(null);
        break;
    }
  }, []);

  useEffect(() => {
    const hasNoAvailability =
      !availability.maxDownload &&
      !availability.ftthPresalesInformation?.isOffered &&
      !availability.isChecked &&
      !availability.planned;

    // no address
    if (!address) {
      setView(ViewType.FORM);
      return;
    }

    // address exists but no availability
    if (hasNoAvailability) {
      setView(ViewType.FORM);
      return;
    }

    // address has already been checked outside
    if (
      (availability.maxDownload ||
        availability.ftthPresalesInformation?.isOffered ||
        availability.planned) &&
      !availability.isChecked
    ) {
      handleCheck(address);
      return;
    }

    // handle mandant mismatch
    if (!hasNoAvailability && !isSmall && address?.mandant) {
      if (mandantType === null) {
        setView(ViewType.ERROR);
        return;
      }
      if (mandantType !== 'match') {
        setView(ViewType.MISMATCH);
        return;
      }
    }
  }, [
    address,
    availability.maxDownload,
    availability.ftthPresalesInformation,
    availability.objectInformation?.inkassoTypeValidFrom,
    availability.isChecked,
    availability.planned,
    type,
    isSmall,
    mandantType,
  ]);

  useEffect(() => {
    if (availability.isChecked && !hasAvailability) {
      setView(ViewType.ERROR);
      return;
    }

    if (availability.isChecked && hasAvailability) {
      switch (type) {
        case EmbeddedTypes.TV_CHECK_STANDALONE:
          return setView(ViewType.RESULT);
        case EmbeddedTypes.NETSPEED_CHECK:
          return setView(ViewType.RESULT);
        default:
          return setView(ViewType.FORM);
      }
    }
  }, [availability?.isChecked, availability?.promotions, hasAvailability]);

  const pushResultEvent = useCallback(() => {
    if (view === ViewType.RESULT && isSmall && manualChecked) {
      eventResult({
        event:
          mandantType !== 'match'
            ? DataLayerEvents.ResultMandantMismatch
            : DataLayerEvents.ResultSmall,
        status: AvailabilityStatus.Available,
        zipCode: address?.zipCode,
        maxDownload: availability.maxDownload,
        checkStatus: 'bereits-geprueft',
        portfolio,
        presaleAvailability: !!availability.planned,
        ...(availability.planned && {
          plannedAvailabilityDate:
            availability.planned.plannedAvailabilityDateDescription,
        }),
        checkSource: AvailabilityTypes.SmallGlobal,
        ...(availability.promotions.tvPromotions && {
          tvPromotions: availability.promotions.tvPromotions,
        }),
        wholebuy: availability?.objectInformation?.wholebuy || null,
      });
    }
  }, [
    view,
    isSmall,
    manualChecked,
    availability.maxDownload,
    address,
    portfolio,
    availability.promotions.tvPromotions,
    availability.planned,
    mandantType,
  ]);

  useEffect(() => {
    // push data layer event after small availability check
    pushResultEvent();
  }, [pushResultEvent]);

  const layoutType = useMemo(() => {
    switch (true) {
      case isSmall:
        return LayoutType.Small;
      case type === EmbeddedTypes.TV_CHECK_STANDALONE:
        return LayoutType.EmbeddedTvStandalone;
      default:
        return LayoutType.EmbeddedNetSpeed;
    }
  }, [isSmall, type]);

  return (
    <AvailabilityProvider
      availabilityType={
        type === EmbeddedTypes.TV_CHECK_STANDALONE
          ? AvailabilityTypes.TvContent
          : AvailabilityTypes.NetspeedContent
      }
      manualCheck={manualChecked}
      templateId={templateId}
      availablePortfolio={availability.portfolio}
    >
      <section
        ref={embeddedCheckRef}
        className={cx({ [styles.embedded]: params?.fullWidth || false })}
      >
        <Grid>
          <Grid.Row
            className={cx({ [styles.embedded]: !params?.fullWidth || false })}
          >
            <Grid.Column className={cx({ [styles.content]: !isSmall })}>
              {view === ViewType.LOADING && <LoadingSpinner theme="blue" />}
              {view === ViewType.FORM && (
                <AvailabilityForm
                  onSubmit={handleCheck}
                  headline={wording.headline}
                  subline={wording.subline}
                  layoutType={layoutType}
                />
              )}
              {view === ViewType.MISMATCH && (
                <MandantMismatch
                  address={address}
                  resetAddress={resetCheck}
                  mandantType={mandantType}
                  onRedirect={({ targetUrl }) => {
                    eventLinks({
                      event: DataLayerEvents.MandantMismatchLink,
                      status: availabilityStatus,
                      zipCode: address.zipCode,
                      checkSource: AvailabilityTypes.NetspeedContent,
                      maxDownload: availability.maxDownload,
                      targetPage: targetUrl,
                    });
                  }}
                  fallback={
                    <AvailabilityError
                      address={address}
                      onChangeAddressClick={resetCheck}
                    />
                  }
                />
              )}
              {view === ViewType.RESULT && (
                <>
                  {isSmall ? (
                    <ResultSelector
                      isManualChecked={manualChecked}
                      availabilityList={availability.list}
                      address={address}
                      onChangeAddressClick={resetCheck}
                      portfolio={portfolio}
                      viewType={params?.viewType}
                      availabilityStatus={availabilityStatus}
                      maxAvailableDownload={availability.maxDownload}
                    />
                  ) : (
                    <>
                      {type === EmbeddedTypes.TV_CHECK_STANDALONE && (
                        <TvCheckStandalone resetCheck={resetCheck} />
                      )}
                      {type === EmbeddedTypes.NETSPEED_CHECK && (
                        <AvailabilityOptionSelect
                          onChangeAddressClick={resetCheck}
                          isEmbedded
                        />
                      )}
                    </>
                  )}
                </>
              )}
              {view === ViewType.ERROR && address && (
                <>
                  {isSmall ? (
                    <ResultSelector
                      isManualChecked={manualChecked}
                      availabilityList={availability.list}
                      address={address}
                      onChangeAddressClick={resetCheck}
                      portfolio={portfolio}
                      availabilityStatus={availabilityStatus}
                      maxAvailableDownload={availability.maxDownload}
                    />
                  ) : (
                    <AvailabilityError
                      address={address}
                      onChangeAddressClick={resetCheck}
                    />
                  )}
                </>
              )}
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </section>
    </AvailabilityProvider>
  );
};
