import {
  ContactData,
  contactFields,
  contactFieldsInitialData,
  FormField,
  bankFieldsInitialData,
  bankFields,
  BankData,
  ContactDataModel,
  BankDataModel,
} from '@ncs-frontend-monorepo/forms';

export type AssociationData = {
  associationName: FormField;
  taxNumber: FormField;
  isValueAddedTax: FormField;
};

export type AssociationFormBody = Required<
  AssociationModel & ContactDataModel & BankDataModel
>;
type AssociationForm = Required<AssociationData & ContactData & BankData>;

export const associationForm: AssociationForm = {
  ...contactFields,
  ...bankFields,
  associationName: {
    label: 'Vereinsname',
    name: 'associationName',
    mandatory: true,
  },
  taxNumber: {
    label: 'Steuernummer',
    name: 'taxNumber',
    mandatory: true,
  },
  isValueAddedTax: {
    label: 'Separat ausgewiesene Umsatzsteuer nach §2 Abs.1 UStG ',
    name: 'isValueAddedTax',
    mandatory: true,
  },
} as const;

type AssociationModel = {
  associationName: string;
  taxNumber: string;
  isValueAddedTax: 'yes' | 'no' | '';
};

export const initialFormData: AssociationFormBody = {
  ...contactFieldsInitialData,
  ...bankFieldsInitialData,
  associationName: '',
  taxNumber: '',
  isValueAddedTax: '',
};
