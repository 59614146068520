import { fetcher, getEnv } from '@ncs-frontend-monorepo/utils';

export async function activateSmartcard(smartcardNumber: string) {
  try {
    const formData = new FormData();
    formData.append('action', 'm7sms');
    formData.append('number', smartcardNumber);

    const smartcardResponse = await fetcher(
      getEnv().PK.SMARTCARD_ACTIVATION_URL,
      {
        method: 'POST',
        body: formData,
      },
    );

    return !!smartcardResponse.success;
  } catch (e) {
    return false;
  }
}
