import cx from 'classnames';
import { Button, Grid, IconAttention, Text } from 'dss-ui-library';
import { TextLink } from '@ncs-frontend-monorepo/content-library';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';
import { DataLayerEvents } from '../../../interfaces/tracking';
import { pushDataLayer } from '../../../utils/dataLayer';
import { toCamelCase } from '../../../utils/helpers';
import { buildPlanDetailPageUrl } from '../../../utils/plan';
import { AvailabilityTypes } from '../../AvailabilityCheck/Context';
import styles from './NotAvailableInfo.module.scss';
import {
  ProductId,
  TemplateGroup,
  ProductModifiers,
  isDisabledCableTVGroup,
} from '@ncs-frontend-monorepo/order';
import {
  AvailabilityStatus,
  ExpansionStatus,
  Portfolio,
  useAvailability,
} from '@ncs-frontend-monorepo/availability';
import { BUILDINGOWNER_CONTACT_URL } from '../../../constants/urls';

interface NotAvailableInfoProps {
  portfolioMismatch: boolean;
  availablePortfolio: Portfolio;
  templateId: ProductId;
  availabilityStatus: AvailabilityStatus;
  preSalesStatus?: ExpansionStatus;
  landingPage?: string;
  planBandwidth?: number;
  planGroup?: TemplateGroup;
  modifier?: ProductModifiers;
  isFallback?: boolean;
  onChangeClick?: () => void;
}

const expansionStateProps = {
  [ExpansionStatus.AREA_PLANNED]: {
    text: 'Wir prüfen den Glasfaserausbau.',
    urlText: 'Jetzt informieren',
  },
  [ExpansionStatus.PRE_MARKETING]: {
    text: 'Wir prüfen den Glasfaserausbau.',
    urlText: 'Informieren & vorbestellen',
  },
  [ExpansionStatus.UNDER_CONSTRUCTION]: {
    text: 'Wir bauen Glasfaser aus.',
    urlText: 'Informieren & vorbestellen',
  },
};

export const NotAvailableInfo: React.FC<NotAvailableInfoProps> = ({
  availabilityStatus,
  preSalesStatus,
  landingPage,
  planBandwidth,
  portfolioMismatch,
  availablePortfolio,
  templateId,
  planGroup,
  isFallback = false,
  modifier = ProductModifiers.withoutAll,
  onChangeClick,
}) => {
  const { push } = useRouter();
  const { availability } = useAvailability();
  const [planDetailUrl, setPlanDetailUrl] = useState<string>();
  const e2eStatus = availabilityStatus
    .toString()
    .toLowerCase()
    .replace(/_([a-z])/g, (m, p1) => p1.toUpperCase());

  useEffect(() => {
    setPlanDetailUrl(
      buildPlanDetailPageUrl(
        planBandwidth,
        templateId,
        availablePortfolio,
        modifier,
      ),
    );
  }, [
    availability.promotions.order,
    planBandwidth,
    templateId,
    modifier,
    availablePortfolio,
  ]);

  const handleButtonClick = (target: string) => {
    pushDataLayer({
      event: DataLayerEvents.PortfolioSwitch,
      checkSource: AvailabilityTypes.NetspeedPlan,
      requestedDownload: planBandwidth,
      templateId: templateId,
    });

    push(target);
  };

  if (isFallback) {
    return (
      <div className={cx(styles.wrapper, styles.borderTop)}>
        <Text appearance="t7" className={styles.text}>
          Die Verfügbarkeitsprüfung war nicht möglich.{' '}
        </Text>
        <TextLink
          appearance="t7"
          url={BUILDINGOWNER_CONTACT_URL}
          e2e={`availability-fallback`}
        >
          Kontakt aufnehmen
        </TextLink>
      </div>
    );
  }

  switch (availabilityStatus) {
    // AVAILABLE & REALIZABLE
    case AvailabilityStatus.AVAILABLE:
    case AvailabilityStatus.AVAILABLE_INSTALL_GFAST:
    case AvailabilityStatus.REALIZABLE:
    case AvailabilityStatus.REALIZABLE_INSTALL_GFAST:
      return (
        <>
          <Grid.Row collapsed>
            <Grid.Column xs={1} center marginBottom={1}>
              <IconAttention color="blue" height={16} width={16} />
            </Grid.Column>
            <Grid.Column xs={11} marginBottom={1}>
              <Text appearance="t7">Du surfst bereits mit Glasfaser.</Text>
            </Grid.Column>
          </Grid.Row>
          <Button
            e2e={`availability-${e2eStatus}-${planBandwidth}`}
            onClick={() => handleButtonClick(planDetailUrl)}
            variant="primary"
            color="blue"
            fullWidth
          >
            Zum verfügbaren Tarif
          </Button>
        </>
      );
    // PRESALE
    case AvailabilityStatus.PRESALE:
      return (
        <>
          <Grid.Row collapsed>
            <Grid.Column xs={1} center marginBottom={1}>
              <IconAttention color="blue" height={16} width={16} />
            </Grid.Column>
            <Grid.Column xs={11} marginBottom={1}>
              <Text appearance="t7">Du surfst demnächst mit Glasfaser.</Text>
            </Grid.Column>
          </Grid.Row>
          <Button
            e2e={`availability-${e2eStatus}-${planBandwidth}`}
            onClick={() => handleButtonClick(planDetailUrl)}
            variant="primary"
            color="blue"
            fullWidth
          >
            Zur Tarifvorbestellung
          </Button>
        </>
      );
    // NOT AVAILABLE
    case AvailabilityStatus.NOT_AVAILABLE: {
      const isDisabledTV =
        isDisabledCableTVGroup({
          templateGroup: planGroup,
          availableTv: availability.promotions.tvPromotions,
        }) && planBandwidth <= availability.maxDownload;

      if (portfolioMismatch || isDisabledTV) {
        return (
          <>
            <Grid.Row collapsed>
              <Grid.Column xs={1} center marginBottom={1}>
                <IconAttention color="blue" height={16} width={16} />
              </Grid.Column>
              <Grid.Column xs={11} marginBottom={1}>
                <Text appearance="t7">
                  {isDisabledTV
                    ? 'Bei dir ist NetTV über Internet verfügbar'
                    : availablePortfolio === 'CLASSIC'
                    ? 'Du surfst mit DSL/Kabel.'
                    : 'Du surfst mit Glasfaser.'}
                </Text>
              </Grid.Column>
            </Grid.Row>
            <Button
              e2e={`availability-${e2eStatus}-${planBandwidth}`}
              onClick={() => handleButtonClick(planDetailUrl)}
              variant="primary"
              color="blue"
              fullWidth
            >
              Zum verfügbaren Tarif
            </Button>
          </>
        );
      } else if (expansionStateProps[preSalesStatus]) {
        const { text, urlText } = expansionStateProps[preSalesStatus];

        return (
          <>
            <Grid.Row collapsed>
              <Grid.Column xs={1} center marginBottom={1}>
                <IconAttention color="blue" height={16} width={16} />
              </Grid.Column>
              <Grid.Column xs={11} marginBottom={1}>
                <Text appearance="t7">{text}</Text>
              </Grid.Column>
            </Grid.Row>
            <Button
              e2e={`availability-${e2eStatus}-${
                preSalesStatus ? `${toCamelCase(preSalesStatus)}-` : ''
              }${planBandwidth}`}
              onClick={() => handleButtonClick(landingPage)}
              variant="primary"
              color="blue"
              fullWidth
            >
              {urlText}
            </Button>
          </>
        );
      } else {
        return (
          <div className={cx(styles.wrapper, styles.borderTop)}>
            <Text appearance="t7" className={styles.text}>
              Leider nicht an deiner Adresse verfügbar.{' '}
            </Text>
            <TextLink
              appearance="t7"
              url={null}
              onClick={onChangeClick}
              e2e={`availability-${e2eStatus}-${
                preSalesStatus ? `${toCamelCase(preSalesStatus)}-` : ''
              }${planBandwidth}`}
            >
              Adresse ändern
            </TextLink>
          </div>
        );
      }
    }
  }
};
