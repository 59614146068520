import React, { useEffect } from 'react';
import { Grid, RadioGroup, Spacer, Text, Tooltip } from 'dss-ui-library';
import { FormikFields } from '../../../FormikFields';
import { cancellationFormModel } from '../cancellationFormModel';
import getDay from 'date-fns/getDay';
import { useFormikContext } from 'formik';

export enum DateDecision {
  select = 'select',
  earliest = 'earliest',
}

export const Date: React.FC = () => {
  const { values, setFieldValue } = useFormikContext();
  const filterDate = (date: Date) => ![0, 6].includes(getDay(date));
  const dateSelection =
    values[cancellationFormModel.cancellationDateSelection.name];

  useEffect(() => {
    if (dateSelection === DateDecision.earliest) {
      setFieldValue(cancellationFormModel.cancellationDate.name, '');
    }
  }, [dateSelection]);

  return (
    <>
      <Grid.Row>
        <Grid.Column s={10} m={8}>
          <Spacer y={3} block>
            <Text appearance="t2_2" element="h4">
              Mein Kündigungswunschtermin *
              <Spacer l={1}>
                <Tooltip>
                  <Text appearance="t4">
                    Bitte beachte, dass wir Kündigungen aus technischen Gründen
                    nicht zu Samstagen, Sonntagen und Feiertagen ausführen
                    können. Bitte wähle dann den nächsten Werktag.
                  </Text>
                </Tooltip>
              </Spacer>
            </Text>
          </Spacer>
          <RadioGroup e2e="cancel-date">
            <FormikFields.Radio
              name={cancellationFormModel.cancellationDateSelection.name}
              label="Schnellstmöglich"
              e2e={cancellationFormModel.cancellationDateSelection.name}
              value={DateDecision.earliest}
            />
            <Spacer y={1} block />
            <FormikFields.Radio
              name={cancellationFormModel.cancellationDateSelection.name}
              label="Zu einem bestimmten Termin kündigen"
              e2e={cancellationFormModel.cancellationDateSelection.name}
              value={DateDecision.select}
            />
          </RadioGroup>
        </Grid.Column>
      </Grid.Row>
      {dateSelection === DateDecision.select && (
        <Grid.Row>
          <Grid.Column s={5} m={4}>
            <Spacer t={1} block />
            <FormikFields.Datepicker
              name={cancellationFormModel.cancellationDate.name}
              label={cancellationFormModel.cancellationDate.label}
              e2e={`datepicker-${cancellationFormModel.cancellationDate.name}`}
              filterDate={filterDate}
              isRequired
            />
          </Grid.Column>
        </Grid.Row>
      )}
    </>
  );
};
