import { cancellationFormModel } from './cancellationFormModel';

export const initialData = {
  [cancellationFormModel.issue.name]: '',
  [cancellationFormModel.message.name]: '',
  [cancellationFormModel.cancelSelection.name]: '',
  [cancellationFormModel.cancelContracts.name]: [],
  [cancellationFormModel.installationPhoneNumber.name]: '',
  [cancellationFormModel.cancellationDateSelection.name]: '',
  [cancellationFormModel.cancellationDate.name]: '',
  [cancellationFormModel.customerNumber.name]: '',
  [cancellationFormModel.salutation.name]: '',
  [cancellationFormModel.title.name]: '',
  [cancellationFormModel.firstName.name]: '',
  [cancellationFormModel.lastName.name]: '',
  [cancellationFormModel.zipCode.name]: '',
  [cancellationFormModel.city.name]: '',
  [cancellationFormModel.street.name]: '',
  [cancellationFormModel.houseNumber.name]: '',
  [cancellationFormModel.email.name]: '',
  [cancellationFormModel.areaCode.name]: '49',
  [cancellationFormModel.phoneNo.name]: '',
};
