import { Background, Grid, Text } from 'dss-ui-library';

export const Success: React.FC = () => (
  <Background color="blue">
    <Grid>
      <Text appearance="t1_2" color="white" e2e="success-message">
        Das Freischaltsignal wurde erfolgreich gesendet.
      </Text>
    </Grid>
  </Background>
);
