import { formModel } from './leadContactFormModel';

export const initialData = {
  [formModel.salutation.name]: '',
  [formModel.title.name]: '',
  [formModel.firstName.name]: '',
  [formModel.lastName.name]: '',
  [formModel.customerNumber.name]: '',
  [formModel.street.name]: '',
  [formModel.houseNumber.name]: '',
  [formModel.zipCode.name]: '',
  [formModel.city.name]: '',
  [formModel.email.name]: '',
  [formModel.countryCode.name]: '49',
  [formModel.phoneNumber.name]: '',
  [formModel.agreedToDataUsage.name]: false,
};
