import { Grid, RichText, Spacer, Text } from 'dss-ui-library';
import { AvailabilityResponseModalProps } from './types';
import { IconTextBox } from './Elements/IconTextBox';
import { ButtonLink } from '@ncs-frontend-monorepo/content-library';
import { ButtonRowBottom } from './Elements/ButtonRowBottom';
import { getPortfolioUrl } from '../../../../../utils/plan';
import {
  ModalContentWrapper,
  Portfolio,
  useAvailability,
} from '@ncs-frontend-monorepo/availability';
import { replaceDynamicText } from '@ncs-frontend-monorepo/utils';
import { useAvailabilityContext } from '../../../Context';

interface ModalContentAvailableProps extends AvailabilityResponseModalProps {
  portfolio: Portfolio;
}

export const ModalContentAvailable: React.FC<ModalContentAvailableProps> = ({
  portfolio,
  content,
  address,
  handleAddressReset,
}) => {
  const {
    availability: { maxDownload },
  } = useAvailability();
  const { templateId } = useAvailabilityContext();

  const url = content?.buttonLink?.url
    ? content.buttonLink.url
    : getPortfolioUrl({
        portfolio,
        templateId,
      });

  return (
    <ModalContentWrapper
      title="Verfügbare Produkte an deiner Adresse"
      address={address}
      resetAddress={handleAddressReset}
    >
      <Grid fullWidth>
        <Grid.Column>
          {content.modalText1 && (
            <>
              <IconTextBox
                icon={portfolio === 'FIBER' ? 'rocket' : 'wlan'}
                text={replaceDynamicText(content.modalText1, {
                  maxAvailableDownload: maxDownload,
                })}
              />
              <Spacer t={3} block />
            </>
          )}
          <Text appearance="t2_2" color="blue">
            {content.headline}
          </Text>
          <Spacer t={1} block>
            <RichText text={content.modalText2} />
          </Spacer>
        </Grid.Column>
        <ButtonRowBottom
          submitButton={
            <ButtonLink btnFullwidth link={{ url }}>
              {portfolio === 'FIBER'
                ? 'Zu den Glasfaser-Tarifen'
                : 'Zu den DSL/Kabel-Tarifen'}
            </ButtonLink>
          }
        />
      </Grid>
    </ModalContentWrapper>
  );
};
