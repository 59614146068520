import * as Yup from 'yup';
import {
  addressValidation,
  emailValidation,
  ftthCustomerNoValidation,
  generalFieldValidation,
  nameValidation,
  requiredDropDownText,
  requiredText,
} from '@ncs-frontend-monorepo/forms';
import { formModel } from './leadContactFormModel';

export const LeadContactSchema = Yup.object().shape({
  [formModel.salutation.name]: Yup.string().required(requiredDropDownText),
  [formModel.title.name]: Yup.string(),
  [formModel.firstName.name]: nameValidation,
  [formModel.lastName.name]: nameValidation,
  [formModel.customerNumber.name]: ftthCustomerNoValidation,
  [formModel.street.name]: Yup.string()
    .concat(addressValidation.street)
    .required(requiredText),
  [formModel.houseNumber.name]: Yup.string()
    .concat(addressValidation.houseNumber)
    .required(requiredText),
  [formModel.zipCode.name]: Yup.string()
    .concat(addressValidation.zipCode)
    .required(requiredText),
  [formModel.city.name]: Yup.string()
    .concat(addressValidation.city)
    .required(requiredText),
  [formModel.email.name]: emailValidation.required(requiredText),
  [formModel.countryCode.name]: Yup.string().when(
    [formModel.phoneNumber.name],
    {
      is: (phoneNumber) => !!phoneNumber,
      then: (schema) => schema.required(requiredDropDownText),
    },
  ),
  [formModel.phoneNumber.name]: Yup.string().concat(
    generalFieldValidation.phoneLong,
  ),
  [formModel.agreedToDataUsage.name]: Yup.bool()
    .oneOf([true], 'Bitte stimme der Nutzung deiner Daten zu')
    .required('Bitte stimme der Nutzung deiner Daten zu'),
});
