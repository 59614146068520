import { fileUploadHelper } from '../../../utils/fileUploadHelper';
import { FormikFields } from '../../FormikFields';
import { orderServiceURL } from '../../../utils/orderAPI';
interface BuildingOwnerFileUploadProps {
  name: string;
}

export default function BuildingOwnerFileUpload({
  name,
}: BuildingOwnerFileUploadProps): JSX.Element {
  const { handleUpload, handleDelete } = fileUploadHelper(
    `${orderServiceURL()}/support/upload/file`,
  );

  return (
    <FormikFields.FileUpload
      name={name}
      allowedMimes=".jpeg,.jpg,.pdf,.png"
      e2e="buildingowner-upload"
      maxFiles={5}
      maxMBSize={10}
      maxMBTotal={30}
      maxFilesError="Die Gesamtanzahl der maximalen Dateigrößen wurde erreicht."
      disabledText="Die maximale Anzahl an Dokumentenuploads wurde erreicht."
      fileNotSupportedError="Bitte prüfe Dateigröße oder -format."
      fileTooBigError="Bitte prüfe Dateigröße oder -format."
      flashTimeout={4000}
      subtitle="Unterstützte Formate: jpeg, jpg, pdf, png, max. 10 MB, insg. max. 30 MB und max. 5 Dateien"
      onFileDelete={handleDelete}
      onFileUpload={handleUpload}
    />
  );
}
