import React from 'react';
import { Grid, Spacer, Text } from 'dss-ui-library';
import { Selection } from '../Elements/Selection';
import { OnUpdate, TariffAdvisorStepId } from '../index';
import styles from '../TariffAdvisor.module.scss';

export enum DeviceSelection {
  VoiceAssistant = 'voice-assistant',
  Smarthome = 'smarthome',
  Smartphone = 'smartphone',
  Streaming = 'streaming',
  Tablet = 'tablet',
  Computer = 'computer',
  Gaming = 'gaming',
}

export const deviceSelection = [
  {
    text: 'Sprachassistenten (z. B. Alexa)',
    value: DeviceSelection.VoiceAssistant,
    e2e: DeviceSelection.VoiceAssistant,
    points: 1,
  },
  {
    text: 'Smart Home (z. B. Saugroboter, smarte Steckdosen & Heizungsthermostate)',
    value: DeviceSelection.Smarthome,
    e2e: DeviceSelection.Smarthome,
    points: 2,
  },
  {
    text: 'Smartphone',
    value: DeviceSelection.Smartphone,
    e2e: DeviceSelection.Smartphone,
    points: 2,
  },
  {
    text: 'Smart TV/Streaming-Boxen (z. B. Apple TV, Fire TV o.ä.)',
    value: DeviceSelection.Streaming,
    e2e: DeviceSelection.Streaming,
    points: 4,
  },
  {
    text: 'Tablet',
    value: DeviceSelection.Tablet,
    e2e: DeviceSelection.Tablet,
    points: 2,
  },
  {
    text: 'Computer/Laptop',
    value: DeviceSelection.Computer,
    e2e: DeviceSelection.Computer,
    points: 3,
  },
  {
    text: 'Spielekonsole',
    value: DeviceSelection.Gaming,
    e2e: DeviceSelection.Gaming,
    points: 4,
  },
];

export interface DevicesValue {
  [TariffAdvisorStepId.Devices]: DeviceSelection[];
}

export type DevicesProps = DevicesValue & OnUpdate;

export const Devices: React.FC<DevicesProps> = ({ devices, handleChange }) => {
  const handleSelection = (device: DeviceSelection) => {
    handleChange({
      devices: devices.includes(device)
        ? devices.filter((item) => item !== device)
        : [...devices, ...[device]],
    });
  };

  return (
    <div
      className={styles.stepContainer}
      data-e2e="tariff-advisor-step-devices"
    >
      <Text appearance="t2_2">
        Welche Geräte sind bei dir zuhause mit dem Internet verbunden?{' '}
      </Text>
      <Text appearance="t3">(Mehrfachauswahl möglich)</Text>
      <Spacer b={2} block />
      <Grid fullWidth>
        <Grid.Row>
          {deviceSelection.map((device, ix) => (
            <Grid.Column s={6} key={`${ix}-${device.value}`}>
              <Selection
                isSelected={devices?.includes(device.value)}
                onChange={() => {
                  handleSelection(device.value);
                }}
                name={device.e2e}
                multiSelect={true}
                {...device}
              />
            </Grid.Column>
          ))}
        </Grid.Row>
      </Grid>
    </div>
  );
};
