import React from 'react';
import { Button, ErrorMessage, Grid, Spacer, Text } from 'dss-ui-library';
import { FormikFields } from '../../FormikFields';
import {
  cancellationFormModel,
  issueOptions,
  IssueValues,
} from './cancellationFormModel';
import { useFormikContext, Form } from 'formik';
import { Detail } from './FormElements/Detail';
import { Contract } from './FormElements/Contract';
import { Date } from './FormElements/Date';
import { Personal } from './FormElements/Personal';
import { ErrorFocus } from '../../ErrorFocus';
import { RequiredInfo } from '@ncs-frontend-monorepo/forms';

interface FormWrapperProps {
  hasError: boolean;
}

export const FormWrapper: React.FC<FormWrapperProps> = ({ hasError }) => {
  const { values, isSubmitting } = useFormikContext();
  return (
    <Form>
      <Grid>
        <Grid.Row>
          <Grid.Column s={10} m={8}>
            <Spacer y={3} block>
              <Text appearance="t2_2" element="h4">
                Worum geht es genau?
              </Text>
            </Spacer>
            <FormikFields.Dropdown
              e2e={cancellationFormModel.issue.name}
              name={cancellationFormModel.issue.name}
              label={cancellationFormModel.issue.label}
              options={issueOptions}
              placeholder={cancellationFormModel.issue.placeholder}
              isRequired
            ></FormikFields.Dropdown>
          </Grid.Column>
        </Grid.Row>
        {values[cancellationFormModel.issue.name] !== '' && (
          <>
            {values[cancellationFormModel.issue.name] ===
              IssueValues.Exceeding && <Detail />}
          </>
        )}
        <Contract />
        <Date />
        <Personal />
        <Spacer y={2} block>
          <RequiredInfo />
        </Spacer>
        <ErrorFocus />

        {hasError && (
          <Grid.Row>
            <Grid.Column s={10} m={8}>
              <Spacer y={2} block>
                <ErrorMessage e2e="cancellation-form-submit-error">
                  Es ist ein Fehler aufgetreten. Dein Kündigungswunsch konnte
                  nicht übermittelt werden. Bitte versuche es zu einem späteren
                  Zeitpunkt noch mal.
                </ErrorMessage>
              </Spacer>
            </Grid.Column>
          </Grid.Row>
        )}

        <Button
          variant="primary"
          color="red"
          e2e="cancellation-form-submit"
          disabled={isSubmitting}
          type="submit"
        >
          Jetzt kündigen
        </Button>
      </Grid>
    </Form>
  );
};
