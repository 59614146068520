import React from 'react';
import { AvailableResult } from './Available';
import { LoadingSpinner } from 'dss-ui-library';
import {
  AvailabilityType,
  ContentKey,
  availabilityType,
  createContentKey,
} from '../utils';
import { useAvailabilityContext } from '../../Context';
import { NotAvailableResult } from './NotAvailable';
import { ADSLResult } from './ADSL';
import { PresaleResult } from './Presale';
import { Link } from '../../../../interfaces';
import { FTTHResult } from './FTTH';
import { useFetchContent } from '../../../../hooks/useFetchContent';
import { getPortfolioUrl } from '../../../../utils/plan';
import { useRouter } from 'next/router';
import {
  checkMandantType,
  getEnv,
  useScrollTo,
} from '@ncs-frontend-monorepo/utils';
import { AddressSelection } from '../../../../components/OrderContext';
import { AvailabilityStatus } from '../../../../interfaces/tracking';
import dynamic from 'next/dynamic';
import {
  Portfolio,
  useAvailability,
} from '@ncs-frontend-monorepo/availability';

const MandantMismatchResult = dynamic(
  () =>
    import('./MandantMismatchResult').then(
      ({ MandantMismatchResult }) => MandantMismatchResult,
    ),
  { ssr: false },
);

export interface ResultContent {
  headline: string;
  modalText1?: string;
  modalText2?: string;
  text?: string;
  buttonLink?: Link;
  kundenweltLink?: Link;
}

interface ResultProps {
  address?: AddressSelection;
  onChangeAddressClick: () => void;
}

export interface ResultContentProps extends ResultProps {
  content: ResultContent;
  availabilityType: AvailabilityType<ContentKey>;
  portfolio: Portfolio;
  isManualChecked: boolean;
  onScrollToPlans(): void;
  onPortfolioClick(): void;
  viewType: ResultSelectorType;
  availabilityStatus: AvailabilityStatus;
  maxAvailableDownload: number;
}

interface ResultSelectorProps extends ResultProps {
  availabilityList: string[];
  portfolio: Portfolio;
  isManualChecked: boolean;
  viewType?: ResultSelectorType;
  availabilityStatus: AvailabilityStatus;
  maxAvailableDownload: number;
}

export const compliantAvailablePlans: ContentKey[] = [
  'FIBER_AVAILABLE',
  'CLASSIC_AVAILABLE',
  'YOUNG_FIBER_AVAILABLE',
  'YOUNG_CLASSIC_AVAILABLE',
];
export const contraryAvailablePlans: ContentKey[] = [
  'CONTRARY_FIBER_AVAILABLE',
  'CONTRARY_CLASSIC_AVAILABLE',
  'CONTRARY_YOUNG_FIBER_AVAILABLE',
  'CONTRARY_YOUNG_CLASSIC_AVAILABLE',
];

export const classicAvailablePlans: ContentKey[] = [
  'CLASSIC_AVAILABLE',
  'YOUNG_CLASSIC_AVAILABLE',
  'CONTRARY_CLASSIC_AVAILABLE',
  'CONTRARY_YOUNG_CLASSIC_AVAILABLE',
];

export const availablePlans: ContentKey[] = [
  ...compliantAvailablePlans,
  ...contraryAvailablePlans,
];

export const ftthPlans: ContentKey[] = [
  'CLASSIC_AVAILABLE_UNDER_CONSTRUCTION',
  'CLASSIC_AVAILABLE_AREA_PLANNED',
  'CLASSIC_AVAILABLE_PRE_MARKETING',
  'CLASSIC_AVAILABLE_AREA_PLANNED_DATE',
  'CLASSIC_AVAILABLE_PRE_MARKETING_DATE',
  'CLASSIC_AVAILABLE_UNDER_CONSTRUCTION_DATE',
  'AREA_PLANNED',
  'PRE_MARKETING',
  'UNDER_CONSTRUCTION',
  'AREA_PLANNED_DATE',
  'PRE_MARKETING_DATE',
  'UNDER_CONSTRUCTION_DATE',
];

export const presalePlans: ContentKey[] = [
  'FIBER_PRESALE',
  'FIBER_AVAILABLE_PRESALE',
  'FIBER_GFAST_PRESALE',
  'FIBER_AVAILABLE_GFAST_PRESALE',
  'YOUNG_FIBER_AVAILABLE_PRESALE',
  'YOUNG_FIBER_PRESALE',
  'YOUNG_FIBER_AVAILABLE_GFAST_PRESALE',
  'YOUNG_FIBER_GFAST_PRESALE',
];

export const presalePlansWithAlternative: ContentKey[] = [
  'FIBER_AVAILABLE_PRESALE',
  'FIBER_AVAILABLE_GFAST_PRESALE',
  'YOUNG_FIBER_AVAILABLE_PRESALE',
  'YOUNG_FIBER_AVAILABLE_GFAST_PRESALE',
];

export type ResultSelectorType = 'plan' | 'standalone';

export const ResultSelector: React.FC<ResultSelectorProps> = ({
  availabilityList,
  address,
  portfolio,
  isManualChecked,
  availabilityStatus,
  maxAvailableDownload,
  onChangeAddressClick,
  viewType = 'plan',
}) => {
  const mandantType = checkMandantType(getEnv().SITE, address?.mandant);
  const contentKey = createContentKey(availabilityList, portfolio, mandantType);
  const { templateId } = useAvailabilityContext();
  const { availability } = useAvailability();
  const { push } = useRouter();
  const { data, error } = useFetchContent<ResultContent>({
    contentId: 'availability_' + contentKey,
  });
  const scrollToPlans = useScrollTo('plans', { dataName: 'name' });

  if (!data || error) return <LoadingSpinner theme="blue" />;

  const onPortfolioClick = () => {
    const url = data.buttonLink?.url
      ? data.buttonLink.url
      : getPortfolioUrl({
          portfolio: portfolio === 'FIBER' ? 'CLASSIC' : 'FIBER',
          templateId,
        });

    push(url);
  };

  const resultProps: ResultContentProps = {
    availabilityType: availabilityType[contentKey],
    portfolio,
    address,
    onChangeAddressClick,
    onScrollToPlans: scrollToPlans,
    onPortfolioClick,
    content: data,
    isManualChecked,
    viewType,
    availabilityStatus,
    maxAvailableDownload,
  };

  // Not Available
  if (contentKey === 'NOT_AVAILABLE')
    return <NotAvailableResult {...resultProps} />;

  // Mandant Mismatch
  if (mandantType === 'mismatch')
    return <MandantMismatchResult {...resultProps} />;

  // Available
  if (availablePlans.includes(contentKey) && !availability.isFTTHPlanned)
    return <AvailableResult {...resultProps} />;

  // ADSL
  if (contentKey === 'ADSL') return <ADSLResult {...resultProps} />;

  // FTTH mit/ohne Alternative UNDER_CONSTRUCTION, PRE_MARKETING, AREA_PLANNED
  if (ftthPlans.includes(contentKey)) return <FTTHResult {...resultProps} />;

  // Presale
  if (presalePlans.includes(contentKey))
    return <PresaleResult {...resultProps} />;

  return <LoadingSpinner />;
};
